import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { transliterate as slugify } from "transliteration";
import { useTranslation } from "react-i18next";

export const ProdProperty = ({ properties = [] }) => {
  const { t, i18n } = useTranslation();

  if (properties.length > 0) {
    const valuesProperty = properties.filter((item) => item.VALUE);
    if (valuesProperty.length > 0) {
      const desc = valuesProperty.filter(
        (item) => item.ID === "191" || item.ID === "185"
      );
      const enegy = valuesProperty.filter(
        (item) =>
          item.ID === "189" ||
          item.ID === "186" ||
          item.ID === "187" ||
          item.ID === "188"
      );
      const hran = valuesProperty.filter((item) => item.ID === "190");
      const sost = valuesProperty.find((item) => item.ID === "262");
      const enegyObj = {};
      enegy.forEach((item) => (enegyObj[item.ID] = item));
      return (
        <Fragment>
          {desc.length > 0 ? (
            <div className="d-flex flex-column prod-property-list">
              <h6>{t("PRODUCT_DESCRIPTION")}</h6>
              {desc.map((item) => (
                <div
                  className="d-flex justify-content-between  prod-property-list-item"
                  key={item.ID}
                >
                  <strong>{item.LANG[i18n.resolvedLanguage]}</strong>
                  {item.ID === "191" ? (
                    <Link
                      to={
                        "/brands/" +
                        slugify(item.VALUE)
                          .toLowerCase()
                          .replaceAll(" ", "_")
                          .replaceAll("-", "_")
                          .replace(/[^a-z_0-9]*/g, "") +
                        "/"
                      }
                    >
                      {item.VALUE}
                    </Link>
                  ) : (
                    <span>{item.VALUE}</span>
                  )}
                </div>
              ))}
            </div>
          ) : null}
          {enegy.length > 0 ? (
            <div className="d-flex flex-column prod-property-list">
              <h6>{t("PRODUCT_NUTRITIONAL_VALUE")}</h6>
              {enegyObj[186] ? (
                <div className="d-flex justify-content-between prod-property-list-item">
                  <strong>{enegyObj[186].LANG[i18n.resolvedLanguage]}</strong>
                  <span>{enegyObj[186].VALUE}</span>
                </div>
              ) : (
                <div className="d-flex justify-content-between prod-property-list-item">
                  <strong>{t("PRODUCT_PROTEIN")}</strong>
                  <span>0</span>
                </div>
              )}
              {enegyObj[187] ? (
                <div className="d-flex justify-content-between prod-property-list-item">
                  <strong>{enegyObj[187].LANG[i18n.resolvedLanguage]}</strong>
                  <span>{enegyObj[187].VALUE}</span>
                </div>
              ) : (
                <div className="d-flex justify-content-between prod-property-list-item">
                  <strong>{t("PRODUCT_FAT")}</strong>
                  <span>0</span>
                </div>
              )}
              {enegyObj[188] ? (
                <div className="d-flex justify-content-between prod-property-list-item">
                  <strong>{enegyObj[188].LANG[i18n.resolvedLanguage]}</strong>
                  <span>{enegyObj[188].VALUE}</span>
                </div>
              ) : (
                <div className="d-flex justify-content-between prod-property-list-item">
                  <strong>{t("PRODUCT_CARBOHYDRATE")}</strong>
                  <span>0</span>
                </div>
              )}
              {enegyObj[189] ? (
                <div className="d-flex justify-content-between prod-property-list-item">
                  <strong>{enegyObj[189].LANG[i18n.resolvedLanguage]}</strong>
                  <span>{enegyObj[189].VALUE}</span>
                </div>
              ) : null}
            </div>
          ) : null}
          {hran.length > 0 ? (
            <div className="d-flex flex-column prod-property-list">
              <h6>{t("PRODUCT_STORAGE_CONDITIONS")}</h6>
              {hran.map((item) => (
                <div
                  className="d-flex justify-content-between  prod-property-list-item"
                  key={item.ID}
                >
                  <strong>{item.LANG[i18n.resolvedLanguage]}</strong>
                  <span>{item.VALUE}</span>
                </div>
              ))}
            </div>
          ) : null}
          {sost ? (
            <div className="d-flex flex-column prod-property-list">
              <h6>{t("PRODUCT_COMPOSITION")}</h6>
              <div className="d-flex justify-content-between  prod-property-list-item">
                <span>{sost.VALUE}</span>
              </div>
            </div>
          ) : null}
        </Fragment>
      );
    } else {
      return <Fragment />;
    }
  } else {
    return <Fragment />;
  }
};
