import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FaPencilAlt } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";
import { saleActions } from "../../state/actions";

export const PersonalOrdersEdit = ({ order }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const bitrix = useBitrix();

  const [load, setLoad] = useState(false);

  const setEditOrder = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(t("ORDER_EDIT_QESTION"))) {
      setLoad(true);
      bitrix.sale
        .setEditOrderId(order.ID)
        .then((res) => {
          if (order.ID) {
            dispatch(saleActions.setOrderIdForEdit(order.ID));
            dispatch(saleActions.updateBasket(res.BASKET));
            setLoad(false);
            history.push("/basket/");
          }
          if (res.error) {
            setLoad(false);
            alert(t("ORDER_WAS_NOT_EDIT"));
          }
        })
        .catch((e) => {
          setLoad(false);
          alert(t("SERVICE_NOT_FOUND"));
        });
    }
  };
  return (
    <button className="btn order-button" disabled={load} onClick={setEditOrder}>
      {load ? (
        <Spinner animation="border" size="sm" className="mr-1" />
      ) : (
        <FaPencilAlt color="#ffc850" className="mr-1" />
      )}{" "}
      <span>{t("EDIT_ORDER")}</span>
    </button>
  );
};
