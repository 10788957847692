import React from "react";
import { Image } from "react-bootstrap";
import { YMaps, Map, ZoomControl, Placemark } from "react-yandex-maps";
import { FaMapMarkerAlt, FaBus, FaStoreAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../Breadcrumbs";

import k85 from "./k85.jpg";
import k379 from "./k379.jpg";
import k2 from "./k2.jfif";
import gip from "./gip.jpg";
import u21 from "./u21.jpg";
import z1 from "./zu2a.jpg";
import ark from "./ark.jpg";
import aero from "./aero.jpg";

export const RuPage = () => {
  const { t } = useTranslation();

  return (
    <div className="supermarkets mb-3">
      <Breadcrumbs />
      <h1>{t("SUPERMARKETS_HEADER")}</h1>
      <div className="supermarkets-wrap">
        <YMaps
          query={{
            lang: "ru_RU",
            apikey: "c5af4455-6b73-439c-ad0a-f2f40be2ff58",
          }}
        >
          <Map
            className="super-map"
            defaultState={{ center: [53.214422, 63.631976], zoom: 12 }}
          >
            <ZoomControl />
            <Placemark
              geometry={[53.185019, 63.586274]}
              properties={{ iconContent: t("K85_ADDRESS_SHORT") }}
              options={{ preset: "islands#greenStretchyIcon" }}
            />
            <Placemark
              geometry={[53.239447, 63.679972]}
              properties={{ iconContent: t("K379_ADDRESS_SHORT") }}
              options={{ preset: "islands#greenStretchyIcon" }}
            />
            <Placemark
              geometry={[53.202201, 63.66043]}
              properties={{ iconContent: t("GIP_ADDRESS_SHORT") }}
              options={{ preset: "islands#greenStretchyIcon" }}
            />
            <Placemark
              geometry={[53.240187, 63.580742]}
              properties={{ iconContent: t("K2_ADDRESS_SHORT") }}
              options={{ preset: "islands#greenStretchyIcon" }}
            />
            <Placemark
              geometry={[53.242376, 63.610271]}
              properties={{ iconContent: t("U21_ADDRESS_SHORT") }}
              options={{ preset: "islands#greenStretchyIcon" }}
            />
            <Placemark
              geometry={[53.218303, 63.717117]}
              properties={{ iconContent: t("ZU2A_ADDRESS_SHORT") }}
              options={{ preset: "islands#greenStretchyIcon" }}
            />
            <Placemark
              geometry={[50.252836, 66.92562]}
              properties={{ iconContent: t("ARK_ADDRESS_SHORT") }}
              options={{ preset: "islands#greenStretchyIcon" }}
            />
            <Placemark
              geometry={[53.219675, 63.569845]}
              properties={{
                iconContent: t("AERO_ADDRESS_SHORT"),
              }}
              options={{ preset: "islands#greenStretchyIcon" }}
            />
          </Map>
        </YMaps>
        <div className="supermarkets-line mt-3">
          <div className="scard mr-2">
            <Image src={k85} fluid />
            <div className="d-flex align-items-center p-3 text-success">
              <FaMapMarkerAlt className="mr-1" size={30} />
              <span className="text-center">{t("K85_ADDRESS")}</span>
            </div>
            <hr />
            <div className="d-flex flex-column align-items-center p-3 text-success">
              <div className="d-flex align-items-center">
                <FaBus className="mr-1" />
                <span>№ 21, 25, 38</span>
              </div>
              <div className="d-flex align-items-center">
                <FaStoreAlt className="mr-1" />
                <span>{t("K85_STATION")}</span>
              </div>
            </div>
          </div>
          <div className="scard mr-2">
            <Image src={k379} fluid />
            <div className="d-flex align-items-center p-3 text-success">
              <FaMapMarkerAlt className="mr-1" size={30} />
              <span className="text-center">{t("K379_ADDRESS")}</span>
            </div>
            <hr />
            <div className="d-flex flex-column align-items-center p-3 text-success">
              <div className="d-flex align-items-center">
                <FaBus className="mr-1" />
                <span>№ 18, 25, 19, 8, 107, 18A</span>
              </div>
              <div className="d-flex align-items-center">
                <FaStoreAlt className="mr-1" />
                <span>{t("K379_STATION")}</span>
              </div>
            </div>
          </div>
          <div className="scard mr-2">
            <Image src={k2} fluid />
            <div className="d-flex align-items-center p-3 text-success">
              <FaMapMarkerAlt className="mr-1" size={30} />
              <span className="text-center">{t("K2_ADDRESS")}</span>
            </div>
            <hr />
            <div className="d-flex flex-column align-items-center p-3 text-success">
              <div className="d-flex align-items-center">
                <FaBus className="mr-1" />
                <span>№ 8, 10</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="text-center">
                  <FaStoreAlt className="mr-1" />
                  {t("K2_STATION")}
                </span>
              </div>
            </div>
          </div>
          <div className="scard">
            <Image src={gip} fluid />
            <div className="d-flex align-items-center p-3 text-success">
              <FaMapMarkerAlt className="mr-1" size={30} />
              <span className="text-center">{t("GIP_ADDRESS")}</span>
            </div>
            <hr />
            <div className="d-flex flex-column align-items-center p-3 text-success">
              <div className="d-flex align-items-center">
                <FaBus className="mr-1" />
                <span>№ 102, 104, 109, 110, 116</span>
              </div>
              <div className="d-flex align-items-center">
                <FaStoreAlt className="mr-1" />
                <span>{t("GIP_STATION")}</span>
              </div>
            </div>
          </div>
          <div className="scard mr-2">
            <Image src={u21} fluid />
            <div className="d-flex align-items-center p-3 text-success">
              <FaMapMarkerAlt className="mr-1" size={30} />
              <span className="text-center">{t("U21_ADDRESS")}</span>
            </div>
            <hr />
            <div className="d-flex flex-column align-items-center p-3 text-success">
              <div className="d-flex align-items-center">
                <FaBus className="mr-1" />
                <span>№ 8, 10, 12, 38</span>
              </div>
              <div className="d-flex align-items-center">
                <FaStoreAlt className="mr-1" />
                <span>{t("U21_STATION")}</span>
              </div>
            </div>
          </div>
          <div className="scard mr-2">
            <Image src={z1} fluid />
            <div className="d-flex align-items-center p-3 text-success">
              <FaMapMarkerAlt className="mr-1" size={30} />
              <span className="text-center">{t("ZU2A_ADDRESS")}</span>
            </div>
            <hr />
            <div className="d-flex flex-column align-items-center p-3 text-success">
              <div className="d-flex align-items-center">
                <FaBus className="mr-1" />
                <span>№ 101</span>
              </div>
              <div className="d-flex align-items-center">
                <FaStoreAlt className="mr-1" />
                <span>{t("ZU2A_STATION")}</span>
              </div>
            </div>
          </div>
          <div className="scard mr-2">
            <Image src={ark} fluid />
            <div className="d-flex align-items-center p-3 text-success">
              <FaMapMarkerAlt className="mr-1" size={30} />
              <span className="text-center">{t("ARK_ADDRESS")}</span>
            </div>
            <hr />
            <div className="d-flex flex-column align-items-center p-3 text-success">
              <div className="d-flex align-items-center">
                <span></span>
              </div>
              <span></span>
            </div>
          </div>
          <div className="scard mr-2">
            <Image src={aero} fluid />
            <div className="d-flex align-items-center p-3 text-success">
              <FaMapMarkerAlt className="mr-1" size={30} />
              <span className="text-center">{t("AERO_ADDRESS")}</span>
            </div>
            <hr />
            <div className="d-flex flex-column align-items-center p-3 text-success">
              <div className="d-flex align-items-center">
                <FaBus className="mr-1" />
                <span>№ 3,5</span>
              </div>
              <div className="d-flex align-items-center">
                <FaStoreAlt className="mr-1" />
                <span>{t("AERO_STATION")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
