import React, { Fragment, useState } from "react";
import { Form, FormControl } from "react-bootstrap";
import { FaUser, FaAngleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const OrderContactsM = ({ params }) => {
  const { t } = useTranslation();

  const [show, toggle] = useState(false);

  let classWrap = "d-flex position-relative order-panel-button";
  if (params.valid) {
    classWrap += " valid";
  }
  if (!params.valid && (params.name || params.phone)) {
    classWrap += " invalid";
  }

  return (
    <Fragment>
      <div className={classWrap}>
        <div className="w-25 d-flex justify-content-center align-items-center order-panel-button-icon-wrap position-relative">
          <FaUser className="order-panel-button-icon" />
        </div>
        <div
          className="w-75 d-flex align-items-center order-panel-button-action pt-2 pb-2 pl-4 position-relative"
          onClick={() => toggle(true)}
        >
          <div className="d-flex flex-column">
            {params.name ? (
              <strong>{params.name}</strong>
            ) : (
              <span>{t("SET_NAME")}</span>
            )}
            {params.phone ? (
              <strong>{params.phone}</strong>
            ) : (
              <span>{t("SET_PHONE")}</span>
            )}
          </div>
          <FaAngleRight className="order-panel-button-right-icon" />
        </div>
      </div>
      {show ? (
        <div className="modal-panel-wrap d-flex justify-content-center align-items-start">
          <div className="modal-panel">
            <Form>
              <Form.Group controlId="form-name">
                <Form.Label>{t("NAME_LABEL")}</Form.Label>
                <Form.Control
                  type="text"
                  value={params.name}
                  onChange={(e) => params.setName(e.target.value)}
                  onBlur={params.isValidN}
                  isValid={params.validN}
                  isInvalid={params.validN === false}
                />
                <FormControl.Feedback type="invalid">
                  {params.nErr}
                </FormControl.Feedback>
              </Form.Group>
              <Form.Group controlId="form-phone">
                <Form.Label>{t("PHONE_LABEL")}</Form.Label>
                <Form.Control
                  type="text"
                  value={params.phone}
                  onChange={(e) => params.setPhone(e.target.value)}
                  onBlur={params.isValidP}
                  isValid={params.validP}
                  isInvalid={params.validP === false}
                />
                <FormControl.Feedback type="invalid">
                  {params.pErr}
                </FormControl.Feedback>
              </Form.Group>
            </Form>
            <button
              type="button"
              className="btn btn-sm btn-success btn-block"
              onClick={() => toggle(false)}
            >
              {t("SAVE")}
            </button>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
