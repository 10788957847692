import md5 from "md5";
import React, { Fragment, useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";

import AppSpinner from "../AppSpinner";
import CatalogSlider from "../CatalogSlider";
import { PersonalOrdersItem } from "./PersonalOrdersItem";

import "./personal-orders.css";
export const PersonalOrdersFeedback = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const bitrix = useBitrix();

  const [order, setOrder] = useState({});
  const [feed, setFeed] = useState("");
  const [errFeed, setErrFeed] = useState("");
  const [load, setLoad] = useState(false);
  const [sendFeed, toggleSendFeed] = useState(false);
  const [err, setErr] = useState("");

  const parseUrl = () => {
    const urlParams = search
      .replace("?order=", "")
      .replace("hash=", "")
      .split("&");
    const isValidUrl = urlParams[1] === md5(urlParams[0] + "ordr_feedback");
    return {
      orderId: urlParams[0],
      isValidUrl,
    };
  };

  const save = () => {
    toggleSendFeed(true);
    bitrix.sale
      .sendFeedback(feed, order.ID)
      .then((res) => {
        toggleSendFeed(false);
        setErrFeed(
          <span className="text-success">{t("FEED_SUCCESS_MSG")}</span>
        );
      })
      .catch(() => {
        toggleSendFeed(false);
        setErrFeed(<span className="text-danger">{t("FEED_ERROR_MSG")}</span>);
      });
  };

  useEffect(() => {
    const urlPath = parseUrl();
    if (urlPath.isValidUrl) {
      setLoad(true);
      bitrix.sale
        .order(urlPath.orderId)
        .then((res) => {
          setLoad(false);
          setOrder(res);
          if (res.ORDER_FEEDBACK) setFeed(res.ORDER_FEEDBACK);
        })
        .catch(() => {
          setLoad(false);
          setErr(t("SERVICE_NOT_FOUND"));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  if (load) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <AppSpinner />
      </div>
    );
  }
  if (err) {
    return <Alert variant="warning">{err}</Alert>;
  }
  if (Object.keys(order).length > 0) {
    return (
      <Fragment>
        <div className="bg-white p-3 order-feed">
          <h1>{t("ORDER_FEED")}</h1>
          <div className="d-flex w-100 order-feed-body">
            <div className="d-flex align-items-center">
              <PersonalOrdersItem order={order} />
            </div>
            <div className="d-flex flex-column feed-form-wrap">
              <div className="form-group">
                <label htmlFor="order-feed-field">
                  {t("TEXT_FEED_LABEL")}:
                </label>
                <textarea
                  className="form-control"
                  id="order-feed-field"
                  rows="3"
                  value={feed}
                  onChange={(e) => setFeed(e.target.value)}
                  disabled={sendFeed}
                ></textarea>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <button
                  className="btn btn-success mr-2"
                  disabled={sendFeed}
                  onClick={save}
                >
                  {sendFeed ? <Spinner animation="border" size="sm" /> : null}{" "}
                  {t("SAVE_FEED")}
                </button>
                {errFeed}
              </div>
            </div>
          </div>
        </div>
        <CatalogSlider type="banner" />
      </Fragment>
    );
  }
  return <Fragment />;
};
