import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import { useBitrix } from "./BitrixProvider";
import { userActions } from "../state/actions";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const PersonalLogoutButton = () => {
  const { t } = useTranslation();
  const bitrix = useBitrix();
  const dispath = useDispatch();
  const history = useHistory();

  const [load, setLoad] = useState(false);

  const lodout = () => {
    setLoad(true);
    bitrix.users.logout().then((data) => {
      setLoad(false);
      if (data["auth_key"]) {
        dispath(userActions.logout());
        history.push("/");
      }
    });
  };
  if (load) {
    return <Spinner animation="border" size="sm" variant="success" />;
  }
  return (
    <div className="d-flex align-items-center link-block" onClick={lodout}>
      <FaSignOutAlt color="#8cc83c" className="mr-2" />
      <span>{t("EXIT_LABEL")}</span>
    </div>
  );
};
