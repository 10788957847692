import React, { useState, Fragment } from "react";
import { Image, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { OrderTimeModal } from "./OrderTimeModal";

import timeIcon from "./time.svg";

export const OrderTimeD = ({ params }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const save = (t) => {
    params.saveDateTime(t);
    setShow(false);
  };

  return (
    <Fragment>
      <div className="order-time">
        <h6>{t("TIME_DELIVERY")}:</h6>
        <div className="d-flex align-items-end justify-content-between">
          <Form.Control
            type="text"
            value={params.dataTime ? params.dataTime : t("NO_TIME")}
            readOnly
          />
          <Image src={timeIcon} onClick={() => setShow(!show)} width={29} />
        </div>
      </div>
      {show ? (
        <OrderTimeModal
          params={params}
          save={save}
          close={() => setShow(false)}
        />
      ) : null}
    </Fragment>
  );
};
