import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useWindowDimensions } from "../WindowDimensionsProvider";

import PersonalOrders from "../PersonalOrders";
import PersonalFavorite from "../PersonalFavorite";
import PersonalAddress from "../PersonalAddress";

import "./personal-tabs.css";

const PersonalTabs = () => {
  const { t } = useTranslation();
  const { isMobil } = useWindowDimensions();
  const { favorite } = useSelector((state) => state.user);

  const [key, setKey] = useState("orders");

  const getBody = (k) => {
    switch (k) {
      case "orders":
        if (isMobil) {
          return <Fragment />;
        } else {
          return (
            <div className="personal-tabs-line-wrap p-3">
              <div className="personal-tabs-line pt-3 pb-3">
                <PersonalOrders />
              </div>
            </div>
          );
        }
      case "favorite":
        return <PersonalFavorite />;
      case "address":
        return <PersonalAddress />;
      default:
        return <Fragment />;
    }
  };
  useEffect(() => {
    if (favorite.length === 0) setKey("orders");
  }, [favorite]);
  return (
    <div className="mt-3 bg-white personal-tabs mb-3">
      <div className="pt-3 pl-3">
        <button
          onClick={() => setKey("orders")}
          className={
            key === "orders" ? "btn-tab-active btn mr-3" : "btn-tab btn mr-3"
          }
        >
          {t("ORDERS_HISTORY")}
        </button>
        {favorite.length > 0 ? (
          <button
            onClick={() => setKey("favorite")}
            className={
              key === "favorite"
                ? "btn-tab-active btn mr-3"
                : "btn-tab btn mr-3"
            }
          >
            {t("FAVORITES_PROD")}
          </button>
        ) : null}
        <button
          onClick={() => setKey("address")}
          className={key === "address" ? "btn-tab-active btn" : "btn-tab btn"}
        >
          {t("ADDRESSES_DELIVERY")}
        </button>
      </div>
      <div>{getBody(key)}</div>
    </div>
  );
};
export default PersonalTabs;
