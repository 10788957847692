import React from 'react';
import CatalogFilter from '../CatalogFilter'
import CatalogSort from '../CatalogSort'
export const CatalogTopPanelM = ()=>{
    return (
        <div className="catalog-top-panel-mobil d-flex">
            <div className="w-50">
                <CatalogSort/>
            </div>
            <div className="w-50">
                <CatalogFilter/>
            </div>            
        </div>
    )
}