import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { catalogActions } from "../../state/actions";

import { ResponsiveLayout } from "../ResponsiveLayout";
import { CatalogFilterD } from "./CatalogFilterD";
import { CatalogFilterM } from "./CatalogFilterM";

import "./catalog-filter.css";
const CatalogFilter = () => {
  const dispath = useDispatch();
  const {
    filters,
    settings: { filter },
  } = useSelector((state) => state.catalog.main);
  const setFilter = (code, value) => {
    value = value.replace("&", "%26");
    let newFilter = "";
    if (filter.includes(value)) {
      newFilter = filter.replace(`filter[${code}][]=${value}&`, "");
    } else {
      newFilter += filter + `filter[${code}][]=${value}&`;
    }
    newFilter = newFilter.replace("filter=", "");
    dispath(catalogActions.main.settigs.changeType("filter"));
    dispath(catalogActions.main.settigs.setFilter(newFilter));
  };

  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <CatalogFilterD filters={filters} action={setFilter} filter={filter} />
      )}
      renderMobile={() => (
        <CatalogFilterM filters={filters} action={setFilter} filter={filter} />
      )}
    />
  );
};
export default CatalogFilter;
