import React, { Fragment } from "react";
import { Form, Spinner, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const OrderPaymentD = ({ params }) => {
  const { t, i18n } = useTranslation();

  if (params.load) {
    return (
      <div className="order-payment-wrap d-flex flex-column">
        <h6>{t("PAYMENTS_LIST")}:</h6>
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="success" />
        </div>
      </div>
    );
  }
  if (params.err) {
    return (
      <div className="order-payment-wrap d-flex flex-column">
        <h6>{t("PAYMENTS_LIST")}:</h6>
        <div className="d-flex justify-content-center">
          <Alert variant="warning">{params.err}</Alert>
        </div>
      </div>
    );
  }
  return (
    <div className="order-payment-wrap d-flex flex-column">
      <h6>{t("PAYMENTS_LIST")}:</h6>
      <div className="d-flex flex-wrap">
        {params.active.map((item) => (
          <Form.Check
            key={item.ID}
            type="radio"
            id={"payment" + item.ID}
            name="payment"
            className="cust-radio mr-2 mb-2"
            checked={params.type === item.ID}
            onChange={() => params.setPayment(item.ID)}
            label={
              <Fragment>
                <img
                  src={item.IMG}
                  className="order-payment-lable-img"
                  alt={item.LANG[i18n.resolvedLanguage]}
                />
                <span>{item.LANG[i18n.resolvedLanguage]}</span>
              </Fragment>
            }
            custom
          />
        ))}
      </div>
      {params.type === 6 ? (
        <div className="form-group mt-2 mb-0 sdacha-form w-100">
          <label htmlFor="invoice">{t("INVOICE_INFORMATION")}</label>
          <textarea
            rows={2}
            placeholder={t("INVOICE_DETAILS")}
            className="form-control"
            value={params.text}
            onChange={(e) => params.setText(e.target.value)}
          ></textarea>
        </div>
      ) : null}
    </div>
  );
};
