import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useWindowDimensions } from "../WindowDimensionsProvider";

import "./sale-basket-title.css";
import cart from "./cart.svg";

const SaleBasketTitle = () => {
  const { basket, total } = useSelector((state) => state.sale);
  const { isMobil } = useWindowDimensions();
  const { t } = useTranslation();

  if (total === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center pt-5">
        <div className="d-flex flex-column align-items-center">
          <Image src={cart} />
          <h1 className="mt-3">{t("CART_EMPTY")}</h1>
          <strong>{t("CART_EMPTY_WARNIG")}</strong>
          <Link to="/discounts/" className="btn btn-success mt-4">
            {t("GO_CATALOG")}
          </Link>
        </div>
      </div>
    );
  }
  if (isMobil) {
    return (
      <div className="d-flex flex-column mb-4 align-items-center sale-basket-list-title">
        <div className="d-flex align-items-baseline">
          <h1 className="mb-0 mr-3">{t("BASKET_LABEL")}</h1>
        </div>
        <small>{t("FREE_DELIVERY")}</small>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column mb-4 sale-basket-list-title">
      <div className="d-flex align-items-baseline">
        <h1 className="mb-0 mr-3">{t("BASKET_LABEL")}</h1>
        <span>
          {basket.length}{" "}
          {plural(basket.length, [
            t("PRODS_LABEL_FORM1"),
            t("PRODS_LABEL_FORM2"),
            t("PRODS_LABEL_FORM3"),
          ])}{" "}
          {t("ON_SUM_SMALL")} {formatSum("" + total)}
          {t("TENGE")}
        </span>
      </div>
      <small>{t("FREE_DELIVERY")}</small>
    </div>
  );
};
const plural = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};
const formatSum = (summ) => {
  const separator = " ";
  return summ.replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g, "$1" + separator);
};
export default SaleBasketTitle;
