import React from 'react';
import {useSelector,useDispatch} from 'react-redux'
import {ResponsiveLayout} from '../ResponsiveLayout'
import { CatalogSortD } from './CatalogSortD';
import { CatalogSortM } from './CatalogSortM';
import {catalogActions} from '../../state/actions'
import './catalog-sort.css'
const CatalogSort = ()=>{
    const {sort} = useSelector(state=>state.catalog.main.settings)
    const dispath = useDispatch()
    const setFilter = value=>{
        dispath(catalogActions.main.settigs.setSort(value))
    }
    return <ResponsiveLayout renderDesktop={()=><CatalogSortD sort={sort} action={setFilter}/>} renderMobile={()=><CatalogSortM sort={sort} action={setFilter}/>}/>
}
export default CatalogSort