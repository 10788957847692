export default class Basket {
  #shop = 11;
  #price = 6;
  //Получаем состав корзины для пользователя
  getBasket(data = {}) {
    data.fields = "url";
    return {
      method: "GET",
      url: "baskets",
      data,
    };
  }
  //Получаем ид корзины для пользователя
  getUserForBasket() {
    return {
      method: "GET",
      url: "baskets/fuser",
    };
  }
  //Добавляем товар в корзину
  addToBasket(data) {
    data.price = this.#price;
    return {
      method: "POST",
      url: "baskets",
      data,
    };
  }
  //Удаляем товар из корзины
  delFromBasket(data) {
    return {
      method: "DELETE",
      url: "baskets",
      data,
    };
  }
  //Получаем информацию о минимальной сумме заказа
  getBasketConfig() {
    return {
      method: "GET",
      url: "baskets/config",
    };
  }
}
