import React, { useState, useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux'
import {useBitrix} from '../../BitrixProvider'
import {ResponsiveLayout} from '../../ResponsiveLayout'

import {saleActions} from '../../../state/actions'
import {OrderTimeD} from './OrderTimeD'
import {OrderTimeM} from './OrderTimeM'

import './order-time.css'
const OrderTime = ()=>{
    const {total,order:{delivery:{point,address,type},time:{dataTime,valid}},orderId} = useSelector(state=>state.sale)
    const [times,setTimes] = useState({}) 
    const [activeData,setActiveData] = useState({
        data:'',
        time:''
    })
    const bitrix = useBitrix() 
    const dispath = useDispatch()
    const saveDateTime = t=>{       
        if(activeData.data && t){
            dispath(saleActions.orderSetTime({dataTime:activeData.data+' '+t,valid:true}))
            setActiveData({...activeData,time:t})

        }
    }
    useEffect(()=>{
        if(!orderId){           
            dispath(saleActions.orderSetTime({dataTime:'',valid:false}))
            setActiveData({data:'',time:''})
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[address,orderId])
    useEffect(()=>{        
        if(dataTime){
            const dataTimeInfo = dataTime.split(' ')            
            setActiveData({data:dataTimeInfo[0],time:dataTimeInfo[1]})
        }

    },[dataTime])
    useEffect(()=>{        
        if(total && point){            
            bitrix.sale.getDeliveryTime(point,type,orderId)
                .then(res=>{                    
                    setTimes(res)
                })
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[point,total])
    return (
        <ResponsiveLayout
            renderDesktop={()=><OrderTimeD params={{times,activeData,setActiveData,dataTime,point,saveDateTime}}/>}
            renderMobile={()=><OrderTimeM params={{times,activeData,setActiveData,dataTime,point,saveDateTime,valid}}/>}
        />
    )
}
export default OrderTime