const userActions = {
    updateId:(id)=>{
        return{
            type:'UPD_USER_ID',
            payload:id
        }
    },
    updateName:(name)=>{
        return{
            type:'UPD_USER_NAME',
            payload:name
        }
    },
    updateLogin:(login)=>{
        return{
            type:'UPD_USER_LOGIN',
            payload:login
        }
    },
    logout:()=>{
        return{
            type:'USER_LOGOUT',
            payload:[]
        } 
    },
    setAddress:(address)=>{
        return{
            type:'UPD_ADDRESS',
            payload:address
        } 
    },
    setFavorite:(favorite)=>{
        return{
            type:'UPD_FAVORITE',
            payload:favorite
        }
    },
    setBonusCard:(card)=>{
        return{
            type:'BONUS_CARD_UPD',
            payload:card
        }
    },
    setUser:(user)=>{
        return{
            type:'SET_USER',
            payload:user
        }
    }
}
export default userActions