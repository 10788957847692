import React from 'react';

import {ResponsiveLayout} from '../ResponsiveLayout'
import { AdvantagesD } from './AdvantagesD';
import { AdvantagesM } from './AdvantagesM';

import './advantages.css'

const Advantages = ()=>{
    return <ResponsiveLayout renderDesktop={AdvantagesD} renderMobile={AdvantagesM}/>
}
export default Advantages