import React from "react";

import spinner from "./spinner.gif";

const AppSpinner = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img src={spinner} alt="loading..." />
    </div>
  );
};

export default AppSpinner;
