import React,{Fragment,useState,useEffect} from 'react'
import {useSelector} from 'react-redux'

import CatalogSectionItem from '../CatalogSectionItem'
import { useBitrix } from '../BitrixProvider'

import './blurb-catalog-search.css'
import { FavoriteButton } from '../PersonalFavorite/FavoriteButton'


export const BlurbCatalogSearch = ({size})=>{
    const bitrix = useBitrix()
    const [products,setProds] = useState([])
    const query = useSelector(state=>state.catalog.main.settings.searchList)
    const getData = ()=>{        
        bitrix.catalog.getBlurbSearch(query)
            .then(res=>{               
                if(res.ARRAY && Array.isArray(res.ARRAY)){                    
                    setProds(res.ARRAY)
                }else{
                    if(products.length>0) setProds([])
                }
            })
            .catch(e=>{})
    }
    useEffect(()=>{        
        if(query){
            getData()
        }else{
            setProds([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[query])
    if(products.length>0){
        return (
            <div className="d-flex flex-wrap">
                {
                    products.map(item=>(
                        <div className="w-25 d-flex position-relative" key={'blub'+item['CODE']}>
                            <span className="blurb-search-label">Хит</span>
                            <FavoriteButton prodId={item['ID']}/>
                          <CatalogSectionItem prods={item}/>  
                        </div>                        
                    ))
                }
            </div>
        )
    }    
    return <Fragment></Fragment>
}