import React from "react";
import { Image } from "react-bootstrap";

import logoImg from "./logo-d.png";
import { Link } from "react-router-dom";

export const LogoDesc = () => {
  return (
    <div className="mb-1">
      <Link to="/" className="d-block">
        <Image src={logoImg} alt="Logo" width={150} />
      </Link>
    </div>
  );
};
