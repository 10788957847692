import React, { useState, Fragment } from "react";
import { FaSort, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const CatalogSortM = ({ sort, action }) => {
  const { t } = useTranslation();

  const [show, toggle] = useState(false);

  return (
    <Fragment>
      <div
        className="d-flex justify-content-center align-items-center"
        onClick={() => toggle(true)}
      >
        <FaSort />
        <strong>{t("SORT")}</strong>
      </div>
      <div
        className={
          show ? "catalog-sort-panel-wrap show" : "catalog-sort-panel-wrap"
        }
      >
        <div
          className={show ? "catalog-sort-panel show" : "catalog-sort-panel"}
        >
          <div className="catalog-sort-title">
            <span>{t("SORT")}</span>
            <FaTimes
              className="catalog-sort-right-icon"
              color="#FFEF16"
              onClick={() => toggle(false)}
            />
          </div>
          <div className="catalog-sort-mobil-body">
            <div className="custom-control custom-radio mb-3">
              <input
                type="radio"
                id="customRadio1"
                name="customRadio"
                className="custom-control-input"
                defaultChecked={sort === "name" || sort === ""}
                onChange={() => action("name")}
              />
              <label className="custom-control-label" htmlFor="customRadio1">
                {t("SORT_NAME")}
              </label>
            </div>
            <div className="custom-control custom-radio mb-3">
              <input
                type="radio"
                id="customRadio2"
                name="customRadio"
                className="custom-control-input"
                defaultChecked={sort === "price_up"}
                onChange={() => action("price_up")}
              />
              <label className="custom-control-label" htmlFor="customRadio2">
                {t("SORT_PRICE_UP")}
              </label>
            </div>
            <div className="custom-control custom-radio mb-3">
              <input
                type="radio"
                id="customRadio3"
                name="customRadio"
                className="custom-control-input"
                defaultChecked={sort === "price_down"}
                onChange={() => action("price_down")}
              />
              <label className="custom-control-label" htmlFor="customRadio3">
                {t("SORT_PRICE_DOWN")}
              </label>
            </div>
          </div>
          <div className="catalog-sort-mobil-button mt-2">
            <button
              className="btn btn-block btn-success btn-sm"
              onClick={() => toggle(false)}
            >
              {t("DONE")}
            </button>
          </div>
        </div>
        <div className="catalog-sort-close" onClick={() => toggle(false)}></div>
      </div>
    </Fragment>
  );
};
