import React, { useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";

export const PersonalOrdersCancel = ({ order, refreshList }) => {
  const { t } = useTranslation();
  const bitrix = useBitrix();

  const [load, setLoad] = useState(false);

  const setCancel = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(t("ORDER_CANCELATIONS_REQUEST"))) {
      setLoad(true);
      bitrix.sale
        .cancelOrder(order.ID)
        .then((data) => {
          setLoad(false);
          if (data.status && data.status === "success") {
            refreshList();
          } else {
            alert(t("SERVICE_NOT_FOUND"));
          }
        })
        .catch((e) => {
          setLoad(false);
          alert(t("SERVICE_NOT_FOUND"));
        });
    }
  };
  if (load) {
    return (
      <Spinner
        animation="border"
        size="sm"
        className="personal-order-item-cancel"
        variant="danger"
      />
    );
  }
  return (
    <FaTimesCircle className="personal-order-item-cancel" onClick={setCancel} />
  );
};
