import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./brands-list.css";
import { useBitrix } from "../BitrixProvider";

export const BrandsList = () => {
  const bitrix = useBitrix();
  const [brands, setBrands] = useState([]);
  const getData = () => {
    bitrix.content
      .getBrands()
      .then((res) => {
        if (Array.isArray(res)) {
          setBrands(res);
        }
      })
      .catch((e) => {});
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getData(), []);
  if (brands.length > 0) {
    return (
      <div className="brands-wrap">
        <div className="brands-line">
          {brands.map((item) => (
            <div className="brands-card" key={item.ID}>
              <Link to={item.LINK}>
                <img src={item.PICTURE} alt={item.LINK} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return <Fragment></Fragment>;
};
