import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../../BitrixProvider";
import { saleActions } from "../../../state/actions";

import OrderTime from "../OrderTime";

import "./order-edit-save.css";
const OrderEditSave = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispath = useDispatch();
  const bitrix = useBitrix();
  const {
    orderId,
    total,
    basket,
    order: {
      delivery: { price, type },
      time: { dataTime },
    },
  } = useSelector((state) => state.sale);

  const [order, setOrder] = useState(null);
  const [load, setLoad] = useState(false);

  const saveOrder = () => {
    if (dataTime) {
      const dateMask =
        type === 3
          ? /(\d\d).(\d\d).(\d\d\d\d)\s(\d\d:\d\d)/
          : /(\d\d).(\d\d).(\d\d\d\d)\s\d\d:\d\d-(\d\d:\d\d)/;
      const dataTimeInfo = dateMask.exec(dataTime);

      const endMoment = Date.parse(
        dataTimeInfo[3] +
          "-" +
          dataTimeInfo[2] +
          "-" +
          dataTimeInfo[1] +
          " " +
          dataTimeInfo[4]
      );
      const basketCount = basket.length;
      const orderTime =
        Date.now() +
        1200000 +
        basketCount * 6000 +
        basketCount * 20000 +
        basketCount * 60000;

      if (orderTime > endMoment) {
        //Error
        dispath(saleActions.orderSetTime({ dataTime: "", valid: false }));
        alert(t("SET_OTHER_DATA_TIME"));
      } else {
        //Success
        setLoad(true);
        bitrix.sale
          .saveEditOrder(orderId, dataTime)
          .then((res) => {
            setLoad(false);
            if (res.status && res.status === "success") {
              dispath(saleActions.setOrderIdForEdit(null));
              dispath(saleActions.updateBasket([]));
              history.push("/personal/");
            }
            if (res.error) {
              alert(res.error);
            }
          })
          .catch((e) => {
            setLoad(false);
            alert(t("SERVICE_NOT_FOUND"));
          });
      }
    } else {
      alert(t("SET_DATA_TIME"));
    }
  };
  useEffect(() => {
    if (orderId) {
      bitrix.sale
        .order(orderId)
        .then((data) => {
          if (data.ACCOUNT_NUMBER) {
            setOrder(data);
            const delivery = {
              type: parseInt(data.DELIVERY_ID),
              address: data.ADDRESS,
              price: parseInt(data.PRICE_DELIVERY),
              valid: false,
              coord: "",
              point: data.DELIVERY_POINT,
            };
            const time = {
              dataTime: data.DATA_TIME,
              valid: false,
            };
            dispath(saleActions.orderSetDelivery(delivery));
            dispath(saleActions.orderSetTime(time));
          }
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (order) {
    return (
      <div className="sale-order-wrap order-edit-save">
        <div className="bg-white mb-3">
          <h3>{t("DATA_ORDER")}</h3>
          <div className="sale-order-panel">
            <Form.Group controlId="order-name">
              <Form.Label>{t("ORDER_NAME")}</Form.Label>
              <Form.Control type="text" readOnly value={order.ACCOUNT_NUMBER} />
            </Form.Group>
          </div>
          <div className="sale-order-panel">
            <Form.Group controlId="order-address">
              <Form.Label>{t("ORDER_ADDRESS_LABEL")}</Form.Label>
              <Form.Control type="text" readOnly value={order.ADDRESS} />
            </Form.Group>
          </div>
          <div className="sale-order-panel">
            <OrderTime />
          </div>
          <div className="sale-order-panel d-flex flex-column pt-1 pb-3">
            <div className="sale-order-total">
              <div className="d-flex justify-content-between">
                <strong>{t("ORDER_BASKET_SUM")}</strong>
                <span>
                  {total}
                  {t("TENGE")}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <strong>{t("PRICE_DELIVERY_LABEL")}</strong>
                <span>
                  {price}
                  {t("TENGE")}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <strong>{t("ORDER_SUM")}</strong>
                <span>
                  {total + price}
                  {t("TENGE")}
                </span>
              </div>
            </div>
            <div className="sale-order-button mt-2">
              <button
                className="btn btn-sm btn-block btn-success mt-3"
                onClick={saveOrder}
              >
                {load ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : null}{" "}
                {t("SAVE")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <Fragment />;
};
export default OrderEditSave;
