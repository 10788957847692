import React from 'react';
import {useWindowDimensions} from '../components/WindowDimensionsProvider'
import { Container, Row, Col } from 'react-bootstrap';
import SaleBasketList from '../components/SaleBasketList';
import { SaleBasketLink } from '../components/SaleBasketLink';
import SaleBasketMsg from '../components/SaleBasketMsg';
import SaleOrder from '../components/SaleOrder';
import SaleBasketTitle from '../components/SaleBasketTitle'
import Breadcrumbs from '../components/Breadcrumbs';
export const SaleBasketPage = ()=>{
    const {isMobil} = useWindowDimensions()
    if(isMobil){
        return (
            <main className="pt-3 pb-3">
                <Container>
                    <Row>
                        <Col>
                            <SaleBasketTitle/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SaleBasketMsg/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SaleBasketList/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SaleBasketLink/>                            
                        </Col>
                    </Row>
                </Container>
            </main>
        )
    }
    return (
        <main className="pt-3 pb-3">
            <Container>
                <Breadcrumbs/>
                <Row>
                    <Col>
                        <SaleBasketTitle/>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8}>
                        <SaleBasketMsg/>
                        <SaleBasketList/>
                    </Col>
                    <Col lg={4}>
                        <SaleOrder/>
                    </Col>
                </Row>
            </Container>
        </main>
    )
}