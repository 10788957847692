import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import Emoji from "react-emoji-render";
import { FaAngleRight, FaAngleDown, FaArrowLeft } from "react-icons/fa";

const CatalogTree = ({ close }) => {
  const { categories } = useSelector((state) => state.catalog.categories);
  const [path, setPath] = useState([]);
  const icons = {
    produkty: "🥕",
    tovary_dlya_doma: "🔦",
    detskiy_mir: "👶🏼",
  };
  const levelUp = (key) => setPath([...path, key]);
  const levelDown = () => {
    const newPath = [...path];
    newPath.pop();
    setPath(newPath);
  };
  if (Object.keys(categories).length > 0) {
    const currentSection = path.reduce((pr, cur) => {
      if (pr[cur]) return pr[cur];
      return pr["CHILD"][cur];
    }, categories);

    if (currentSection["CHILD"]) {
      return (
        <Fragment>
          <ListGroup.Item
            className="d-flex justify-content-center"
            onClick={levelDown}
          >
            <FaArrowLeft
              className="main-menu-sidebar-left-icon"
              color="rgba(128, 128, 128, 0.3)"
              size={20}
            />
            <span className="main-menu-sidebar-link">
              {currentSection["NAME"]}
            </span>
            <FaAngleDown
              className="main-menu-sidebar-right-icon"
              color="rgba(128, 128, 128, 0.3)"
              size={28}
            />
          </ListGroup.Item>
          {Object.keys(currentSection["CHILD"]).map((key) => {
            if (currentSection["CHILD"][key]["CHILD"]) {
              return (
                <ListGroup.Item
                  key={key}
                  className="d-flex align-items-center"
                  onClick={() => levelUp(key)}
                >
                  <span className="main-menu-sidebar-link">
                    {currentSection["CHILD"][key]["NAME"]}
                  </span>
                  <FaAngleRight
                    className="main-menu-sidebar-right-icon"
                    color="rgba(128, 128, 128, 0.3)"
                    size={28}
                  />
                </ListGroup.Item>
              );
            }
            return (
              <ListGroup.Item key={key}>
                <Link
                  to={currentSection["CHILD"][key]["SECTION_PAGE_URL"]}
                  className="d-flex align-items-center w-100"
                  onClick={close}
                >
                  <span className="main-menu-sidebar-link">
                    {currentSection["CHILD"][key]["NAME"]}
                  </span>
                  <FaAngleRight
                    className="main-menu-sidebar-right-icon"
                    color="rgba(128, 128, 128, 0.3)"
                    size={28}
                  />
                </Link>
              </ListGroup.Item>
            );
          })}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {Object.keys(currentSection).map((key) => (
            <ListGroup.Item key={key} onClick={() => levelUp(key)}>
              <Emoji text={"" + icons[key]} className="mr-1" />
              <span className="main-menu-sidebar-link">
                {currentSection[key]["NAME"]}
              </span>
            </ListGroup.Item>
          ))}
        </Fragment>
      );
    }
  }
  return <Fragment></Fragment>;
};
export default CatalogTree;
