import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cart from './cart-m.svg'
export const SaleMiniBasketM = ({count})=>{    
    if(count>0){
        return (
            <Link to="/basket/" className="position-relative">
                <span className='sale-mini-basket-badge'>{count}</span>
                <Image src={cart} height={30}/> 
            </Link>
            
        )
    }
    return (
        <Link to="/basket/">            
            <Image src={cart} height={30}/>            
        </Link>        
    )
}