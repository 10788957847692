import React from "react";
import { Form, FormControl } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const OrderContactsD = ({ params }) => {
  const { t } = useTranslation();

  return (
    <Form className="order-contacts">
      <Form.Group controlId="form-name">
        <Form.Label>{t("NAME_LABEL")}</Form.Label>
        <Form.Control
          type="text"
          value={params.name}
          onChange={(e) => params.setName(e.target.value)}
          onBlur={params.isValidN}
          isValid={params.validN}
          isInvalid={params.validN === false}
        />
        <FormControl.Feedback type="invalid">
          {params.nErr}
        </FormControl.Feedback>
      </Form.Group>
      <Form.Group controlId="form-phone">
        <Form.Label>{t("PHONE_LABEL")}</Form.Label>
        <Form.Control
          type="text"
          value={params.phone}
          onChange={(e) => params.setPhone(e.target.value)}
          onBlur={params.isValidP}
          isValid={params.validP}
          isInvalid={params.validP === false}
        />
        <FormControl.Feedback type="invalid">
          {params.pErr}
        </FormControl.Feedback>
      </Form.Group>
    </Form>
  );
};
