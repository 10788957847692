import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import tenge from "./tenge.svg";
import returnIcon from "./return.svg";
import fresh from "./fresh.svg";
import floor from "./floor.svg";
import timeIcon from "./time.svg";

export const AdvantagesD = () => {
  const { t } = useTranslation();

  return (
    <div className="advantages pt-45 d-flex justify-content-between">
      <div className="advantages-card advantages-blue">
        <Image src={tenge} className="advantages-img" />
        <div className="advantages-text">
          <span>{t("ADVANTAGES_FREE_DELIVERY")}</span>
        </div>
      </div>
      <div className="advantages-card advantages-green">
        <Image src={returnIcon} className="advantages-img" />
        <div className="advantages-text">
          <span>{t("ADVANTAGES_EXCHANGE")}</span>
        </div>
      </div>
      <div className="advantages-card advantages-yellow">
        <Image src={fresh} className="advantages-img" />
        <div className="advantages-text">
          <span>{t("ADVANTAGES_FRESH_PRODUCTS")}</span>
        </div>
      </div>
      <div className="advantages-card advantages-purpur">
        <Image src={floor} className="advantages-img" />
        <div className="advantages-text">
          <span>{t("ADVANTAGES_SHIPPING")}</span>
        </div>
      </div>
      <div className="advantages-card advantages-red">
        <Image src={timeIcon} className="advantages-img" />
        <div className="advantages-text">
          <span>{t("ADVANTAGES_DELIVERY")}</span>
        </div>
      </div>
    </div>
  );
};
