import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import alco from "./21.png";
import ves from "./vesy.png";
import big from "./big.png";
import "./sale-basket-msg.css";

const SaleBasketMsg = () => {
  const { t } = useTranslation();
  const { basket } = useSelector((state) => state.sale);
  const isVes = basket.find((item) => item.PROPERTY_CML2_BASE_UNIT === "кг");
  const isAlco = basket.find((item) =>
    item.DETAIL_PAGE_URL.includes("alkogol")
  );
  const vesBasket = basket.find(
    (item) =>
      item.PROPERTY_CHANGE_DELIVERY_VALUE &&
      parseFloat(item.QUANTITY) > parseInt(item.PROPERTY_MAX_VALUE_VALUE)
  );

  if (isVes || isAlco || vesBasket) {
    return (
      <div className="mb-3">
        {isVes ? (
          <div
            className="sale-basket-msg alert alert-warning d-flex align-items-center"
            role="alert"
          >
            <Image src={ves} />
            <span>{t("VES_WARNING")}</span>
          </div>
        ) : null}
        {isAlco ? (
          <div
            className="sale-basket-msg alert alert-warning d-flex align-items-center"
            role="alert"
          >
            <Image src={alco} />
            <span>{t("ALCO_WARNING")}</span>
          </div>
        ) : null}
        {vesBasket ? (
          <div
            className="sale-basket-msg alert alert-warning d-flex align-items-center"
            role="alert"
          >
            <Image src={big} />
            <span>{t("HELP_WARNING")}</span>
          </div>
        ) : null}
      </div>
    );
  }
  return <Fragment />;
};
export default SaleBasketMsg;
