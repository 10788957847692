export default class Bonus {
  //Запрос бонусной карты
  getCard() {
    return {
      method: "GET",
      url: "bonus",
    };
  }
  //Запрос на удаление связи бонусной карты с пользователем
  bonusDelCard() {
    return {
      method: "DELETE",
      url: "bonus",
    };
  }
  //Запрос на подтверждения телефона
  bonusPhone(phone, name, code) {
    return {
      method: "POST",
      url: "bonus/phone",
      data: {
        phone,
        name,
        code,
      },
    };
  }
  //Запрос на подтверждение телефона по смс
  bonusPhoneCheck(data) {
    return {
      method: "POST",
      url: "bonus",
      data,
    };
  }
}
