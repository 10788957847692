export default class Order {
  #price = 6;
  //Список заказов пользователя
  getOrders() {
    return {
      method: "GET",
      url: "orders/list",
    };
  }
  //Получить информацию о заказе
  getOrder(order) {
    return {
      method: "GET",
      url: "orders",
      data: {
        order,
        fields: "url,price_6",
      },
    };
  }
  //Отменить заказ
  cancelOrder(order) {
    return {
      method: "DELETE",
      url: "orders",
      data: {
        order,
      },
    };
  }
  //Изменить свойства заказа
  changeOrderProps(order, props = {}) {
    return {
      method: "PUT",
      url: "orders",
      data: {
        order,
        ...props,
      },
    };
  }
  //Оплатить заказ
  setOnlinePaid(order) {
    return {
      method: "PUT",
      url: "orders/payment",
      data: {
        order,
      },
    };
  }
  //Повторить заказ
  repeatOrder(order) {
    return {
      method: "PUT",
      url: "orders/repeat",
      data: {
        order,
        price: this.#price,
      },
    };
  }
  //Редактировать заказ
  editOrder(order, params = {}) {
    return {
      method: "PUT",
      url: "orders/edit",
      data: {
        order,
        ...params,
      },
    };
  }
  //Получить редактируемый заказ пользователя
  getEditableOrder() {
    return {
      method: "GET",
      url: "orders/edit",
    };
  }
  //Получить доступное время доставки
  getDeliveryTime(data = {}) {
    return {
      method: "GET",
      url: "orders/time",
      data,
    };
  }
  //Получить информацию об адресе доставки
  getDeliveryPoint(data) {
    return {
      method: "GET",
      url: "orders/delivery/address",
      data,
    };
  }
  //Получить стоимость доставки
  getDeliveryPrice(data = {}) {
    return {
      method: "GET",
      url: "orders/delivery/price",
      data,
    };
  }
  //Создание заказа
  orderCreate(data) {
    return {
      method: "POST",
      url: "orders",
      data,
    };
  }
  //Проверить валидный ли промокод
  getPromoInfo(code) {
    return {
      method: "GET",
      url: "orders/delivery/promo",
      data: {
        code,
      },
    };
  }
  //Получить информацию о платеже
  getPaymentInfo(id) {
    return {
      method: "GET",
      url: "orders/orders/payment",
      data: {
        id,
      },
    };
  }
  //Получить актичный заказ
  getActiveOrder() {
    return {
      method: "GET",
      url: "orders/active",
    };
  }
  //Получить список ID доступных оплат
  getPaymentIds(deliveryId) {
    return {
      method: "GET",
      url: "orders/paymentlist",
      data: {
        deliveryId,
      },
    };
  }
  //Получить список доступных оплат
  getPaymentList(deliveryId) {
    return {
      method: "GET",
      url: "orders/payments_for_companies",
      data: {
        deliveryId,
      },
    };
  }
  // Проверить доступен ли телефон для оплаты через Kaspi
  checkPhoneInKaspi(phone) {
    return {
      method: "GET",
      url: "orders/kaspiphone",
      data: {
        phone,
      },
    };
  }

  // Проверить статус оплаты платежа
  checkPaymentStatus(order) {
    return {
      method: "GET",
      url: "orders/paystatus",
      data: {
        order,
      },
    };
  }
}
