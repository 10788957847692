import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { saleActions } from "../../../state/actions";

import { ResponsiveLayout } from "../../ResponsiveLayout";
import { OrderContactsD } from "./OrderContactsD";
import { OrderContactsM } from "./OrderContactsM";

import "./order-contacts.css";

const OrderContacts = () => {
  const { t } = useTranslation();
  const dispath = useDispatch();
  const { name: usName, phone: login } = useSelector((state) => state.user);
  const {
    name,
    phone,
    valid: validPers,
    ban,
  } = useSelector((state) => state.sale.order.personal);

  const [nErr, setNErr] = useState("");
  const [pErr, setPErr] = useState("");
  const [validN, setValidN] = useState(name ? true : null);
  const [validP, setValidP] = useState(phone ? true : null);

  const isValidN = () => {
    let valid = true;
    if (name === "") {
      valid = false;
      setNErr(t("EMPTY_FIELD"));
    }
    if (name.length === 1) {
      valid = false;
      setNErr(t("INVALID_VALUE"));
    }
    setValidN(valid);
    if (valid && validP) saveContacts();
  };
  const isValidP = () => {
    let valid = true;
    const validPhone = phone.replace(/[^0-9]*/g, "").replace(/^8/, "7");
    if (phone === "") {
      valid = false;
      setPErr(t("EMPTY_FIELD"));
    }
    if (phone && validPhone.length !== 11) {
      valid = false;
      setPErr(t("INVALID_VALUE"));
    }
    setValidP(valid);
    if (validN && valid) saveContacts();
  };
  const saveContacts = () => {
    const validPhone = phone.replace(/[^0-9]*/g, "").replace(/^8/, "7");
    dispath(
      saleActions.orderSetPersonal({
        name,
        phone: validPhone,
        valid: true,
        ban,
      })
    );
  };
  const nameChange = (text) => {
    setNErr("");
    setValidN(null);
    dispath(
      saleActions.orderSetPersonal({
        name: text,
        phone,
        valid: validN && validP,
        ban,
      })
    );
  };
  const phoneChange = (text) => {
    setPErr("");
    setValidP(null);
    dispath(
      saleActions.orderSetPersonal({
        name,
        phone: text,
        valid: validN && validP,
        ban,
      })
    );
  };
  useEffect(() => {
    if (usName && login) {
      dispath(
        saleActions.orderSetPersonal({
          name: usName,
          phone: login.replace(/^7/, "8"),
          valid: true,
          ban,
        })
      );
      setValidP(true);
      setValidN(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usName, login]);
  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <OrderContactsD
          params={{
            name,
            setName: nameChange,
            phone,
            setPhone: phoneChange,
            nErr,
            pErr,
            validN,
            validP,
            isValidN,
            isValidP,
          }}
        />
      )}
      renderMobile={() => (
        <OrderContactsM
          params={{
            name,
            setName: nameChange,
            phone,
            setPhone: phoneChange,
            valid: validPers,
            nErr,
            pErr,
            validN,
            validP,
            isValidN,
            isValidP,
          }}
        />
      )}
    />
  );
};
export default OrderContacts;
