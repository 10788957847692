export default class CCApiBase {
  baseUrl = "https://cc.kz/api/v2/";
  apiKey = "";

  async init() {
    let key = localStorage.getItem("ccapi_key");
    if (key) {
      this.apiKey = key;
      return true;
    } else {
      const urlKey = this.getKey();
      const res = await this.getData({ method: "GET", url: urlKey });
      if (res.auth_key) {
        return true;
      }
      return false;
    }
  }

  async sendRequest(url = "", init = {}) {
    if (this.apiKey) {
      init.headers = {
        Authorization: "Bearer " + this.apiKey,
        "Content-Type": "application/json",
      };
    } else {
      init.headers = {
        "Content-Type": "application/json",
      };
    }

    try {
      const conect = await fetch(url, init);
      const res = await conect.json();
      //Сохраняем ключ если он приходит с сервера
      if (res.auth_key) {
        this.apiKey = res.auth_key;
        localStorage.setItem("ccapi_key", res.auth_key);
      }
      return res;
    } catch (error) {
      return { error: "Сервис недоступен. Попробуйте позже" };
    }
  }
  getKey() {
    let md5 = require("md5");
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let day = today.getDate();
    if (day < 10) day = "0" + day;
    const todayStr = year + "-" + month + "-" + day;
    return md5(todayStr + "cc_key_token").slice(0, 8);
  }

  getData({ method, url = "", data = {} }) {
    const init = {
      method,
    };
    url = this.baseUrl + url;
    if (method === "GET") {
      url = url + "?";
      for (let key in data) {
        if (key === "filter" || key === "filterDef") {
          if (data[key]) url = url + data[key]; //+ '&'
        } else {
          if (data[key]) url = url + key + "=" + data[key] + "&";
        }
      }
      url = url.slice(0, -1);
    } else {
      init.body = JSON.stringify(data);
    }
    return this.sendRequest(url, init);
  }
}
