import React, { createContext, useContext, useEffect, useState } from 'react'

import fireApp from 'firebase/app'
// eslint-disable-next-line no-unused-vars
import analytics from 'firebase/analytics'


const FireCtx = createContext(null)
const config = {
    apiKey: "AIzaSyBN5tUcgYL2l3__GsPV6bq6zxiBjcOc5jc",
    authDomain: "cc-kz-app.firebaseapp.com",
    projectId: "cc-kz-app",
    storageBucket: "cc-kz-app.appspot.com",
    messagingSenderId: "559440593197",
    appId: "1:559440593197:web:27918a451b9e512989d3a4",
    measurementId: "G-XF6MHWXQMN"
}

fireApp.initializeApp(config)

export const FireProvider = ({children})=>{    
    const [fire,setFire] = useState(null)
    useEffect(()=>{
        const analitics = fireApp.analytics()
        setFire(analitics)
        return ()=>setFire(null)
    },[])
    return (
        <FireCtx.Provider value={fire}>
            {children}
        </FireCtx.Provider>
    )
}
export const useFireAnalitic = ()=>useContext(FireCtx)