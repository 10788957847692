import React, { useState, useEffect } from "react";
import { FaBoxOpen } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";
import { useWindowDimensions } from "../WindowDimensionsProvider";

import AppSpinner from "../AppSpinner";
import { PersonalOrdersItem } from "./PersonalOrdersItem";

import "./personal-orders.css";

const PersonalOrders = () => {
  const { t } = useTranslation();
  const { isMobil } = useWindowDimensions();
  const bitrix = useBitrix();

  const [orders, setOrders] = useState([]);
  const [load, setLoad] = useState(false);
  const getList = () => {
    setLoad(true);
    bitrix.sale
      .getMyOrders()
      .then((data) => {
        if (Array.isArray(data)) setOrders(data);
      })
      .catch((e) => {})
      .finally(() => setLoad(false));
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (load) {
    return (
      <div className="personal-orders d-flex align-items-center justify-content-center w-100">
        <AppSpinner />
      </div>
    );
  }
  if (orders.length === 0) {
    return (
      <div className="personal-orders d-flex align-items-center justify-content-center w-100">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center mb-2">
            <FaBoxOpen className="mr-2" color="#8cc83c" size="36" />
            <h2>{t("NOT_ORDERS")}</h2>
          </div>
          <strong className="mb-2">{t("NOT_ORDERS_MSG")}</strong>
          <Link to="/discounts/" className="btn btn-success">
            {t("GET_TO_BUY")}
          </Link>
        </div>
      </div>
    );
  }
  if (isMobil) {
    return (
      <div className="d-flex flex-column">
        {orders.map((item, key) => (
          <PersonalOrdersItem
            className="mb-2 w-100"
            order={item}
            refreshList={getList}
            key={"order" + key}
          />
        ))}
      </div>
    );
  }
  return (
    <div className="d-flex align-items-center">
      {orders.map((item, key) => (
        <PersonalOrdersItem
          order={item}
          refreshList={getList}
          key={"order" + key}
        />
      ))}
    </div>
  );
};
export default PersonalOrders;
