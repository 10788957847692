import React, { createContext, useContext, useState, useEffect } from 'react'
import CCApiService from '../services/ccapi'
import AppSpinner from './AppSpinner'
import CrashPage from './StaticPage/CrashPage'

const BitrixCtx = createContext(null)

export const BitrixProvider = ({ children })=>{    
    const [bitrix,setBitrix] = useState(null)
    const [hasError,setError] = useState(false)
    const init = async ()=>{
        setError(false)
        const ccapi = new CCApiService()
        const res = await ccapi.init()
        if(res){
            setBitrix(ccapi)
        }else{
            setError(true)
        }
        
    }
    useEffect(()=>{        
        init()
        return ()=>setBitrix(null)      
    },[])

    if(bitrix){
        return (            
            <BitrixCtx.Provider value={bitrix}> 
                {children}
            </BitrixCtx.Provider>
        )
    }
    if(hasError){
        return <ErrorIndicator init={init}/>
        
    }
    return (
        <main className="d-flex justify-content-center align-items-center" style={{minHeight:'100vh'}}>
            <AppSpinner/>
        </main>
    )
    
}
export const useBitrix = ()=>useContext(BitrixCtx)

const ErrorIndicator = ({init})=>{
    return (
        <main className="d-flex justify-content-center align-items-center" style={{minHeight:'100vh'}}>
            <CrashPage reload={init}/>
        </main>
    )
}