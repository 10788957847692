import React from "react";
import { useTranslation } from "react-i18next";

export const CatalogSortD = ({ sort, action }) => {
  const { t } = useTranslation();
  let value = sort ? sort : "name";
  return (
    <div className="catalog-sort" onChange={(e) => action(e.target.value)}>
      <select defaultValue={value}>
        <option value="name" className="catalog-sort-item catalog-sort-name">
          {t("SORT_NAME")}
        </option>
        <option
          value="price_up"
          className="catalog-sort-item catalog-sort-price-up"
        >
          {t("SORT_PRICE_UP")}
        </option>
        <option
          value="price_down"
          className="catalog-sort-item catalog-sort-price-down"
        >
          {t("SORT_PRICE_DOWN")}
        </option>
      </select>
    </div>
  );
};
