import React from "react";
import DayPicker from "react-day-picker";
import { useTranslation } from "react-i18next";

import "react-day-picker/lib/style.css";

export const AppDatePicker = ({ params }) => {
  const { t } = useTranslation();

  const modifiers = {};
  let initialMonth = new Date();
  const activeDateParser = params.activeData.data.match(
    /^([0-9]{2}).([0-9]{2}).([0-9]{4})$/
  );
  if (Array.isArray(activeDateParser) && activeDateParser.length > 0) {
    modifiers["highlighted"] = new Date(
      parseInt(activeDateParser[3]),
      parseInt(activeDateParser[2]) - 1,
      parseInt(activeDateParser[1])
    );
    initialMonth = new Date(
      parseInt(activeDateParser[3]),
      parseInt(activeDateParser[2]) - 1
    );
  }
  const MONTHS = [
    t("JANUARY"),
    t("FEBRUARY"),
    t("MARCH"),
    t("APRIL"),
    t("MAY"),
    t("JUNE"),
    t("JULY"),
    t("AUGUST"),
    t("SEPTEMBER"),
    t("OCTOBER"),
    t("NOVEMBER"),
    t("DECEMBER"),
  ];
  const WEEKDAYS_LONG = [
    t("SUNDAY"),
    t("MONDAY"),
    t("TUESDAY"),
    t("WEDNESDAY"),
    t("THURSDAY"),
    t("FRIDAY"),
    t("SATURDAY"),
  ];
  const WEEKDAYS_SHORT = [
    t("SUNDAY_SHORT"),
    t("MONDAY_SHORT"),
    t("TUESDAY_SHORT"),
    t("WEDNESDAY_SHORT"),
    t("THURSDAY_SHORT"),
    t("FRIDAY_SHORT"),
    t("SATURDAY_SHORT"),
  ];

  const selectDays = Object.keys(params.times).map((item) => {
    const parseDate = item.match(/^([0-9]{2}).([0-9]{2}).([0-9]{4})$/);
    return new Date(
      parseInt(parseDate[3]),
      parseInt(parseDate[2]) - 1,
      parseInt(parseDate[1])
    );
  });

  return (
    <DayPicker
      initialMonth={initialMonth}
      locale="ru"
      months={MONTHS}
      weekdaysLong={WEEKDAYS_LONG}
      weekdaysShort={WEEKDAYS_SHORT}
      firstDayOfWeek={1}
      selectedDays={selectDays}
      modifiers={modifiers}
      onDayClick={(d, m) => {
        if (m.selected) {
          params.setActiveData({
            data: d.toLocaleDateString("ru-RU"),
            time: "",
          });
        }
      }}
      onMonthChange={() => params.setActiveData({ data: "", time: "" })}
    />
  );
};
