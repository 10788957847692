import md5 from "md5";
import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { PersonalOrdersDetail } from "./PersonalOrdersDetail";
import { PersonalOrdersRepeat } from "./PersonalOrdersRepeat";
import { PersonalOrdersPay } from "./PersonalOrdersPay";
import { PersonalOrdersCancel } from "./PersonalOrdersCancel";
import { PersonalOrdersEdit } from "./PersonalOrdersEdit";
import { PersonalOrdersRating } from "./PersonalOrdersRating";
import { PersonalOrderStatus } from "./PersonalOrderStatus";

import loadImg from "./load.svg";

export const PersonalOrdersItem = ({
  order,
  refreshList = null,
  className = "",
}) => {
  const { t } = useTranslation();

  const classWrap = (id) => {
    if (id === "F") {
      return "personal-order-item done " + className;
    }
    if (id === "CA") {
      return "personal-order-item cancel " + className;
    }
    return "personal-order-item " + className;
  };

  return (
    <div className={classWrap(order.STATUS_ID)}>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <strong className="mb-1">
            {t("ORDER_LABEL")} №{order.ACCOUNT_NUMBER}
          </strong>
          <small>{order.DATA}</small>
          <div className="d-flex align-items-center">
            <span className="mr-2 text-small">{t("ORDER_BASKET_SUM")}</span>
            <strong className="text-small">
              {Math.round(parseFloat(order.PRICE))}
              {t("TENGE")}
            </strong>
          </div>
          {order.STATUS_ID === "F" && refreshList ? (
            <Link
              to={
                "/feedback/?order=" +
                order.ID +
                "&hash=" +
                md5(order.ID + "ordr_feedback")
              }
              className="feed-link"
            >
              {t("FEEDBACK_SAVE")}
            </Link>
          ) : null}
        </div>
        <OrdersRight
          status={order.STATUS_ID}
          info={order.DATA_TIME}
          order={order}
        />
      </div>
      <PersonalOrderStatus id={order.STATUS_ID} delivery={order.DELIVERY_ID} />
      <OrderButtons order={order} />
      {order.STATUS_ID === "N" ? (
        <PersonalOrdersCancel order={order} refreshList={refreshList} />
      ) : null}
    </div>
  );
};

const OrdersRight = ({ status, info = "", order }) => {
  const { t } = useTranslation();

  if (status === "F") {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <FaCheckCircle color="#00994D" size="36" className="mr-2" />
          <div className="d-flex flex-column">
            <strong>{t("ORDER_LABEL")}</strong>
            <strong>{t("ORDER_DONE")}</strong>
          </div>
        </div>
        <PersonalOrdersRating order={order} />
      </div>
    );
  }
  if (status === "CA") {
    return (
      <div className="d-flex align-items-center">
        <FaTimesCircle color="red" size="36" className="mr-2" />
        <div className="d-flex flex-column">
          <strong>{t("ORDER_LABEL")}</strong>
          <strong>{t("ORDER_CANCEL")}</strong>
        </div>
      </div>
    );
  }
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {/* <FaHourglassHalf color="#ffc850" size="36" className="mb-2"/> */}
      <Image src={loadImg} className="mb-2" />
      <strong className="orders-right-text">
        {t("DELIVERY_LABEL")}/ {t("DELIVERY_PICK_LABEL")}
      </strong>
      <strong className="orders-right-text">{info}</strong>
    </div>
  );
};
const OrderButtons = ({ order }) => {
  return (
    <div className="personal-orders-buttons">
      {order.STATUS_ID !== "F" && order.STATUS_ID !== "CA" ? (
        <PersonalOrdersPay order={order} />
      ) : null}
      <PersonalOrdersRepeat order={order} />
      {order.STATUS_ID === "N" ? <PersonalOrdersEdit order={order} /> : null}
      <PersonalOrdersDetail order={order} />
    </div>
  );
};
