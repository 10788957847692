import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";

import { useFireAnalitic } from "../FireProvider";
import ym from "react-yandex-metrika";

import { useBitrix } from "../BitrixProvider";
import { userActions } from "../../state/actions";

import "./registration.css";

const Registration = () => {
  const { t, i18n } = useTranslation();
  const analist = useFireAnalitic();
  const history = useHistory();
  const dispath = useDispatch();
  const bitrix = useBitrix();
  const [login, setLogin] = useState("");
  const [errLogin, setErrLogin] = useState("");
  const [validLogin, setValidLodin] = useState(false);
  const [name, setName] = useState("");
  const [errName, setErrName] = useState("");
  const [validName, setValidName] = useState(false);
  const [load, setLoad] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [errSmsCode, setErrSmsCode] = useState("");
  const [screen, setScreen] = useState(1);
  const [data, setData] = useState(null);
  const setField = (val, change, err) => {
    change(val);
    err("");
  };
  const validField = (field) => {
    switch (field) {
      case "name":
        const errorSimName = name.replace(
          /[а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\s-]*/g,
          ""
        );
        let nameValid = true;
        if (name && errorSimName.length > 0) {
          setErrName(t("INVALID_VALUE"));
          nameValid = false;
        }
        if (!name) {
          setErrName(t("EMPTY_FIELD"));
          nameValid = false;
        }
        if (nameValid) setValidName(true);
        break;
      case "login":
        const validPhone = login.replace(/[^0-9]*/g, "").replace(/^8/, "7");
        let isLoginValid = true;
        if (!login) {
          setErrLogin(t("EMPTY_FIELD"));
          isLoginValid = false;
        }
        if (login && validPhone.length !== 11) {
          setErrLogin(t("INVALID_VALUE"));
          isLoginValid = false;
        }

        setValidLodin(isLoginValid);
        break;
      case "code":
        if (!smsCode) {
          setErrSmsCode(t("EMPTY_FIELD"));
        }
        break;
      default:
    }
  };
  const startReg = (e) => {
    e.preventDefault();
    let valid = true;
    const validPhone = login.replace(/[^0-9]*/g, "").replace(/^8/, "7");
    const fields = ["name"];
    fields.forEach((item) => validField(item));
    if (validPhone.length !== 11) {
      setErrLogin(t("INVALID_VALUE"));
      valid = false;
    }
    if (valid && !errName && !errLogin) {
      const userData = {
        phone: validPhone,
        name,
      };
      setLoad(true);
      bitrix.users
        .auth(userData)
        .then((res) => {
          setLoad(false);
          if (res.smstoken) {
            setData(res);
            setScreen(2);
          }
          if (res.error) {
            setErrLogin(res.error);
          }
        })
        .catch((e) => {
          setLoad(false);
        });
    }
  };

  const endReg = (e) => {
    e.preventDefault();
    let valid = true;
    if (!smsCode) {
      setErrSmsCode(t("EMPTY_FIELD"));
      valid = false;
    }
    if (smsCode.replace(/[^0-9]*/g, "").length !== 4) {
      setErrSmsCode(t("INVALID_SMS_CODE_LABEL"));
      valid = false;
    }
    if (valid) {
      const userData = {
        ...data,
        sms: smsCode.replace(/[^0-9]*/g, ""),
      };
      setLoad(true);
      bitrix.users
        .auth(userData)
        .then((res) => {
          setLoad(false);
          if (res.user) {
            const user = {
              id: res.user.ID,
              name: res.user.NAME,
              login: res.user.LOGIN,
              favorite: res.user.FAVORITE,
              address: res.user.ADDRESS,
              bonuscard: res.user.BONUSCARD,
            };
            dispath(userActions.setUser(user));
            history.push("/personal/");
            if (analist) analist.logEvent("sign_up");
            if (ym) ym("reachGoal", "reg-form-send");
          }
          if (res.error) {
            setErrSmsCode(res.error);
          }
        })
        .catch((e) => {
          setLoad(false);
        });
    }
  };

  if (screen === 2) {
    return (
      <div className="reg-wrap">
        <h3>{t("REGISTRATION_LABEL")}</h3>
        <div
          className="mt-3 mb-3 d-flex align-items-center return-text"
          onClick={() => {
            setLoad(false);
            setScreen(1);
          }}
        >
          <FaArrowLeft className="mr-2" />
          <strong>{t("RETURN_REG_FORM")}</strong>
        </div>
        <div className="mt-3 mb-3">
          <strong>
            {data.msg ? data.lang[i18n.resolvedLanguage] : t("REG_SEND_SMS")}
          </strong>
        </div>
        <Form onSubmit={endReg}>
          <Form.Group controlId="reg-sms">
            <Form.Label>{t("CODE_LABEL")}</Form.Label>
            <Form.Control
              type="text"
              value={smsCode}
              onChange={(e) =>
                setField(e.target.value, setSmsCode, setErrSmsCode)
              }
              isInvalid={errSmsCode}
              disabled={load}
              onBlur={() => validField("code")}
            />
            <Form.Control.Feedback type="invalid">
              {errSmsCode}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-center mt-3">
            <button
              className="btn btn-success"
              type="submit"
              disabled={load}
              onClick={endReg}
            >
              {load ? <Spinner animation="border" size="sm" /> : t("SAVE")}
            </button>
          </div>
        </Form>
      </div>
    );
  }
  return (
    <div className="reg-wrap">
      <h3>{t("REGISTRATION_LABEL")}</h3>
      <Form onSubmit={startReg}>
        <Form.Group controlId="reg-name">
          <Form.Label>{t("NAME_LABEL")}</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setField(e.target.value, setName, setErrName)}
            isInvalid={errName}
            isValid={validName}
            disabled={load}
            onBlur={() => validField("name")}
          />
          <Form.Control.Feedback type="invalid">
            {errName}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="reg-login">
          <Form.Label>{t("PHONE_LABEL")}</Form.Label>
          <Form.Control
            type="text"
            value={login}
            onChange={(e) => setField(e.target.value, setLogin, setErrLogin)}
            isInvalid={errLogin}
            isValid={validLogin}
            disabled={load}
            onBlur={() => validField("login")}
          />
          <Form.Control.Feedback type="invalid">
            {errLogin}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="mt-3 oferta-link">
          <a
            href="https://cc.kz/upload/info/Privacy_Policy.pdf"
            target="_blanck"
          >
            <Trans i18nKey="CONFIRM_RULES_REG">
              <small></small>
              <small className="text-link"></small>
            </Trans>
          </a>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-success"
            type="submit"
            disabled={load}
            onClick={startReg}
          >
            {load ? <Spinner animation="border" size="sm" /> : t("DONE_REG")}
          </button>
        </div>
      </Form>
    </div>
  );
};
export default Registration;
