import React from "react";
import { FaMapMarkerAlt, FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const PersonalAddressItem = ({ address, delAddress }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex personal-addres-item align-items-center">
      <FaMapMarkerAlt color="#8cc83c" className="mr-2" />
      <span className="mr-2">{address}</span>
      <FaTrash
        color="#8cc83c"
        onClick={delAddress}
        className="personal-addres-item-del"
        title={t("DEL_ADDRESS_LABEL")}
      />
    </div>
  );
};
