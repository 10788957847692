import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useBitrix } from "../BitrixProvider";
import { useTranslation } from "react-i18next";
import { catalogActions } from "../../state/actions";

import { ResponsiveLayout } from "../ResponsiveLayout";

import "./catalog-section.css";
import AppSpinner from "../AppSpinner";
import { CatalogSectionD } from "./CatalogSectionD";
import { CatalogScroll } from "./CatalogScroll";
import { CatalogSectionM } from "./CatalogSectionM";
import CatalogSlider from "../CatalogSlider";
import { FavoriteButton } from "../PersonalFavorite/FavoriteButton";

const CatalogSection = ({ header = null }) => {
  const { t } = useTranslation();
  const { settings } = useSelector((state) => state.catalog.main);
  const dispath = useDispatch();
  const bitrix = useBitrix();

  const [prods, setProds] = useState([]);
  const [load, toggleLoad] = useState(false);
  const [err, setErr] = useState("");
  const [param, setParam] = useState({
    page: 1,
    pagesNav: 1,
  });

  useEffect(() => {
    setParam({ ...param, page: 1 });
    if (settings.filter || settings.sort) {
      getProds((data) => setProds(data), {
        ...settings,
        typeReq: "filter",
        page: 1,
      });
    } else {
      getProds((data) => setProds(data), {
        ...settings,
        typeReq: "new",
        page: 1,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    settings.sect,
    settings.searchList,
    settings.brands,
    settings.filterDef,
    settings.sort,
    settings.filter,
  ]);

  const getProds = (
    loadProds,
    reqSet = { ...settings, ...param },
    finish = null
  ) => {
    // console.log(reqSet)
    if (
      !reqSet.sect &&
      !reqSet.searchList &&
      !reqSet.filterDef &&
      !reqSet.brands
    ) {
      setErr(t("PRODS_NOT_FOUND"));
    } else {
      setErr("");
      if (reqSet.typeReq !== "page") toggleLoad(true);
      bitrix.catalog
        .getCatalogList({ ...reqSet })
        .then((data) => {
          if (
            data["ARRAY"] &&
            Array.isArray(data["ARRAY"]) &&
            data["ARRAY"].length > 0
          ) {
            loadProds(data["ARRAY"]);
          } else {
            setErr(t("PRODS_NOT_FOUND"));
          }
          if (
            data["FILTERS"] &&
            Array.isArray(data["FILTERS"]) &&
            data["FILTERS"].length > 0
          ) {
            dispath(catalogActions.main.setFilters(data["FILTERS"]));
          }
          if (data["PAGES"] > 0) {
            if (param.pagesNav !== parseInt(data["PAGES"])) {
              if (param.page > parseInt(data["PAGES"])) {
                setParam({ page: 1, pagesNav: parseInt(data["PAGES"]) });
              } else {
                setParam({ ...param, pagesNav: data["PAGES"] });
              }
            }
          }
          if (finish) finish();
        })
        .catch((e) => setErr(t("SERVICE_NOT_FOUND")))
        .finally(() => {
          if (reqSet.typeReq !== "page") toggleLoad(false);
        });
    }
  };
  const scrollHandler = (success = () => {}, oldPage) => {
    let newPage = oldPage + 1;
    if (newPage <= param.pagesNav) {
      setParam({ ...param, page: newPage });
      getProds(
        (data) => setProds([...prods, ...data]),
        { ...settings, ...param, page: newPage, typeReq: "page" },
        success
      );
    }
  };
  if (load) {
    return (
      <div className="catalog-section-load">
        <AppSpinner />
      </div>
    );
  }
  if (err) {
    return (
      <div>
        <div className="alert alert-warning" role="alert">
          <h3>{err}</h3>
        </div>
        <div>
          <CatalogSlider
            type="discounts"
            header={(id) => <FavoriteButton prodId={id} />}
          />
        </div>
      </div>
    );
  }
  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <Fragment>
          <CatalogSectionD prods={prods} cardHead={header} />
          <CatalogScroll
            action={scrollHandler}
            page={param.page}
            maxPage={param.pagesNav}
          />
        </Fragment>
      )}
      renderMobile={() => (
        <Fragment>
          <CatalogSectionM prods={prods} cardHead={header} />
          <CatalogScroll
            action={scrollHandler}
            page={param.page}
            maxPage={param.pagesNav}
          />
        </Fragment>
      )}
    />
  );
};
export default CatalogSection;
