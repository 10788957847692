import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useWindowDimensions } from "../components/WindowDimensionsProvider";

import CatalogSection from "../components/CatalogSection";
import CatalogCategories from "../components/CatalogCategories";
import CatalogFilter from "../components/CatalogFilter";
import CatalogTopPanel from "../components/CatalogTopPanel";
import Breadcrumbs from "../components/Breadcrumbs";
import { BlurbCatalogSearch } from "../components/blurb-catalog-search/BlurbCatalogSearch";
import { BlurbCategory } from "../components/blurb-category/BlurbCategory";
import { CategoryBanner } from "../components/category-banner/CategoryBanner";

export const CatalogSectionPage = ({ name = "", header = null }) => {
  const { isMobil } = useWindowDimensions();
  if (isMobil) {
    return (
      <main className="pt-3">
        <Container>
          <Row>
            <Col>
              <CatalogTopPanel />
              <CatalogSection header={header} />
            </Col>
          </Row>
        </Container>
      </main>
    );
  }
  return (
    <main className="pt-3">
      <Container>
        <Row>
          <Col lg={3} className="mb-3">
            <CatalogCategories />
            <CatalogFilter />
          </Col>
          <Col lg={9}>
            <Breadcrumbs />
            <h1>{name}</h1>
            <CatalogTopPanel />
            <CategoryBanner />
            <BlurbCategory />
            <BlurbCatalogSearch />
            <CatalogSection header={header} />
          </Col>
        </Row>
      </Container>
    </main>
  );
};
