import React from "react";
import { FaSearch } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";

export const CatalogSearchD = ({
  search,
  list,
  setSearch,
  clear,
  clearReq,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const submitHandler = (e) => {
    e.preventDefault();
    if (search) {
      history.push("/search/" + clearReq(search) + "/");
      clear();
    }
    return false;
  };

  return (
    <Col xs={9} lg={3}>
      <form
        className="d-flex align-items-center position-relative"
        onSubmit={submitHandler}
      >
        <input
          type="text"
          className="search-input"
          placeholder={t("SEARCH_PLACEHOLDER")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button type="submit" className="search-buttom">
          <FaSearch color="white" size={20} />
        </button>
      </form>
      {list.length > 0 && search.length > 4 ? (
        <div className="catalog-search-title">
          {list.map((item) => (
            <Link
              to={item.DETAIL_PAGE_URL}
              onClick={clear}
              className="catalog-search-title-list-item"
              key={item.ID}
            >
              {item.NAME}
            </Link>
          ))}
          <Link
            to={"/search/" + clearReq(search) + "/"}
            onClick={clear}
            className="catalog-search-title-list-item text-bold"
          >
            {t("ALL_RESULTS")}
          </Link>
        </div>
      ) : null}
    </Col>
  );
};
