import React, { useState, Fragment } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CatalogSearchM = ({ search, setSearch, clear, list }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [show, toggle] = useState(false);

  const clearForm = () => {
    clear();
    toggle(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (search) {
      history.push("/search/" + search + "/");
      clear();
      toggle(false);
    }
    return false;
  };

  return (
    <Fragment>
      <FaSearch color="#FFEF16" size={30} onClick={() => toggle(!show)} />
      {show ? (
        <form className="catalog-search-title-mobil" onSubmit={submitHandler}>
          <input
            type="text"
            className="catalog-search-title-mobil-input"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("SEARCH_PLACEHOLDER")}
          />
          {search ? (
            <FaTimes
              onClick={clear}
              className="catalog-search-title-mobil-input-clear"
            />
          ) : null}
        </form>
      ) : null}
      {list.length > 0 ? (
        <div className="catalog-search-title-mobil-list">
          {list.map((item, id) => {
            if (id < 5) {
              return (
                <Link
                  to={item.DETAIL_PAGE_URL}
                  onClick={clearForm}
                  className="catalog-search-title-list-item"
                  key={item.ID}
                >
                  {item.NAME}
                </Link>
              );
            } else {
              return <Fragment key={item.ID} />;
            }
          })}
          <Link
            to={"/search/" + search + "/"}
            onClick={clearForm}
            className="catalog-search-title-list-item text-bold"
          >
            {t("ALL_RESULTS")}
          </Link>
        </div>
      ) : null}
    </Fragment>
  );
};
