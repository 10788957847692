import React from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { FaDoorClosed } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { useWindowDimensions } from "../components/WindowDimensionsProvider";

import PersonalInfo from "../components/PersonalInfo";
import PersonalTabs from "../components/PersonalTabs";
import PersonalMobilView from "../components/PersonalMobilView";
import CatalogSlider from "../components/CatalogSlider";
import Breadcrumbs from "../components/Breadcrumbs";
import { FavoriteButton } from "../components/PersonalFavorite/FavoriteButton";

export const PersonalPage = () => {
  const { t } = useTranslation();
  const { isMobil } = useWindowDimensions();
  const { id } = useSelector((state) => state.user);

  if (!id) {
    return (
      <main className="pt-4 d-flex justify-content-center align-items-center flex-column">
        <FaDoorClosed color="#8cc83c" size="120" />
        <h1>{t("ACCESS_DENIED")}</h1>
        <strong>{t("ACCESS_TO_PERSONAL_CONDITION")}</strong>
      </main>
    );
  }
  if (isMobil) {
    return (
      <main className="pt-1">
        <Container>
          <Row>
            <Col>
              <PersonalMobilView />
            </Col>
          </Row>
        </Container>
      </main>
    );
  }
  return (
    <main className="pt-4">
      <Container>
        <Breadcrumbs />
        <Row>
          <Col>
            <h1>{t("PERSONAL_CABINET")}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <PersonalInfo />
          </Col>
        </Row>
        <Row>
          <Col>
            <PersonalTabs />
          </Col>
        </Row>
        <Row>
          <Col>
            <CatalogSlider
              type="banner"
              header={(id) => <FavoriteButton prodId={id} />}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};
