import React, { useEffect, useState, Fragment } from "react";
import { Form, Spinner, FormControl } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  YMaps,
  Map,
  ZoomControl,
  SearchControl,
  Placemark,
} from "react-yandex-maps";

import { useWindowDimensions } from "../WindowDimensionsProvider";

import icon from "./icon.png";

export const AddressMap = ({ ymaps, saveAddress, addressList }) => {
  const { isMobil } = useWindowDimensions();
  const { t } = useTranslation();

  const [placeMarck, setPlaceMarck] = useState(null);
  const [placeMarckList, setPalceMarckList] = useState([]);
  const [mapCenter, setMapCenter] = useState([53.214422, 63.631976]);
  const [address, setAddress] = useState("");
  const [load, toggleLoad] = useState(false);

  const mapClick = async (e) => {
    const coords = e.get("coords");
    const addr = await ymaps.getAddress(coords);
    setAddress(addr);
    setPlaceMarck(coords);
    setMapCenter(coords);
  };
  const searchClick = async (e) => {
    const coords = e.get("target").state._data.results[0].geometry._coordinates;
    const addr = await ymaps.getAddress(coords);
    setAddress(addr);
    setPlaceMarck(null);
    setMapCenter(coords);
  };
  const saveAddressHandler = async (fulladdress) => {
    toggleLoad(true);
    saveAddress(fulladdress);
    toggleLoad(false);
    setPlaceMarck(null);
    setAddress("");
  };
  useEffect(() => {
    if (Array.isArray(addressList) && addressList.length > 0 && ymaps.ymaps) {
      const requests = addressList.map((item) => ymaps.getCoords(item));
      Promise.all(requests).then((responses) => setPalceMarckList(responses));
    } else {
      setPalceMarckList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList, ymaps.ymaps]);
  return (
    <YMaps
      query={{ lang: "ru_RU", apikey: "c5af4455-6b73-439c-ad0a-f2f40be2ff58" }}
    >
      <Map
        className="delivery-map"
        onClick={mapClick}
        instanceRef={(m) => ymaps.setMap(m)}
        state={{ center: mapCenter, zoom: 12 }}
      >
        <ZoomControl options={{ size: "large" }} />
        <SearchControl
          options={{
            size: "large",
            float: "none",
            boundedBy: [
              [53.386622, 63.467021],
              [52.95116, 63.784957],
            ],
            strictBounds: true,
            placeholderContent: t("SET_ADDRESS"),
            position: {
              top: isMobil ? 15 : 10,
              left: isMobil ? 10 : 10,
              right: 0,
            },
            maxWidth: 260,
          }}
          onResultShow={searchClick}
        />
        {placeMarck ? (
          <Placemark
            geometry={placeMarck}
            options={{
              iconLayout: "default#image",
              iconImageSize: [27, 40],
              iconImageHref: icon,
            }}
          />
        ) : null}
        {address ? (
          <MapPanel
            address={address}
            cancel={() => {
              setAddress("");
            }}
            load={load}
            save={saveAddressHandler}
          />
        ) : null}
        {placeMarckList.length > 0
          ? placeMarckList.map((item, id) => (
              <Placemark
                geometry={item}
                key={"address-map" + id}
                options={{
                  iconLayout: "default#image",
                  iconImageSize: [27, 40],
                  iconImageHref: icon,
                }}
              />
            ))
          : null}
      </Map>
    </YMaps>
  );
};
// const MapPanel = ({address,cancel,save,load})=>{
//   const { t } = useTranslation();
//   const [flat,setFlat] = useState('')
//   const [floor,setFloor] = useState('')
//   const [poor,setPoor] = useState('')
//   const cancelHandler = ()=>{
//     setFlat('')
//     setFloor('')
//     setPoor('')
//     cancel()
//   }
//   const saveHandler = ()=>{
//     let fullAddress = address
//     if(flat) fullAddress+=t('FLAT_LABEL_SMALL')+flat
//     if(poor) fullAddress+=t('FLOOR_LABEL_SMALL')+poor
//     if(floor) fullAddress+=t('POOR_LABEL_SMALL')+floor
//     save(fullAddress)
//     cancelHandler()
//   }
//   return (
//     <div className="delivery-map-address-panel">
//       <h3>{t('YOU_ADDRESS')}:</h3>
//       <div className="text-center">
//         <strong>{address}</strong>
//       </div>
//       <hr/>
//       <Form>
//         <Form.Group>
//           <Form.Control type="text" value={flat} placeholder={t('FLAT_LABEL')} onChange={e=>setFlat(e.target.value)} maxLength={4} disabled={load}/>
//         </Form.Group>
//         <div className="d-flex justify-content-between">
//           <Form.Group className="w-40">
//             <Form.Control type="text" value={floor} placeholder={t('FLOOR_LABEL')} onChange={e=>setFloor(e.target.value)} maxLength={2} disabled={load}/>
//           </Form.Group>
//           <Form.Group className="w-40">
//             <Form.Control type="text" value={poor} placeholder={t('POOR_LABEL')} onChange={e=>setPoor(e.target.value)} maxLength={2} disabled={load}/>
//           </Form.Group>
//         </div>
//         <div className="d-flex justify-content-between">
//           <button type="button" className="btn btn-sm btn-success w-40" onClick={saveHandler} disabled={load}>
//             {load?<Spinner as="span" animation="border" size="sm" role="status"aria-hidden="true"/>:t('SAVE')}
//             </button>
//           <button type="button" className="btn btn-sm btn-warning w-40" onClick={cancelHandler} disabled={load}>{t('CANCEL')}</button>
//         </div>
//       </Form>
//     </div>
//   )
// }
const MapPanel = ({ address, cancel, save, load }) => {
  const { t } = useTranslation();

  const [flat, setFlat] = useState("");
  const [errFlat, setErrFlat] = useState("");
  const [floor, setFloor] = useState("");
  const [errFloor, setErrFloor] = useState("");
  const [poor, setPoor] = useState("");
  const [errPoor, setErrPoor] = useState("");
  const [isHouse, setIsHouse] = useState(false);

  const cancelHandler = () => {
    setFlat("");
    setFloor("");
    setPoor("");
    cancel();
  };
  const saveHandler = () => {
    let fullAddress = address;
    let valid = true;
    if (!isHouse) {
      if (flat) {
        fullAddress += t("FLAT_LABEL_SMALL") + flat;
      } else {
        valid = false;
        setErrFlat("Укажите номер квартиры/офиса");
      }
      if (poor) {
        fullAddress += t("FLOOR_LABEL_SMALL") + poor;
      } else {
        valid = false;
        setErrPoor("Укажите подъезд");
      }
      if (floor) {
        fullAddress += t("POOR_LABEL_SMALL") + floor;
      } else {
        valid = false;
        setErrFloor("Укажите этаж");
      }
    }
    if (valid) {
      save(fullAddress);
      cancelHandler();
    }
  };
  return (
    <div className="delivery-map-address-panel">
      <h3>{t("YOU_ADDRESS")}:</h3>
      <div className="text-center">
        <strong>{address}</strong>
      </div>
      <hr />
      <Form>
        {!isHouse ? (
          <Fragment>
            <Form.Group>
              <Form.Control
                type="text"
                value={flat}
                placeholder={t("FLAT_LABEL")}
                onChange={(e) => {
                  setErrFlat("");
                  setFlat(e.target.value);
                }}
                maxLength={4}
                disabled={load}
                isInvalid={errFlat}
              />
              <FormControl.Feedback type="invalid">
                {errFlat}
              </FormControl.Feedback>
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group className="w-40">
                <Form.Control
                  type="text"
                  value={floor}
                  placeholder={t("FLOOR_LABEL")}
                  onChange={(e) => {
                    setErrFloor("");
                    setFloor(e.target.value);
                  }}
                  maxLength={2}
                  disabled={load}
                  isInvalid={errFloor}
                />
                <FormControl.Feedback type="invalid">
                  {errFloor}
                </FormControl.Feedback>
              </Form.Group>
              <Form.Group className="w-40">
                <Form.Control
                  type="text"
                  value={poor}
                  placeholder={t("POOR_LABEL")}
                  onChange={(e) => {
                    setErrPoor("");
                    setPoor(e.target.value);
                  }}
                  maxLength={2}
                  disabled={load}
                  isInvalid={errPoor}
                />
                <FormControl.Feedback type="invalid">
                  {errPoor}
                </FormControl.Feedback>
              </Form.Group>
            </div>
          </Fragment>
        ) : null}

        <Form.Group>
          <Form.Check
            type="checkbox"
            checked={isHouse}
            id="is-house"
            onChange={() => setIsHouse(!isHouse)}
            label={t("ORDER_PRIVATE_HOUSE")}
            custom
          />
        </Form.Group>
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-sm btn-success w-40"
            onClick={saveHandler}
            disabled={load}
          >
            {load ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t("SAVE")
            )}
          </button>
          <button
            type="button"
            className="btn btn-sm btn-warning w-40"
            onClick={cancelHandler}
            disabled={load}
          >
            {t("CANCEL")}
          </button>
        </div>
      </Form>
    </div>
  );
};
