import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import { catalogActions } from "../../state/actions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";
import { useCrash } from "../ErrorProvider";

import "./catalog-menu.css";
import { Col } from "react-bootstrap";

const CatalogMenu = () => {
  const { t } = useTranslation();
  const { categories } = useSelector((state) => state.catalog.categories);
  const crash = useCrash();
  const dispath = useDispatch();
  const bitrix = useBitrix();

  const [load, toggleLoad] = useState(false);
  const [show, setShow] = useState(false);

  const getCategories = async () => {
    toggleLoad(true);
    const res = await bitrix.catalog.sectTree();
    toggleLoad(false);
    if (typeof res === "object" && Object.keys(res).length > 0 && !res.error) {
      dispath(catalogActions.categories.load(res));
    } else {
      crash.setErr();
    }
  };

  const closeHandler = (e) => {
    if (
      typeof e.target.className === "string" &&
      e.target.className.includes("dropx")
    ) {
    } else {
      setShow(false);
    }
  };
  useEffect(() => {
    getCategories();
    document.addEventListener("click", closeHandler, false);
    return () => document.removeEventListener("click", closeHandler, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (load) {
    return (
      <div className="d-flex justify-content-center">
        <span
          className="spinner-border spinner-border-sm text-app-yellow"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    );
  }
  if (Object.keys(categories).length > 0) {
    return (
      <Fragment>
        <Col xs={4} lg={2}>
          <div className={show ? "dropdown show dropx" : "dropdown dropx"}>
            <span onClick={() => setShow(!show)} className="menu-button dropx">
              Каталог
            </span>
            <div className={show ? "dropdown-menu show" : "dropdown-menu"}>
              <Menu catategories={categories} />
            </div>
          </div>
        </Col>
        <Col xs={4} lg={2}>
          <Link
            to="/catalog/kulinariya_solnechnaya_kukhnya/"
            className="main-menu-item"
          >
            Солнечная кухня
          </Link>
        </Col>
        <Col xs={4} lg={3}>
          <Link to="/discounts/" className="main-menu-item">
            {t("DISCOUNTS")}
          </Link>
        </Col>
      </Fragment>
    );
  }
  return <Fragment />;
};
export default CatalogMenu;

const Menu = ({ catategories }) => {
  const [center, setCenter] = useState(null);
  const [right, setRight] = useState(null);
  useEffect(() => {
    if (!center && catategories)
      setCenter(catategories[Object.keys(catategories)[0]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="d-flex">
      <div className="d-flex flex-column dropdown-menu-left">
        {Object.keys(catategories).map((item) => (
          <MenuItem
            key={item}
            item={catategories[item]}
            click={(elem) => {
              setCenter(elem);
              setRight(null);
            }}
            isActive={center && item === center["CODE"]}
          />
        ))}
      </div>
      <div className="d-flex flex-column dropdown-menu-center">
        {center
          ? Object.keys(center["CHILD"]).map((item) => (
              <MenuItem
                key={item}
                item={center["CHILD"][item]}
                click={setRight}
                isActive={right && item === right["CODE"]}
              />
            ))
          : null}
      </div>
      {right ? (
        <div>
          <div className="d-flex flex-wrap dropdown-menu-rigth">
            {Object.keys(right["CHILD"]).map((item) => (
              <MenuItem key={item} item={right["CHILD"][item]} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const MenuItem = ({ item, isActive = false, click = null }) => {
  const { i18n } = useTranslation();

  if (item.CHILD && click) {
    return (
      <div
        onClick={() => click(item)}
        className={
          isActive
            ? "dropdown-menu-item d-flex justify-content-between align-items-center dropx active"
            : "dropdown-menu-item d-flex justify-content-between align-items-center dropx"
        }
      >
        <span onClick={() => click(item)} className="dropx">
          {item.LANG[i18n.resolvedLanguage]}
        </span>
        <IoIosArrowForward size={14} />
      </div>
    );
  }
  return (
    <Link to={item.SECTION_PAGE_URL} className="dropdown-menu-item">
      {item.LANG[i18n.resolvedLanguage]}
    </Link>
  );
};
