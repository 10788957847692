import React, { Fragment } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const PersonalOrderStatus = ({ id, delivery }) => {
  const { t } = useTranslation();

  if (id !== "F" && id !== "CA") {
    return (
      <div
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginVertical: "10px",
          display: "flex",
        }}
      >
        {id === "ED" ? (
          <OrderStatusItem
            emoji="📝"
            text={t("ED_STATUS")}
            arrow={false}
            active={true}
          />
        ) : null}
        <OrderStatusItem
          emoji="🗳"
          text={t("CO_STATUS")}
          arrow={id === "ED"}
          active={id === "N" || id === "CO"}
          done={["KP", "D", "OW"].includes(id)}
        />
        <OrderStatusItem
          emoji="🛒"
          text={t("KP_STATUS")}
          arrowAcive={["KP", "D", "OW"].includes(id)}
          active={id === "KP"}
          done={["D", "OW"].includes(id)}
        />
        <OrderStatusItem
          emoji={delivery === 3 ? "⌚" : "🚛"}
          text={delivery === 3 ? t("OW_STATUS") : t("D_STATUS")}
          arrowAcive={["D", "OW"].includes(id)}
          active={id === "D" || id === "OW"}
        />
        {id !== "ED" ? (
          <OrderStatusItem emoji="👌" text={t("ORDER_DONE")} />
        ) : null}
      </div>
    );
  }
  return <Fragment />;
};

const OrderStatusItem = ({
  emoji,
  text,
  arrow = true,
  done = false,
  active = false,
  arrowAcive = false,
}) => {
  return (
    <Fragment>
      {arrow ? (
        <FaChevronRight
          color={arrowAcive ? "#008c00" : "rgba(0,0,0,.3)"}
          size={12}
        />
      ) : null}
      <div
        style={
          active
            ? {
                alignItems: "center",
                borderBottom: "2px solid #008c00",
                paddingBottom: 5,
                display: "flex",
                flexDirection: "column",
              }
            : {
                alignItems: "center",
                paddingBottom: 5,
                display: "flex",
                flexDirection: "column",
              }
        }
      >
        <span style={{ fontSize: "16pt" }}>{emoji}</span>
        {active ? (
          <span
            style={{
              fontSize: "8pt",
              lineHeight: "10pt",
              color: "#008c00",
              fontWeight: "bold",
            }}
          >
            {text}
          </span>
        ) : (
          <span
            style={{
              fontSize: "8pt",
              lineHeight: "10pt",
              color: done ? "#008c00" : "rgba(0,0,0,.3)",
            }}
          >
            {text}
          </span>
        )}
      </div>
    </Fragment>
  );
};
