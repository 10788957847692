import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { FaRedoAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";
import { saleActions } from "../../state/actions";

export const PersonalOrdersRepeat = ({ order }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispath = useDispatch();
  const bitrix = useBitrix();

  const [load, setLoad] = useState(false);

  const setRepeat = () => {
    setLoad(true);
    bitrix.sale
      .repeatOrder(order.ID)
      .then((data) => {
        setLoad(false);
        if (Array.isArray(data) && data.length > 0) {
          dispath(saleActions.updateBasket(data));
          history.push("/basket/");
        } else {
          alert(t("DATA_NO_SAVE"));
        }
      })
      .catch((e) => {
        setLoad(false);
      });
  };

  return (
    <button className="btn order-button" disabled={load} onClick={setRepeat}>
      {load ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <FaRedoAlt color="#dc64a0" className="mr-1" />
      )}{" "}
      <span>{t("REPEAT_ORDER")}</span>
    </button>
  );
};
