const getTotal = (basket) => {
  let total = 0;
  basket.map((item) => (total += Math.round(item.QUANTITY * item.PRICE)));
  return total;
};
const updateSale = (state, action) => {
  if (state === undefined) {
    return {
      basket: [],
      total: 0,
      orderId: null,
      panel: {
        show: false,
        prod: {},
      },
      order: {
        personal: {
          name: "",
          phone: "",
          valid: false,
          ban: false,
        },
        delivery: {
          type: 4,
          address: "",
          price: 0,
          valid: false,
          coord: "",
          point: "",
        },
        time: {
          dataTime: "",
          valid: false,
        },
        payment: {
          type: "",
          valid: false,
          text: "",
        },
        comment: "",
        promo: {
          code: "",
          valid: false,
        },
        bonus: {
          value: 0,
          valid: false,
        },
        kaspi: {
          number: "",
          valid: false,
        },
      },
      orderSettings: {
        blockedTime: [],
      },
    };
  }
  switch (action.type) {
    case "UPD_BASKET":
      return {
        ...state.sale,
        basket: action.payload,
        total: getTotal(action.payload),
      };
    case "SALE_PANEL_OPEN":
      return {
        ...state.sale,
        panel: {
          show: true,
          prod: action.payload,
        },
      };
    case "SALE_PANEL_CLOSE":
      return {
        ...state.sale,
        panel: {
          show: false,
          prod: {},
        },
      };
    case "ORDER_PERSONAL":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          personal: action.payload,
        },
      };
    case "ORDER_DELIVERY":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          delivery: action.payload,
        },
      };
    case "ORDER_TIME":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          time: action.payload,
        },
      };
    case "ORDER_PAYMENT":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          payment: action.payload,
        },
      };
    case "ORDER_COMMENT":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          comment: action.payload,
        },
      };
    case "ORDER_SETTINGS_TIME":
      return {
        ...state.sale,
        orderSettings: {
          ...state.sale.orderSettings,
          blockedTime: action.payload,
        },
      };
    case "ORDER_RESET":
      return {
        ...state.sale,
        order: {
          personal: {
            name: "",
            phone: "",
            valid: false,
            ban: false,
          },
          delivery: {
            type: 4,
            address: "",
            price: 0,
            valid: false,
            coord: "",
            point: "",
          },
          time: {
            dataTime: "",
            valid: false,
          },
          payment: {
            type: "",
            valid: false,
            text: "",
          },
          comment: "",
          promo: {
            code: "",
            valid: false,
          },
          bonus: {
            value: 0,
            valid: false,
          },
          kaspi: {
            number: "",
            valid: false,
          },
        },
      };
    case "ORDER_DELIVERY_RESET":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          delivery: {
            type: 4,
            address: "",
            price: 0,
            valid: false,
            coord: "",
            point: "",
          },
          time: {
            dataTime: "",
            valid: false,
          },
          payment: {
            type: "",
            valid: false,
            text: "",
          },
          comment: "",
          promo: {
            code: "",
            valid: false,
          },
          kaspi: {
            number: "",
            valid: false,
          },
        },
      };
    case "ORDER_PROMO":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          promo: action.payload,
        },
      };
    case "SET_DELIVERY_PRICE":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          delivery: {
            ...state.sale.order.delivery,
            price: action.payload,
          },
        },
      };
    case "SET_ORDER_EDIT":
      return {
        ...state.sale,
        orderId: action.payload,
      };
    case "SET_KASPI_NUMBER":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          kaspi: action.payload,
        },
      };
    case "ORDER_SET_BONUS":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          bonus: action.payload,
        },
      };
    case "ORDER_KASPI_NUMBER":
      return {
        ...state.sale,
        order: {
          ...state.sale.order,
          kaspi: action.payload,
        },
      };
    default:
      return state.sale;
  }
};

export default updateSale;
