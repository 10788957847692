import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const BannerSliderM = ({ slider }) => {
  const { i18n } = useTranslation();

  return (
    <div className="banner-slider-mobil">
      {slider.map((item, id) => (
        <Link to={item["HREF"]} key={"pict" + id}>
          <Image
            src={item["LANG"]["PICTURE"][i18n.resolvedLanguage]}
            className="banner-slider-mobil-img"
          />
        </Link>
      ))}
    </div>
  );
};
