import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useFireAnalitic } from "../FireProvider";
import ym from "react-yandex-metrika";
import { usePixel } from "../FbPixelProvider";

import { useBitrix } from "../BitrixProvider";
import { saleActions } from "../../state/actions";

import { ResponsiveLayout } from "../ResponsiveLayout";
import { SaleButtonD } from "./SaleButtonD";
//import { SaleButtonM } from "./SaleButtonM";

import "./sale-button.css";

const SaleButton = ({ prod }) => {
  const analist = useFireAnalitic();
  const pixel = usePixel();
  const { t } = useTranslation();
  const bitrix = useBitrix();
  const dispath = useDispatch();
  const { basket, orderId } = useSelector((state) => state.sale);

  const [value, setValue] = useState(0);
  const setting = getSetting(prod["PROPERTY_CML2_BASE_UNIT_VALUE"]);

  const changeBasket = (quantity) => {
    const changeFun =
      quantity === 0
        ? () => bitrix.sale.delProdFromBasket(prod.ID, orderId)
        : () => bitrix.sale.addProdToBasket(prod.ID, quantity, orderId);
    changeFun().then((res) => {
      if (Array.isArray(res)) {
        if (ym) ym("reachGoal", "add_to_cart");
        if (analist)
          analist.logEvent("add_to_cart", {
            currency: "KZT",
            items: [
              {
                item_id: prod.ID,
                item_name: prod.NAME,
                price: prod.PRICE,
                currency: "KZT",
              },
            ],
            value: quantity,
          });
        if (pixel) {
          pixel.track("AddToCart", {
            content_name: prod.NAME,
            content_type: "product",
            contents: [{ id: prod.ID, quantity }],
            currency: "KZT",
            value: quantity * prod.PRICE,
          });
        }
        dispath(saleActions.updateBasket(res));
      }
    });
  };

  const addOne = () => {
    let newValue = parseFloat(value) + setting.step;
    const maxValue = getMaxValue(prod["PROPERTY_CML2_BASE_UNIT_VALUE"], prod);
    if (newValue > maxValue) newValue = maxValue;
    changeBasket(newValue / setting.ratio);
    setValue(newValue);
  };

  const removeOne = () => {
    let newValue = parseFloat(value) - setting.step;
    if (newValue <= 0) newValue = 0;
    changeBasket(newValue / setting.ratio);
    setValue(newValue);
  };

  const changeValue = (e) => {
    let newValue = e.target.value;
    if (e.target.value !== "") {
      newValue = parseFloat(e.target.value.replace(/[^0-9]*/g, ""))
        ? parseFloat(e.target.value.replace(/[^0-9]*/g, ""))
        : 0;
      const maxValue = getMaxValue(prod["PROPERTY_CML2_BASE_UNIT_VALUE"], prod);
      if (newValue > maxValue) newValue = maxValue;
      if (newValue < 0) newValue = 0;
    }
    setValue(newValue);
  };

  const saveChangeValue = () => {
    if (value === "") {
      setValue(0);
    }
    if (setting.ratio === 1000 && value % 10 !== 0) {
      alert(t("MESURE_WARNING", { value: "10г" }));
      changeBasket(0);
      setValue(0);
    } else if (setting.ratio === 1000 && (value * 10) % 5) {
      alert(t("MESURE_WARNING", { value: "0,5л" }));
      changeBasket(0);
      setValue(0);
    } else {
      changeBasket(value / setting.ratio);
    }
  };

  useEffect(() => {
    const inBasket = basket.find((item) => item.PRODUCT_ID === prod.ID);
    if (inBasket) {
      setValue(inBasket.QUANTITY * setting.ratio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket]);

  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <SaleButtonD
          value={value}
          add={addOne}
          remove={removeOne}
          change={changeValue}
          saveChange={saveChangeValue}
          label={setting.mesure}
          subladel={
            prod.PROPERTY_AVERAGE_WEIGHT_VALUE
              ? Math.round(parseInt(value) / prod.PROPERTY_AVERAGE_WEIGHT_VALUE)
              : null
          }
        />
      )}
      renderMobile={() => (
        <SaleButtonD
          value={value}
          add={addOne}
          remove={removeOne}
          change={changeValue}
          saveChange={saveChangeValue}
          label={setting.mesure}
        />
      )}
      // renderMobile={() => (
      //   <SaleButtonM
      //     value={value}
      //     add={addOne}
      //     remove={removeOne}
      //     change={changeValue}
      //     saveChange={saveChangeValue}
      //     label={setting.mesure}
      //     name={prod.NAME}
      //     sum={Math.round((value / setting.ratio) * parseInt(prod.PRICE))}
      //   />
      // )}
    />
  );
};

const getMaxValue = (type, prod = {}) => {
  if (!prod.PROPERTY_CHANGE_DELIVERY_VALUE && prod.PROPERTY_MAX_VALUE_VALUE)
    return parseInt(prod.PROPERTY_MAX_VALUE_VALUE);
  switch (type) {
    case "кг":
      return 15000;
    case "шт":
      return 150;
    case "л.":
      return 20;
    default:
      return 150;
  }
};

const getSetting = (type) => {
  switch (type) {
    case "кг":
      return {
        mesure: "г",
        ratio: 1000,
        step: 100,
      };
    case "шт":
      return {
        mesure: "шт",
        ratio: 1,
        step: 1,
      };
    case "л.":
      return {
        mesure: "л.",
        ratio: 1,
        step: 0.5,
      };
    default:
      return {
        mesure: "шт",
        ratio: 1,
        step: 1,
      };
  }
};

export default SaleButton;
