import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useFireAnalitic } from "../FireProvider";
import { useBitrix } from "../BitrixProvider";
import { saleActions } from "../../state/actions";

import { ResponsiveLayout } from "../ResponsiveLayout";
import { SaleBasketListItemD } from "./SaleBasketListItemD";
import { SaleBasketListItemM } from "./SaleBasketListItemM";

import "./sale-basket-list.css";
import noImg from "./no-img1.jpg";

const SaleBasketList = () => {
  const analist = useFireAnalitic();
  const { basket, orderId } = useSelector((state) => state.sale);
  const dispath = useDispatch();
  const bitrix = useBitrix();

  const removeProd = (id) => {
    const prod = basket.find((item) => item.PRODUCT_ID === id);
    bitrix.sale.delProdFromBasket(id, orderId).then((res) => {
      if (Array.isArray(res)) {
        if (analist && prod)
          analist.logEvent("remove_from_cart", {
            currency: "KZT",
            value: prod["QUANTITY"],
            items: [
              {
                item_id: prod["PRODUCT_ID"],
                item_name: prod["NAME"],
                price: prod["PRICE"],
                currency: "KZT",
              },
            ],
          });
        dispath(saleActions.updateBasket(res));
      }
    });
  };
  useEffect(() => {
    const updBasket = async () => {
      const res = await bitrix.sale.basket(orderId);
      if (Array.isArray(res)) dispath(saleActions.updateBasket(res));
    };
    updBasket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (basket.length === 0) {
    return <Fragment />;
  }

  return (
    <div className="sale-basket-list">
      {basket.map((item) => {
        const picture = item.PICTURE ? "https://" + item.PICTURE : noImg;
        const prod = {
          NAME: item.NAME,
          ID: item.PRODUCT_ID,
          PROPERTY_CML2_BASE_UNIT_VALUE: item.PROPERTY_CML2_BASE_UNIT,
          PRICE: item.PRICE,
          PROPERTY_MAX_VALUE_VALUE: item.PROPERTY_MAX_VALUE_VALUE,
          PROPERTY_CHANGE_DELIVERY_VALUE: item.PROPERTY_CHANGE_DELIVERY_VALUE,
          PROPERTY_AVERAGE_WEIGHT_VALUE: item.PROPERTY_AVERAGE_WEIGHT_VALUE
            ? item.PROPERTY_AVERAGE_WEIGHT_VALUE
            : null,
        };
        return (
          <ResponsiveLayout
            key={item.ID}
            renderDesktop={() => (
              <SaleBasketListItemD
                item={item}
                picture={picture}
                prod={prod}
                removeProd={removeProd}
              />
            )}
            renderMobile={() => (
              <SaleBasketListItemM
                item={item}
                picture={picture}
                prod={prod}
                removeProd={removeProd}
              />
            )}
          />
        );
      })}
    </div>
  );
};
export default SaleBasketList;
