import React, { useState, Fragment } from "react";
import { Form, Spinner, FormControl } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  YMaps,
  Map,
  ZoomControl,
  SearchControl,
  Placemark,
} from "react-yandex-maps";

import { useWindowDimensions } from "../../WindowDimensionsProvider";

export const DeliveryMap = ({ show, close, ymaps, saveAddress, api }) => {
  const { t } = useTranslation();
  const { isMobil } = useWindowDimensions();

  const [placeMarck, setPlaceMarck] = useState(null);
  const [mapCenter, setMapCenter] = useState([53.214422, 63.631976]);
  const [address, setAddress] = useState("");
  const [load, toggleLoad] = useState(false);

  const classWrap = show ? "delivery-map-wrap show" : "delivery-map-wrap hide";

  const mapClick = async (e) => {
    const coords = e.get("coords");
    const addr = await ymaps.getAddress(coords);
    setAddress(addr);
    setPlaceMarck(coords);
    setMapCenter(coords);
  };
  const searchClick = async (e) => {
    const coords = e.get("target").state._data.results[0].geometry._coordinates;
    const addr = await ymaps.getAddress(coords);
    setAddress(addr);
    setPlaceMarck(null);
    setMapCenter(coords);
  };
  const saveAddressHandler = async (fulladdress) => {
    toggleLoad(true);
    const deliveryInfo = await api.sale.getDeliveryPoint(address);
    close();
    saveAddress(deliveryInfo, fulladdress.replace("Казахстан, ", ""));
    toggleLoad(false);
    setPlaceMarck(null);
    setAddress("");
  };
  return (
    <div className={classWrap}>
      <div className="delivery-map-panel">
        <YMaps
          query={{
            lang: "ru_RU",
            apikey: "c5af4455-6b73-439c-ad0a-f2f40be2ff58",
          }}
        >
          <Map
            className="delivery-map"
            onClick={mapClick}
            instanceRef={(m) => ymaps.setMap(m)}
            state={{ center: mapCenter, zoom: 16 }}
          >
            <ZoomControl options={{ size: "large" }} />
            <SearchControl
              options={{
                size: "large",
                float: "none",
                boundedBy: [
                  [53.386622, 63.467021],
                  [52.95116, 63.784957],
                ],
                strictBounds: true,
                placeholderContent: t("SET_ADDRESS"),
                position: {
                  top: isMobil ? 15 : 10,
                  left: isMobil ? 10 : 80,
                },
              }}
              onResultShow={searchClick}
            />
            {placeMarck ? <Placemark geometry={placeMarck} /> : null}
            {address ? (
              <MapPanel
                address={address}
                cancel={() => {
                  setAddress("");
                }}
                load={load}
                save={saveAddressHandler}
              />
            ) : null}
          </Map>
        </YMaps>
        <span className="delivery-map-close" onClick={close}>
          &times;
        </span>
      </div>
    </div>
  );
};

const MapPanel = ({ address, cancel, save, load }) => {
  const { t } = useTranslation();

  const [flat, setFlat] = useState("");
  const [errFlat, setErrFlat] = useState("");
  const [floor, setFloor] = useState("");
  const [errFloor, setErrFloor] = useState("");
  const [poor, setPoor] = useState("");
  const [errPoor, setErrPoor] = useState("");
  const [isHouse, setIsHouse] = useState(false);

  const cancelHandler = () => {
    setFlat("");
    setFloor("");
    setPoor("");
    cancel();
  };
  const saveHandler = () => {
    let fullAddress = address;
    let valid = true;
    if (!isHouse) {
      if (flat) {
        fullAddress += t("FLAT_LABEL_SMALL") + flat;
      } else {
        valid = false;
        setErrFlat(t("ORDER_MESSAGES_FLAT"));
      }
      if (poor) {
        fullAddress += t("FLOOR_LABEL_SMALL") + poor;
      } else {
        valid = false;
        setErrPoor(t("ORDER_MESSAGES_ENTRANCE"));
      }
      if (floor) {
        fullAddress += t("POOR_LABEL_SMALL") + floor;
      } else {
        valid = false;
        setErrFloor(t("ORDER_MESSAGES_FLOOR"));
      }
    }
    if (valid) {
      save(fullAddress);
      cancelHandler();
    }
  };
  return (
    <div className="delivery-map-address-panel">
      <h3>{t("YOU_ADDRESS")}:</h3>
      <div className="text-center">
        <strong>{address}</strong>
      </div>
      <hr />
      <Form>
        {!isHouse ? (
          <Fragment>
            <Form.Group>
              <Form.Control
                type="text"
                value={flat}
                placeholder={t("FLAT_LABEL")}
                onChange={(e) => {
                  setErrFlat("");
                  setFlat(e.target.value);
                }}
                maxLength={4}
                disabled={load}
                isInvalid={errFlat}
              />
              <FormControl.Feedback type="invalid">
                {errFlat}
              </FormControl.Feedback>
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group className="w-40">
                <Form.Control
                  type="text"
                  value={floor}
                  placeholder={t("FLOOR_LABEL")}
                  onChange={(e) => {
                    setErrFloor("");
                    setFloor(e.target.value);
                  }}
                  maxLength={2}
                  disabled={load}
                  isInvalid={errFloor}
                />
                <FormControl.Feedback type="invalid">
                  {errFloor}
                </FormControl.Feedback>
              </Form.Group>
              <Form.Group className="w-40">
                <Form.Control
                  type="text"
                  value={poor}
                  placeholder={t("POOR_LABEL")}
                  onChange={(e) => {
                    setErrPoor("");
                    setPoor(e.target.value);
                  }}
                  maxLength={2}
                  disabled={load}
                  isInvalid={errPoor}
                />
                <FormControl.Feedback type="invalid">
                  {errPoor}
                </FormControl.Feedback>
              </Form.Group>
            </div>
          </Fragment>
        ) : null}

        <Form.Group>
          <Form.Check
            type="checkbox"
            checked={isHouse}
            id="is-house"
            onChange={() => setIsHouse(!isHouse)}
            label={t("ORDER_PRIVATE_HOUSE")}
            custom
          />
        </Form.Group>
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-sm btn-success w-40"
            onClick={saveHandler}
            disabled={load}
          >
            {load ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t("SAVE")
            )}
          </button>
          <button
            type="button"
            className="btn btn-sm btn-warning w-40"
            onClick={cancelHandler}
            disabled={load}
          >
            {t("CANCEL")}
          </button>
        </div>
      </Form>
    </div>
  );
};
