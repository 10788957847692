import React from "react";

import CatalogSearch from "../CatalogSearch";
import SaleMiniBasket from "../SaleMiniBasket";

import "./main-menu.css";
import CatalogMenu from "../CatalogMenu";
import { Row } from "react-bootstrap";

export const MainMenuD = () => {
  return (
    <Row className="d-flex justify-content-between align-items-center pt-1 pb-1">
      <CatalogMenu />
      <CatalogSearch />
      <SaleMiniBasket />
    </Row>
  );
};
