// import React, { Fragment } from "react";
// import { useWindowDimensions } from "./WindowDimensionsProvider";
export const ResponsiveLayout = ({
  breakpoint = 992,
  renderMobile,
  renderDesktop,
}) => {
  // const {width} = useWindowDimensions()
  // if(renderMobile && renderDesktop){
  //     return (width > breakpoint ? renderDesktop() : renderMobile())
  // }
  // return <Fragment />;
  return renderDesktop();
};
