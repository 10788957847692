import React, { Suspense } from "react";

import AppSpinner from "./AppSpinner";
import { AppNavigation } from "./AppNavigation";
import Header from "./Header/";
import Footer from "./Footer";
// import { SuspenseWithPerf } from 'reactfire';

export const AppMainLayout = () => {
  return (
    <Suspense fallback={<AppSpinner />}>
      <Header />
      <AppNavigation />
      <Footer />
    </Suspense>
  );
};
