import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { FaCalendarAlt, FaTimes, FaEye, FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const PersonalInfoFormM = ({ params }) => {
  const { t } = useTranslation();

  const [pass, showPass] = useState(false);
  return (
    <Form className="personal-info-form d-flex flex-column pt-3">
      <h3>{t("PERSONAL_DATA")}</h3>
      <Form.Group controlId="name">
        <Form.Label>{t("NAME_LABEL")}</Form.Label>
        <Form.Control
          type="text"
          value={params.fields.name.val}
          onChange={(e) => params.changeField("name", e.target.value)}
          onBlur={() => params.validField("name")}
          isInvalid={params.fields.name.err}
          disabled={params.load}
        />
        <Form.Control.Feedback type="invalid">
          {params.fields.name.err}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="surname">
        <Form.Label>{t("SURNAME_LABEL")}</Form.Label>
        <Form.Control
          type="text"
          value={params.fields.surname.val}
          onChange={(e) => params.changeField("surname", e.target.value)}
          onBlur={() => params.validField("surname")}
          isInvalid={params.fields.surname.err}
          disabled={params.load}
        />
        <Form.Control.Feedback type="invalid">
          {params.fields.surname.err}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t("SEX_LABEL")}</Form.Label>
        <div className="d-flex justify-content-between w-50">
          <Form.Check
            type="radio"
            id="sex-M"
            name="sex"
            className="cust-radio"
            checked={params.fields.sex.val === "M"}
            onChange={() => params.changeField("sex", "M")}
            disabled={params.load}
            label={t("MAN")}
            custom
          />
          <Form.Check
            type="radio"
            id="sex-F"
            name="sex"
            className="cust-radio"
            checked={params.fields.sex.val === "F"}
            onChange={() => params.changeField("sex", "F")}
            disabled={params.load}
            label={t("WOMAN")}
            custom
          />
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t("BIRTH_LABEL")}</Form.Label>
        <div>
          <DatePicker
            locale="ru-RU"
            value={params.fields.birth.val}
            onChange={(d) => params.changeField("birth", d)}
            format="dd.M.yyyy"
            className={
              params.fields.birth.err
                ? "form-control is-invalid"
                : "form-control"
            }
            calendarIcon={<FaCalendarAlt color="#ced4da" />}
            clearIcon={<FaTimes color="#ced4da" />}
            disabled={params.load}
          />
        </div>
        {params.fields.birth.err ? (
          <div className="invalid-feedback d-block">
            {params.fields.birth.err}
          </div>
        ) : null}
      </Form.Group>

      <h3>{t("CONTACTS_DATA")}</h3>
      <Form.Group controlId="phone">
        <Form.Label>{t("PHONE_LABEL")}</Form.Label>
        <Form.Control
          type="text"
          value={params.fields.phone.val}
          onChange={(e) => params.changeField("phone", e.target.value)}
          onBlur={() => params.validField("phone")}
          isInvalid={params.fields.phone.err}
          disabled={params.load}
        />
        <Form.Control.Feedback type="invalid">
          {params.fields.phone.err}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>{t("EMAIL_LABEL")}</Form.Label>
        <Form.Control
          type="text"
          value={params.fields.email.val}
          onChange={(e) => params.changeField("email", e.target.value)}
          onBlur={() => params.validField("email")}
          isInvalid={params.fields.email.err}
          disabled={params.load}
        />
        <Form.Control.Feedback type="invalid">
          {params.fields.email.err}
        </Form.Control.Feedback>
      </Form.Group>

      <h3>{t("PASS_CHANGE_LABEL")}</h3>
      <Form.Group controlId="oldPass" className="w-100 position-relative">
        <Form.Label>{t("OLD_PASS_LABEL")}</Form.Label>
        <Form.Control
          type={pass ? "text" : "password"}
          value={params.fields.oldPass.val}
          onChange={(e) => params.changeField("oldPass", e.target.value)}
          onBlur={() => params.validField("oldPass")}
          isInvalid={params.fields.oldPass.err}
          disabled={params.load}
        />
        {pass ? (
          <FaEyeSlash className="icon" onClick={() => showPass(false)} />
        ) : (
          <FaEye className="icon" onClick={() => showPass(true)} />
        )}
        <Form.Control.Feedback type="invalid">
          {params.fields.oldPass.err}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="newPass" className="w-100 position-relative">
        <Form.Label>{t("NEW_PASS_LABEL")}</Form.Label>
        <Form.Control
          type={pass ? "text" : "password"}
          value={params.fields.newPass.val}
          onChange={(e) => params.changeField("newPass", e.target.value)}
          onBlur={() => params.validField("newPass")}
          isInvalid={params.fields.newPass.err}
          disabled={params.load}
        />
        {pass ? (
          <FaEyeSlash className="icon" onClick={() => showPass(false)} />
        ) : (
          <FaEye className="icon" onClick={() => showPass(true)} />
        )}
        <Form.Control.Feedback type="invalid">
          {params.fields.newPass.err}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="rPass" className="w-100 position-relative">
        <Form.Label>{t("CHECK_NEW_PASS")}</Form.Label>
        <Form.Control
          type={pass ? "text" : "password"}
          value={params.fields.rPass.val}
          onChange={(e) => params.changeField("rPass", e.target.value)}
          onBlur={() => params.validField("rPass")}
          isInvalid={params.fields.rPass.err}
          disabled={params.load}
        />
        {pass ? (
          <FaEyeSlash className="icon" onClick={() => showPass(false)} />
        ) : (
          <FaEye className="icon" onClick={() => showPass(true)} />
        )}
        <Form.Control.Feedback type="invalid">
          {params.fields.rPass.err}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-center align-items-center personal-info-form-action">
        <button
          type="button"
          className="btn btn-success mr-2"
          onClick={params.saveFields}
          disabled={params.load}
        >
          {params.load ? <Spinner animation="border" size="sm" /> : t("SAVE")}
        </button>
        {params.status}
      </div>
    </Form>
  );
};
