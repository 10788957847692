import React from "react";
import { useSelector } from "react-redux";
import {
  FaUserCircle,
  FaAngleRight,
  FaShoppingCart,
  FaHeart,
  FaCompass,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PersonalLogoutButton } from "../PersonalLogoutButton";

import "./personal-mobil-view.css";

const PersonalMobilView = () => {
  const { t } = useTranslation();
  const { name, login } = useSelector((state) => state.user);

  return (
    <div className="pt-3">
      <Link
        to="/personal/profile/"
        className="d-flex align-items-center personal-info-block position-relative mb-3"
      >
        <FaUserCircle size="60" color="#8cc83c" className="mr-2" />
        <div className="d-flex flex-column">
          <strong>{name}</strong>
          <span>{"+" + login}</span>
          <span>{t("PERSONAL_DATA")}</span>
        </div>
        <FaAngleRight className="icon" />
      </Link>
      <Link
        to="/personal/orders/"
        className="d-flex align-items-center personal-info-block position-relative orders"
      >
        <FaShoppingCart color="#8cc83c" className="mr-2" />
        <span>{t("MY_ORDERS_LABEL")}</span>
        <FaAngleRight className="icon" />
      </Link>
      <Link
        to="/personal/address/"
        className="d-flex align-items-center personal-info-block position-relative mb-3 address"
      >
        <FaCompass color="#8cc83c" className="mr-2" />
        <span>{t("ADDRESSES_DELIVERY")}</span>
        <FaAngleRight className="icon" />
      </Link>
      <Link
        to="/personal/favorite/"
        className="d-flex align-items-center personal-info-block position-relative mb-3"
      >
        <FaHeart color="#8cc83c" className="mr-2" />
        <span>{t("FAVORITES_PROD")}</span>
        <FaAngleRight className="icon" />
      </Link>
      <div className="d-flex justify-content-between mb-3">
        {/* <Link
          to="/bonus/"
          className="d-flex align-items-center personal-info-block w-45 position-relative"
        >
          <FaCreditCard color="#8cc83c" className="mr-2" />
          <span>{t("BONUS_CARD")}</span>
          <FaAngleRight className="icon" />
        </Link> */}
      </div>
      <div className="personal-info-block position-relative">
        <PersonalLogoutButton />
      </div>
    </div>
  );
};
export default PersonalMobilView;
