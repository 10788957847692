import React from "react";
import { FaRegClock } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const AppTimePicker = ({ param, save, close }) => {
  const { t } = useTranslation();

  const setTime = (time) => {
    save(time);
    close();
  };
  if (!param.activeData.data || !param.times[param.activeData.data]) {
    return (
      <div className="app-time-picker">
        <h6>{t("CHECK_TIME_DELIVERY")}</h6>
      </div>
    );
  }
  const times = Object.keys(param.times[param.activeData.data]["VALUES"]).map(
    (key) => param.times[param.activeData.data]["VALUES"][key]
  );
  const butClass = (time) => {
    return param.activeData.time === time
      ? "app-time-picker-button active"
      : "app-time-picker-button";
  };
  return (
    <div className="app-time-picker">
      {times.map((item, id) => (
        <button
          key={"date" + id}
          className={butClass(item)}
          onClick={() => setTime(item)}
        >
          <FaRegClock className="mr-1 icon" /> {item}
        </button>
      ))}
    </div>
  );
};
