import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useBitrix } from "./BitrixProvider";

export const SaleBasketLink = () => {
  const { t } = useTranslation();
  const bitrix = useBitrix();
  const { total } = useSelector((state) => state.sale);

  const [minSum, setMinSum] = useState(0);

  useEffect(() => {
    const getBasketConfig = async () => {
      const response = await bitrix.sale.getBasketConfig();
      if (response.minSum) {
        setMinSum(response.minSum);
      }
    };
    getBasketConfig();
    // eslint-disable-next-line
  }, []);

  if (total > minSum) {
    return (
      <div className="mt-3">
        <Link to="/order/" className="btn btn-block btn-success">
          {t("ORDER_PLACE", { sum: total })}
        </Link>
      </div>
    );
  }
  return <Fragment />;
};
