import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { useBitrix } from "../BitrixProvider";

import OrderContacts from "./OrderContacts";
import OrderDelivery from "./OrderDelivery";
import OrderPayment from "./OrderPayment";
import OrderTime from "./OrderTime";
import OrderComment from "./OrderComment";
import OrderPromo from "./OrderPromo";

export const SaleOrderM = ({ err, save, sum }) => {
  const { t } = useTranslation();
  const bitrix = useBitrix();

  const [minSum, setMinSum] = useState(0);

  useEffect(() => {
    const getBasketConfig = async () => {
      const response = await bitrix.sale.getBasketConfig();
      if (response.minSum) {
        setMinSum(response.minSum);
      }
    };
    getBasketConfig();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="sale-order-wrap">
      <div
        className={
          err.includes("contacts")
            ? "sale-order-panel invalid"
            : "sale-order-panel"
        }
      >
        <OrderContacts />
      </div>
      <div
        className={
          err.includes("delivery")
            ? "sale-order-panel invalid"
            : "sale-order-panel"
        }
      >
        <OrderDelivery />
      </div>
      <div
        className={
          err.includes("dataTime")
            ? "sale-order-panel invalid"
            : "sale-order-panel"
        }
      >
        <OrderTime />
      </div>
      <div
        className={
          err.includes("payment")
            ? "sale-order-panel invalid"
            : "sale-order-panel"
        }
      >
        <OrderPayment />
      </div>
      <div
        className={
          err.includes("bonus")
            ? "sale-order-panel invalid"
            : "sale-order-panel"
        }
      >
        {/* <OrderBonus /> */}
      </div>
      <div
        className={
          err.includes("promo")
            ? "sale-order-panel invalid"
            : "sale-order-panel"
        }
      >
        <OrderPromo />
      </div>
      <div className="sale-order-panel">
        <OrderComment />
      </div>
      <div className="sale-order-panel d-flex flex-column pt-1 pb-3">
        <div className="sale-order-total">
          <div className="d-flex justify-content-between">
            <strong>{t("ORDER_BASKET_SUM")}</strong>
            <span>
              {sum.basket}
              {t("TENGE")}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <strong>{t("PRICE_DELIVERY_LABEL")}</strong>
            <span>
              {sum.delivery}
              {t("TENGE")}
            </span>
          </div>
          {sum.bonus && sum.bonus > 0 ? (
            <div className="d-flex justify-content-between">
              <strong>{t("ORDER_PAY_BONUSES")}</strong>
              <span>
                {sum.bonus}
                {t("TENGE")}
              </span>
            </div>
          ) : null}
          <div className="d-flex justify-content-between">
            <strong>{t("ORDER_SUM")}</strong>
            <span>
              {sum.total}
              {t("TENGE")}
            </span>
          </div>
        </div>
        <div className="sale-order-button mt-2">
          <a
            href="https://cc.kz/upload/info/Privacy_Policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans i18nKey="OFERTA_SENTENCE">
              <span className="text-oferta"></span>
              <span className="text-oferta link-text"></span>
            </Trans>
          </a>
          {sum.basket < minSum ? (
            <button
              className="btn btn-sm btn-block btn-secondary mt-3"
              disabled={true}
            >
              {t("MIN_SUM_ORDER") + minSum + t("TENGE")}
            </button>
          ) : (
            <button
              className="btn btn-sm btn-block btn-success mt-3"
              onClick={save}
            >
              {t("ORDER_SAVE")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
