import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import noImg from "./error1.png";

export const RuPage = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center align-items-center no-page">
      <div className="d-flex flex-column align-items-center">
        <Image src={noImg} />
        <h1 className="mt-3">{t("NOTFOUNDPAGE_TITLE")}</h1>
        <span className="text-center">{t("NOTFOUNDPAGE_DESCRIPTION")}</span>
        <Link to="/discounts/" className="btn btn-success mt-4">
          {t("GO_CATALOG")}
        </Link>
      </div>
    </div>
  );
};
