import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import SaleButton from "../SaleButton";
import { FavoriteButton } from "../PersonalFavorite/FavoriteButton";

export const SaleBasketListItemD = ({ item, removeProd, picture, prod }) => {
  const { t, i18n } = useTranslation();

  const units = {
    шт: t("UNITS_PIECES"),
    "л.": t("UNITS_LITERS"),
    г: t("UNITS_GRAMMS"),
    кг: t("UNITS_KILOGRAMMS"),
  };

  return (
    <div className="sale-basket-list-item position-relative">
      <FavoriteButton prodId={item.PRODUCT_ID} />
      <Link to={item.DETAIL_PAGE_URL} className="sale-basket-list-item-img">
        <Image src={picture} fluid />
      </Link>
      <div className="d-flex flex-column pl-3 pt-3 sale-basket-list-item-title">
        <Link to={item.DETAIL_PAGE_URL}>
          <h3>{item.LANG[i18n.resolvedLanguage]}</h3>
        </Link>
        <div className="sale-basket-price">
          {parseInt(item.PRICE)} {t("TENGE")}/
          {units[prod.PROPERTY_CML2_BASE_UNIT_VALUE]}
        </div>
      </div>
      <div className="sale-basket-list-item-price d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-center">
          <SaleButton prod={prod} />
          <strong className="mt-1">
            {Math.round(item.QUANTITY * parseInt(item.PRICE))} {t("TENGE")}
          </strong>
        </div>
      </div>
      <div className="sale-basket-list-item-delbutton d-flex justify-content-center align-items-center">
        <button
          title={t("DEL_PROD")}
          onClick={() => removeProd(item.PRODUCT_ID)}
        >
          <FaTrashAlt />
        </button>
      </div>
    </div>
  );
};
