const saleActions = {
  updateBasket: (basket) => {
    return {
      type: "UPD_BASKET",
      payload: basket,
    };
  },
  salePanelOpen: (prodId) => {
    return {
      type: "SALE_PANEL_OPEN",
      payload: prodId,
    };
  },
  salePanelClose: () => {
    return {
      type: "SALE_PANEL_CLOSE",
      payload: false,
    };
  },
  orderSetPersonal: (personal) => {
    return {
      type: "ORDER_PERSONAL",
      payload: personal,
    };
  },
  orderSetDelivery: (delivery) => {
    return {
      type: "ORDER_DELIVERY",
      payload: delivery,
    };
  },
  orderSetTime: (time) => {
    return {
      type: "ORDER_TIME",
      payload: time,
    };
  },
  setBlockedTime: (blockedTime) => {
    return {
      type: "ORDER_SETTINGS_TIME",
      payload: blockedTime,
    };
  },
  orderSetPayment: (payment) => {
    return {
      type: "ORDER_PAYMENT",
      payload: payment,
    };
  },
  orderSetComment: (comment) => {
    return {
      type: "ORDER_COMMENT",
      payload: comment,
    };
  },
  orderReset: () => {
    return {
      type: "ORDER_RESET",
      payload: true,
    };
  },
  orderSetPromo: (promo) => {
    return {
      type: "ORDER_PROMO",
      payload: promo,
    };
  },
  setKaspiNumber: (number) => {
    return {
      type: "SET_KASPI_NUMBER",
      payload: number,
    };
  },
  setDeliveryPrice: (price) => {
    return {
      type: "SET_DELIVERY_PRICE",
      payload: price,
    };
  },
  setOrderIdForEdit: (id) => {
    return {
      type: "SET_ORDER_EDIT",
      payload: id,
    };
  },
  orderDeliveryReset: () => {
    return {
      type: "ORDER_DELIVERY_RESET",
      payload: true,
    };
  },
  orderSetBonus: (bonus) => {
    return {
      type: "ORDER_SET_BONUS",
      payload: bonus,
    };
  },
};
export default saleActions;
