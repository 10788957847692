import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { MdCheckCircle, MdHighlightOff } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../components/BitrixProvider";

import AppSpinner from "../components/AppSpinner";
import ErrorIndicator from "../components/ErrorIndicator";

export const PaymentPage = () => {
  const bitrix = useBitrix();
  const { t } = useTranslation();
  const { id } = useSelector((state) => state.user);
  const { pathname } = useLocation();

  const [payment, setPayment] = useState(null);
  const [err, setErr] = useState("");
  const [load, setLoad] = useState(false);

  const getData = async (id) => {
    setLoad(true);
    try {
      const payInfo = await bitrix.sale.getPaymentInfo(id);
      setLoad(false);
      if (payInfo.CODE) {
        setPayment(payInfo);
      }
      if (payInfo.error) {
        setErr(payInfo);
      }
    } catch (error) {
      setLoad(false);
      setErr(t("SERVICE_NOT_FOUND"));
    }
  };

  useEffect(() => {
    const keys = pathname.slice(1, -1).split("/");
    if (keys[1]) getData(parseInt(keys[1]));
    // eslint-disable-next-line
  }, [pathname]);
  if (err) {
    return (
      <Container>
        <Row>
          <div
            style={{
              height: "55vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ErrorIndicator text={err} title={t("ERROR_LABEL")} />
          </div>
        </Row>
      </Container>
    );
  }
  if (load) {
    return (
      <Container>
        <Row>
          <Col>
            <div
              style={{
                height: "55vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AppSpinner />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (payment) {
    if (payment.CODE === "CANCELED") {
      return (
        <Container>
          <Row>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                paddingTop: "10vh",
                paddingBottom: "15vh",
              }}
            >
              <MdHighlightOff color="red" size="7rem" />
              <span style={{ fontSize: "3rem", fontWeight: "bold" }}>
                {t("PAYMENT_CANCELED")}
              </span>
              <strong>
                {t("ORDER_FOR_AMOUNT", {
                  number: payment.ORDER,
                  sum: payment.SUM,
                })}
              </strong>
              <p
                style={{ width: "40%", textAlign: "center", marginTop: "2rem" }}
              >
                {payment.MSG}
              </p>
              <p>{payment.DATE}</p>
              {id ? (
                <Link to="/personal/" className="btn btn-success mt-4">
                  {t("GO_MY_ORDERS_PAGE")}
                </Link>
              ) : (
                <Link to="/" className="btn btn-success mt-4">
                  {t("GO_HOME_PAGE")}
                </Link>
              )}
            </div>
          </Row>
        </Container>
      );
    }
    if (payment.CODE === "DECLINED") {
      return (
        <Container>
          <Row>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                paddingTop: "10vh",
                paddingBottom: "15vh",
              }}
            >
              <MdHighlightOff color="red" size="7rem" />
              <span style={{ fontSize: "3rem", fontWeight: "bold" }}>
                {t("PAYMENT_REJECTED")}
              </span>
              <strong>
                {t("ORDER_FOR_AMOUNT", {
                  number: payment.ORDER,
                  sum: payment.SUM,
                })}
              </strong>
              <p
                style={{ width: "40%", textAlign: "center", marginTop: "2rem" }}
              >
                {payment.MSG}
              </p>
              <p>{payment.DATE}</p>
              {id ? (
                <Link to="/personal/" className="btn btn-success mt-4">
                  {t("GO_MY_ORDERS_PAGE")}
                </Link>
              ) : (
                <Link to="/" className="btn btn-success mt-4">
                  {t("GO_HOME_PAGE")}
                </Link>
              )}
            </div>
          </Row>
        </Container>
      );
    }
    if (payment.CODE === "APPROVED") {
      return (
        <Container>
          <Row>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                paddingTop: "10vh",
                paddingBottom: "15vh",
              }}
            >
              <MdCheckCircle color="#00994d" size="7rem" />
              <span style={{ fontSize: "3rem", fontWeight: "bold" }}>
                {t("PAYMENT_APROVED")}
              </span>
              <strong>
                {t("ORDER_FOR_AMOUNT", {
                  number: payment.ORDER,
                  sum: payment.SUM,
                })}
              </strong>
              <p
                style={{ width: "40%", textAlign: "center", marginTop: "2rem" }}
              >
                {payment.MSG}
              </p>
              <p>{payment.DATE}</p>
              {id ? (
                <Link to="/personal/" className="btn btn-success mt-4">
                  {t("GO_MY_ORDERS_PAGE")}
                </Link>
              ) : (
                <Link to="/" className="btn btn-success mt-4">
                  {t("GO_HOME_PAGE")}
                </Link>
              )}
            </div>
          </Row>
        </Container>
      );
    }
  }
  return <Fragment />;
};
