const actCreator = (id)=>{
    return{
        req:()=>{
            return{
                type:id+'_REQ',
                payload:true
            }
        },
        load:(data)=>{
            return{
                type:id+'_LOAD',
                payload:data
            }
        },
        err:(err)=>{
            return{
                type:id+'_ERR',
                payload:err
            }
        }
    }
}
const searchInputChange = (text)=>{
    return {
        type:'SEARCH_INPUT_CHANGE',
        payload: text
    }
}
const searchListChange = (list)=>{
    return {
        type: 'SEARCH_LIST_CHANGE',
        payload:list
    }
}
const loadView = (view)=>{
    return{
        type:'MAIN_LOAD_VIEW',
        payload:view
    }
}
const settigs={
    sectChange:(sect)=>{
        return{
            type:'MAIN_SETTING_SECT_CHANGE', 
            payload:sect
        }
    },
    changePage:(page)=>{
        return{
            type:'MAIN_SETTINGS_PAGE_CHANGE',
            payload:page
        }
    },
    setPagesCount:(pagesCount)=>{
        return{
            type:'MAIN_SETTINGS_SET_PAGES',
            payload:pagesCount
        }
    },
    setSort:(sort)=>{
        return{
            type:'MAIN_SETTING_SORT_CAHNGE',
            payload:sort
        }
    },
    setFilter:(filter)=>{               
        return{
            type:'MAIN_SETTING_FILTER_CAHNGE',
            payload:filter
        }
    },
    changeType:(type)=>{               
        return{
            type:'MAIN_SETTING_TYPE_CAHNGE',
            payload:type
        }
    },
    setSeach:(search)=>{               
        return{
            type:'MAIN_SETTING_SEARCH_CAHNGE',
            payload:search
        }
    },
    setFilterDef:(filter)=>{               
        return{
            type:'MAIN_SETTINGS_SET_FILTERDEF',
            payload:filter
        }
    },
    setBrands:(brand)=>{
        return {
            type:'MAIN_SETTINGS_SET_BRAND',
            payload:brand
        }
    }
}
const setName = (name)=>{
    return{
        type:'MAIN_NAME',
        payload:name
    }
}
const setFilters = (filters)=>{
    return{
        type:'MAIN_FILTERS',
        payload:filters
    }
}
const setElem = (elem)=>{
    return{
        type:'MAIN_LOADELEM',
        payload:elem
    }
}
const resetMain = ()=>{
    return{
        type:'MAIN_RESET',
        payload:true
    }
}

const catalogActions ={
    main:{...actCreator('MAIN'),loadView,settigs,setName,setFilters,setElem,resetMain},
    categories:actCreator('CATEGORIES'),
    search:{
        searchInputChange,
        searchListChange       
    }

}



export default catalogActions