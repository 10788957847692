import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ResponsiveLayout } from "../ResponsiveLayout";
import SaleButton from "../SaleButton";

import noImg from "./no-img1.jpg";
import "./catalog-section-item.css";

const CatalogSectionItem = ({ prods }) => (
  <ResponsiveLayout
    renderDesktop={() => <CatalogSectionItemD prod={prods} />}
    renderMobile={() => <CatalogSectionItemM prod={prods} />}
  />
);
export default CatalogSectionItem;

const CatalogSectionItemD = ({ prod }) => {
  const { t, i18n } = useTranslation();

  const picture = prod.PREVIEW_PICTURE
    ? "https://" + prod.PREVIEW_PICTURE
    : noImg;
  const discount =
    parseInt(prod.OLD_PRICE) > 0
      ? Math.round(
          ((parseInt(prod.OLD_PRICE) - parseInt(prod.PRICE)) /
            parseInt(prod.OLD_PRICE)) *
            100
        )
      : 0;

  const units = {
    шт: t("UNITS_PIECES"),
    "л.": t("UNITS_LITERS"),
    г: t("UNITS_GRAMMS"),
    кг: t("UNITS_KILOGRAMMS"),
  };

  return (
    <div className="catalog-section-item">
      <Link to={prod.DETAIL_PAGE_URL}>
        <Image src={picture} fluid />
      </Link>

      <div className="position-relative">
        {prod.PROPERTY_AVERAGE_WEIGHT_VALUE ? (
          <div className="catalog-section-item-averege-weight">
            <span>
              {t("AVERAGE_WEIGHT", {
                weight: prod.PROPERTY_AVERAGE_WEIGHT_VALUE,
              })}
            </span>
          </div>
        ) : null}
        <div className="catalog-section-item-sale-button">
          <SaleButton prod={prod} />
        </div>
      </div>
      <div className="prices d-flex align-items-center">
        {parseInt(prod.OLD_PRICE) ? (
          <div className="d-flex flex-column old-price-wrap">
            <div className="d-flex align-items-center">
              <span className="catalog-section-item-old-price">
                {parseInt(prod.OLD_PRICE) + " " + t("TENGE")}
              </span>
              <span className="catalog-section-item-old-price-label">
                -{discount}%
              </span>
            </div>
            <div className="catalog-section-item-old-price-pricewrap">
              <span className="catalog-section-item-price">
                {parseInt(prod.PRICE) +
                  " " +
                  t("TENGE") +
                  "/" +
                  units[prod.PROPERTY_CML2_BASE_UNIT_VALUE]}
              </span>
            </div>
          </div>
        ) : (
          <Fragment>
            <span className="catalog-section-item-price">
              {parseInt(prod.PRICE) +
                " " +
                t("TENGE") +
                "/" +
                units[prod.PROPERTY_CML2_BASE_UNIT_VALUE]}
            </span>
          </Fragment>
        )}
      </div>
      <Link to={prod.DETAIL_PAGE_URL}>
        <span className="catalog-section-item-name">
          {prod.LANG[i18n.resolvedLanguage]}
        </span>
      </Link>
    </div>
  );
};

const CatalogSectionItemM = ({ prod }) => {
  const { t, i18n } = useTranslation();
  const picture = prod.PREVIEW_PICTURE
    ? "https://" + prod.PREVIEW_PICTURE
    : noImg;
  const discount =
    parseInt(prod.OLD_PRICE) > 0
      ? Math.round(
          ((parseInt(prod.OLD_PRICE) - parseInt(prod.PRICE)) /
            parseInt(prod.OLD_PRICE)) *
            100
        )
      : 0;

  const units = {
    шт: t("UNITS_PIECES"),
    "л.": t("UNITS_LITERS"),
    г: t("UNITS_GRAMMS"),
    кг: t("UNITS_KILOGRAMMS"),
  };

  return (
    <div className="catalog-section-item catalog-section-mobil-item">
      <Link to={prod.DETAIL_PAGE_URL}>
        <Image src={picture} fluid />
      </Link>

      <div className="position-relative">
        {prod.PROPERTY_AVERAGE_WEIGHT_VALUE ? (
          <div className="catalog-section-item-averege-weight">
            <span>
              {t("AVERAGE_WEIGHT", {
                weight: prod.PROPERTY_AVERAGE_WEIGHT_VALUE,
              })}
            </span>
          </div>
        ) : null}
      </div>
      <Link to={prod.DETAIL_PAGE_URL}>
        <span className="catalog-section-item-name">
          {prod.LANG[i18n.resolvedLanguage]}
        </span>
      </Link>
      <div className="prices-mobil d-flex align-items-center">
        {parseInt(prod.OLD_PRICE) ? (
          <div className="d-flex flex-column old-price-wrap">
            <div className="catalog-section-item-old-price-pricewrap">
              <span className="catalog-section-item-price">
                {parseInt(prod.PRICE) +
                  " " +
                  t("TENGE") +
                  "/" +
                  units[prod.PROPERTY_CML2_BASE_UNIT_VALUE]}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <span className="catalog-section-item-old-price">
                {parseInt(prod.OLD_PRICE) + " " + t("TENGE")}
              </span>
              <span className="catalog-section-item-old-price-label">
                -{discount}%
              </span>
            </div>
          </div>
        ) : (
          <Fragment>
            <span className="catalog-section-item-price">
              {parseInt(prod.PRICE) +
                " " +
                t("TENGE") +
                "/" +
                units[prod.PROPERTY_CML2_BASE_UNIT_VALUE]}
            </span>
          </Fragment>
        )}
      </div>
      <div className="d-flex justify-content-end mt-2">
        <SaleButton prod={prod} />
      </div>
    </div>
  );
};
