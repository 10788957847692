import Basket from "./basket";
import Bonus from "./bonus";
import Catalog from "./catalog";
import CCApiBase from "./ccApiBase";
import Content from "./content";
import Order from "./order";
import User from "./user";
export default class CCApiService extends CCApiBase {
  catalogBase = new Catalog();
  contentBase = new Content();
  userBase = new User();
  bonusBase = new Bonus();
  basketBase = new Basket();
  orderBase = new Order();

  catalog = {
    sectTree: () => this.getData(this.catalogBase.getSectionsList()),
    getCatalogList: (param) => this.getData(this.catalogBase.getProds(param)),
    searchTitleProd: (query) =>
      this.getData(this.catalogBase.searchProdsByTitle(query)),
    prodById: (code) => this.getData(this.catalogBase.getProdDetail(code)),
    getBlurbSearch: (search) =>
      this.getData(this.catalogBase.getBlurbProds({ query: search })),
    getBlurbMoreCategory: (category, limit) =>
      this.getData(this.catalogBase.getBlurbProds({ category, limit })),
  };

  content = {
    getSlider: () => this.getData(this.contentBase.getSlidersImg()),
    getCategoryBanner: () => this.getData(this.contentBase.getCategoryBanner()),
    getBrands: () => this.getData(this.contentBase.getBlurbContent()),
    getBlurbCategory: (category) =>
      this.getData(this.contentBase.getBlurbContent({ category })),
    validCaptcha: (token) => this.getData(this.contentBase.validCaptcha(token)),
  };

  users = {
    isAuth: () => this.getData(this.userBase.getUserProfile()),
    auth: (params) => this.getData(this.userBase.auth(params)),
    user: () => this.getData(this.userBase.getUserProfile()),
    updateUser: (fields) => this.getData(this.userBase.updateUser(fields)),
    logout: () => this.getData(this.userBase.logout()),
    isUserBaned: (phone) => this.getData(this.userBase.isPhoneBaned(phone)),
    validPhone: (data) => this.getData(this.userBase.validPhone(data)),
  };

  bonus = {
    bonusGetCard: () => this.getData(this.bonusBase.getCard()),
    bonusDelCard: () => this.getData(this.bonusBase.bonusDelCard()),
    bonusStartPhoneCheck: (phone, name, code) =>
      this.getData(this.bonusBase.bonusPhone(phone, name, code)),
    bonusEndPhoneCheck: (param) =>
      this.getData(this.bonusBase.bonusPhoneCheck(param)),
  };

  sale = {
    basket: async (order = null, fields = "") => {
      let res = await this.getData(
        this.basketBase.getBasket({ order, fields })
      );
      if (res.error) {
        res = await this.getData(this.basketBase.getUserForBasket());
        if (res.auth_key) {
          return await this.getData(
            this.basketBase.getBasket({ order, fields })
          );
        }
      }
      return res;
    },
    addProdToBasket: (prod, qnt = 1, order = null) =>
      this.getData(
        this.basketBase.addToBasket({ prod, qnt, order, fields: "url" })
      ),
    delProdFromBasket: (prod, order = null) =>
      this.getData(this.basketBase.delFromBasket({ prod, order })),
    getMyOrders: () => this.getData(this.orderBase.getOrders()),
    order: (id) => this.getData(this.orderBase.getOrder(id)),
    cancelOrder: (id) => this.getData(this.orderBase.cancelOrder(id)),
    sendRating: (rating, order) =>
      this.getData(
        this.orderBase.changeOrderProps(order, { ORDER_RATING: rating })
      ),
    sendFeedback: (feedback, order) =>
      this.getData(
        this.orderBase.changeOrderProps(order, { ORDER_FEEDBACK: feedback })
      ),
    setOnlinePaid: (id) => this.getData(this.orderBase.setOnlinePaid(id)),
    repeatOrder: (id) => this.getData(this.orderBase.repeatOrder(id)),
    setEditOrderId: (id) => this.getData(this.orderBase.editOrder(id)),
    saveEditOrder: (id, dataTime) =>
      this.getData(this.orderBase.editOrder(id, { DATA_AND_TIME: dataTime })),
    getEditOrder: () => this.getData(this.orderBase.getEditableOrder()),
    getDeliveryTime: (point, delivery, order = null) =>
      this.getData(this.orderBase.getDeliveryTime({ point, delivery, order })),
    getDeliveryPoint: (address) =>
      this.getData(this.orderBase.getDeliveryPoint({ address })),
    getPriceDelivery: (dist, point) =>
      this.getData(this.orderBase.getDeliveryPrice({ dist, point })),
    addOrder: (param) => this.getData(this.orderBase.orderCreate(param)),
    isValidPromo: (code) => this.getData(this.orderBase.getPromoInfo(code)),
    getPaymentInfo: (id) => this.getData(this.orderBase.getPaymentInfo(id)),
    getActiveOrder: () => this.getData(this.orderBase.getActiveOrder()),
    getPaymentIds: (deliveryId) =>
      this.getData(this.orderBase.getPaymentIds(deliveryId)),
    getPaymentList: (deliveryId) =>
      this.getData(this.orderBase.getPaymentList(deliveryId)),
    checkPhoneInKaspi: (phone) =>
      this.getData(this.orderBase.checkPhoneInKaspi(phone)),
    checkPaymentStatus: (order) =>
      this.getData(this.orderBase.checkPaymentStatus(order)),
    getBasketConfig: () => this.getData(this.basketBase.getBasketConfig()),
  };
}
