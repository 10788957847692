import React from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import cart from "./cart.svg";

export const SaleMiniBasketD = ({ sum, count }) => {
  const { t } = useTranslation();

  if (count === 0) {
    return (
      <Link
        to="/basket/"
        className="d-flex align-items-center justify-content-center"
      >
        <Image src={cart} height={32} className="mr-1" />
      </Link>
    );
  }
  return (
    <Col>
      <Link
        to="/basket/"
        className="d-flex align-items-center justify-content-center sale-mini-basket-link"
      >
        <Image src={cart} height={32} className="mr-2" />
        <div className="d-flex flex-column">
          <span className="sale-mini-basket-text">
            {count}{" "}
            {plural(count, [
              t("PRODS_LABEL_FORM1"),
              t("PRODS_LABEL_FORM2"),
              t("PRODS_LABEL_FORM3"),
            ])}
          </span>
          <span className="sale-mini-basket-text">
            {sum + " " + t("TENGE")}
          </span>
        </div>
      </Link>
    </Col>
  );
};
const plural = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};
