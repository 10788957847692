const systemActions = {
    updatePath:(path)=>{
        return {
            type:'UPD_PATH',
            payload:path
        }
    },
    updateTitle:(title)=>{
        return{
            type:'UPD_TITLE',
            payload:title
        }
    },
    showToast:(msg)=>{
        return{
            type:'TOAST_SHOW',
            payload:msg
        }
    },
    hideToast:()=>{
        return{
            type:'TOAST_HIDE',
            payload:''
        }
    },
    setYmaps:(ymaps)=>{
        return{
            type:'SET_YMAPS',
            payload:ymaps
        }
    },
    setSlider:(slider)=>{
        return{
            type:'SET_SLIDER',
            payload:slider
        }
    }
}
export default systemActions