import React, { useState } from "react";
import { FaCreditCard, FaCheckCircle } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";

export const PersonalOrdersPay = ({ order }) => {
  const { t } = useTranslation();
  const bitrix = useBitrix();

  const [load, setLoad] = useState(false);
  const [paid, setPaid] = useState(false);

  const setPayment = async () => {
    setLoad(true);
    try {
      const response = await bitrix.sale.checkPaymentStatus(order.ID);
      if (response["LAST_PAYMENT_STATUS"] === "APPROVED") {
        setPaid(true);
        setLoad(false);
      } else {
        bitrix.sale
          .setOnlinePaid(order.ID)
          .then((data) => {
            setLoad(false);
            if (data.status && data.status === "success") {
              window.location.href = data.HREF;
            } else {
              alert(t("SERVICE_NOT_FOUND"));
            }
          })
          .catch((e) => {
            setLoad(false);
            alert(t("SERVICE_NOT_FOUND"));
          });
      }
    } catch (error) {}
  };
  if (parseInt(order.SUM_PAID) > 0 || paid) {
    return <PaidButton />;
  }
  return (
    <button className="btn order-button" onClick={setPayment} disabled={load}>
      {load ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <FaCreditCard color="#8CC83C" className="mr-1" />
      )}{" "}
      <span>{t("PAY_LABEL")}</span>
    </button>
  );
};

const PaidButton = () => {
  const { t } = useTranslation();
  return (
    <span className="btn order-button bg-success text-white">
      <FaCheckCircle color="white" className="mr-1" />{" "}
      <span>{t("ORDER_HAVE_PAY")}</span>
    </span>
  );
};
