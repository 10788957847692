import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CatalogCategoriesD = ({ list, active, base }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="catalog-categories d-flex flex-column">
      <div className="categories-title-wrap">
        <h6>{t("CATEGORIES")}</h6>
      </div>
      <div className="categories-list d-flex flex-column bg-white">
        {Object.keys(list).map((key) => {
          const linkUrl = list[key]["SECTION_PAGE_URL"].replace(
            /^\/[a-z]*/g,
            "/" + base
          );
          return (
            <Link
              to={linkUrl}
              className={
                key === active
                  ? "categories-list-item active"
                  : "categories-list-item"
              }
              key={key}
            >
              {list[key].LANG[i18n.resolvedLanguage]}
            </Link>
          );
        })}
      </div>
    </div>
  );
};
