import React, { useState } from "react";
import { FaUserCircle, FaTools, FaInfoCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import PersonalInfoForm from "./PersonalInfoForm";
import { PersonalLogoutButton } from "../PersonalLogoutButton";
// import BonusCardMini from "../BonusCardMini";

export const PersonalInfoD = ({ user, setUser }) => {
  // const {t} = useTranslation()
  const [form, showForm] = useState(false);
  return (
    <div className="d-flex flex-column bg-white p-3">
      <div className="personal-info-title  d-flex align-items-center position-relative">
        <div className="d-flex align-items-center mr-5 w-30">
          <FaUserCircle size="100" color="#8cc83c" className="mr-2" />
          <div className="d-flex flex-column">
            <strong>{user.NAME}</strong>
            <div className="d-flex align-items-center">
              <span className="mr-2">+{user.LOGIN}</span>
              <div className="position-relative d-flex align-items-center">
                <FaInfoCircle className="info-trigger" />
                <Info />
              </div>
            </div>
            <span>{user.EMAIL}</span>
          </div>
        </div>
        {/* <div className="d-flex align-items-center">
          <BonusCardMini />
        </div> */}
        {/* <div className="d-flex align-items-center">
          <Image src={bcard} className="mr-3" height={100} />
          <div className="d-flex flex-column">
            <span>{t('BAR_CODE_LABEL')}:</span>
            {user.BONUSCARD?<strong>{user.BONUSCARD}</strong>:<span className="text-danger link-block" onClick={() => showForm(true)}>{t('SET_BONUS_CARD')}</span>}
            
          </div>
        </div> */}
        <div className="personal-info-logout">
          <PersonalLogoutButton />
        </div>
        <FaTools className="icon" onClick={() => showForm(!form)} />
      </div>
      {form ? <PersonalInfoForm user={user} setUser={setUser} /> : null}
    </div>
  );
};
const Info = () => {
  const { t } = useTranslation();

  return (
    <div className="info-wrap">
      <div className="info-title">
        <div className="arrow"></div>
        <h3>{t("ATTENCHION")}</h3>
      </div>
      <div className="info-body">{t("LOGIN_WARNING")}</div>
    </div>
  );
};
