import React, { useState, useEffect, Fragment } from "react";
import {
  FaArrowAltCircleRight,
  FaArrowRight,
  FaArrowAltCircleLeft,
} from "react-icons/fa";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";

import { ResponsiveLayout } from "../ResponsiveLayout";
import CatalogSectionItem from "../CatalogSectionItem";

import "./catalog-slider.css";

const CatalogSlider = ({ type, data, header = null }) => {
  const bitrix = useBitrix();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [prods, setProds] = useState([]);
  const settings = getSettings(type, data, pathname);
  const label = getLabel(type, t);
  const link = getLink(type, pathname);

  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      bitrix.catalog.getCatalogList(settings).then((res) => {
        if (res.ARRAY && Array.isArray(res.ARRAY) && res.ARRAY.length > 0) {
          setProds(res.ARRAY);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      bitrix.catalog.getCatalogList(settings).then((res) => {
        if (res.ARRAY && Array.isArray(res.ARRAY) && res.ARRAY.length > 0) {
          setProds(res.ARRAY);
        }
      });
    } else {
      setProds([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (prods.length > 0) {
    return (
      <ResponsiveLayout
        renderDesktop={() => (
          <CatalogSliderView
            prods={prods}
            view="desc"
            name={label}
            link={link}
            header={header}
          />
        )}
        renderMobile={() => (
          <CatalogSliderView
            prods={prods}
            view="mobil"
            name={label}
            link={link}
            header={header}
          />
        )}
      />
    );
  }
  return <Fragment />;
};
const getLink = (type, path) => {
  switch (type) {
    case "categories":
      const link = path.replace(/[0-9a-zA-Z_]*\/$/, "");
      return link;
    case "banner":
      return "/banners/";
    case "newspaper":
      return "/newspaper/";
    case "discounts":
      return "/discounts/";
    case "favorites":
      return "/personal/favorite/";
    default:
      return "";
  }
};
const getLabel = (type, t) => {
  switch (type) {
    case "categories":
      return t("SEE_MORE_CATALOG");
    case "banner":
      return t("PROD_MONTH");
    case "newspaper":
      return t("SUN_NEWSPAPER");
    case "discounts":
      return t("DISCOUNTS");
    case "favorites":
      return "";
    default:
      return "";
  }
};
export const CatalogSliderView = ({ prods, view, name, link = "", header }) => {
  const { t } = useTranslation();

  const id = "slider-" + Math.floor(0 + Math.random() * (100 + 1 - 0));
  const classWrap =
    view === "desc"
      ? "catalog-slider-desc mt-3 mb-2"
      : "catalog-slider-mobil mt-3 pb-3";

  const scrollButtons = (type = "right") => {
    const scr = document.getElementById(id);
    let counter = 0;
    const ti = setInterval(() => {
      if (type === "right") {
        scr.scrollLeft += 50;
      } else {
        scr.scrollLeft -= 50;
      }
      counter += 50;
      if (counter > 750) clearInterval(ti);
    }, 15);
  };
  useEffect(() => {
    if (view === "desc") {
      let scr = document.getElementById(id);
      let isScroll = false;
      let startX = 0;
      const scrollStart = (e) => {
        startX = e.offsetX;
        isScroll = true;
      };
      const scrollHandler = (e) => {
        if (isScroll && e.offsetX > 0)
          scr.scrollLeft = scr.scrollLeft + (startX - e.offsetX);
      };
      const scrollEnd = () => (isScroll = false);
      scr.addEventListener("dragstart", scrollStart);
      scr.addEventListener("drag", scrollHandler);
      scr.addEventListener("dragend", scrollEnd);
      return () => {
        scr.removeEventListener("dragstart", scrollStart);
        scr.removeEventListener("drag", scrollHandler);
        scr.removeEventListener("dragend", scrollEnd);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classWrap}>
      {view === "desc" ? (
        <div className="catalog-slider-title">
          {name ? <h3>{name}</h3> : null}
          <FaArrowAltCircleLeft
            className="position-absolute catalog-slider-left-icon"
            onClick={() => scrollButtons("left")}
          />
          <FaArrowAltCircleRight
            className="position-absolute catalog-slider-right-icon"
            onClick={() => scrollButtons("right")}
          />
        </div>
      ) : (
        <Link to={link} className="catalog-slider-title-mobil">
          <h3>{name}</h3>
          <FaArrowRight
            color="#008c00"
            className="catalog-slider-title-mobil-icon"
          />
        </Link>
      )}

      <div className="catalog-slider-body" id={id}>
        <div className="catalog-slider-list" id={id + "-list"}>
          {prods.map((item) => (
            <div
              className="catalog-slider-item position-relative"
              key={item.CODE}
            >
              {header ? header(item.ID) : null}
              <CatalogSectionItem prods={item} />
            </div>
          ))}
          {link ? (
            <div className="catalog-slider-item d-flex align-items-center justify-content-center">
              <Link to={link} className="catalog-slider-item-more">
                {t("MORE_PRODS")}
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
const getSettings = (type, data, path) => {
  const settings = {
    filter: "",
    limit: 10,
    page: 1,
    sort: "",
    pagesNav: [],
    sect: "",
    typeReq: "filter",
    searchList: "",
    filterDef: "",
    brands: "",
  };
  switch (type) {
    case "categories":
      let section = path
        .replace(/[0-9a-zA-Z_]*\/$/, "")
        .slice(1, -1)
        .split("/");
      section = section.pop();
      return { ...settings, sect: section };
    case "banner":
      return {
        ...settings,
        filterDef: "filterDef[productMonth]=Да&",
        img: "Y",
        typeReq: "page",
      };
    case "newspaper":
      return {
        ...settings,
        filterDef: "filterDef[newspaper]=Да&",
        img: "Y",
        typeReq: "page",
      };
    case "discounts":
      return {
        ...settings,
        filterDef: "filterDef[discounts]=Товар со скидкой&",
        img: "Y",
        typeReq: "page",
      };
    case "favorites":
      if (Array.isArray(data) && data.length > 0) {
        let list = "";
        data.forEach((item) => (list += `list[]=${item}&`));
        return { ...settings, filterDef: list };
      } else {
        return {};
      }
    default:
      return {};
  }
};
export default CatalogSlider;
