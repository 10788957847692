import React from "react";
import {
  FaCashRegister,
  FaCreditCard,
  FaExchangeAlt,
  FaParachuteBox,
  // FaPeopleCarry,
  FaRegSun,
  FaTag,
  FaTruck,
  FaUndoAlt,
} from "react-icons/fa";
import { useTranslation, Trans } from "react-i18next";

import Breadcrumbs from "../../Breadcrumbs";

export const RuPage = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-3">
      <Breadcrumbs />
      <h1>{t("SHIPMENT")}</h1>

      <div className="line-block">
        <div className="bg-white mt-3 p-lg-3">
          <h3>
            <FaTruck color="#8cc83c" /> {t("TERMS_OF_DELIVERY")}
          </h3>
          <p className="text-justify">
            <Trans
              i18nKey="TERMS_OF_DELIVERY_DESCRIPTION"
              components={[<br />]}
            />
          </p>
        </div>
        <div className="bg-white mt-3 ml-lg-3 p-lg-3">
          <h3>
            <FaCashRegister color="#8cc83c" /> {t("SHIPPING_COST")}
          </h3>
          <p className="text-justify">{t("SHIPPING_COST_DESCRIPTION")}</p>
        </div>
      </div>
      {/* <div className="bg-white mt-3 p-lg-3">
        <h3>
          <FaPeopleCarry color="#8cc83c" /> {t("PICKUP")}
        </h3>
        <p className="text-justify">{t("PICKUP_DESCRIPTION")}</p>
      </div> */}
      <div className="line-block">
        <div className="bg-white mt-3 p-lg-3">
          <h3>
            <FaCreditCard color="#8cc83c" /> {t("PAYMENT_METHODS")}
          </h3>
          {/* <p className="text-justify">
            <FaRegSun color="#8cc83c" /> Наличными курьеру при доставке
          </p> */}
          {/* <p className="text-justify">
            <FaRegSun color="#8cc83c" /> {t("PAYMENT_METHODS_CARD")}
          </p> */}
          <p className="text-justify">
            <FaRegSun color="#8cc83c" /> {t("PAYMENT_METHODS_SAVED_CARD")}
          </p>
          <p className="text-justify">
            <FaRegSun color="#8cc83c" /> {t("PAYMENT_METHODS_ONLINE")}
          </p>
          <p className="text-justify">
            <FaRegSun color="#8cc83c" /> Kaspi Pay
          </p>
          {/* <p className="text-justify">
            <FaRegSun color="#8cc83c" /> {t("PAYMENT_METHODS_INVOICE")}
          </p> */}
        </div>
        <div className="bg-white mt-3 ml-lg-3 p-lg-3">
          <h3>
            <FaTag color="#8cc83c" /> {t("CHANGING_COST_OF_ORDER")}
          </h3>
          <p className="text-justify">
            {t("CHANGING_COST_OF_ORDER_DESCRIPTION")}
          </p>
          <p className="text-justify">
            <FaRegSun color="#8cc83c" />{" "}
            {t("CHANGING_COST_OF_ORDER_DESCRIPTION_ITEM1")}
          </p>
          <p className="text-justify">
            <FaRegSun color="#8cc83c" />{" "}
            {t("CHANGING_COST_OF_ORDER_DESCRIPTION_ITEM2")}
          </p>
          <p className="text-justify">
            <FaRegSun color="#8cc83c" />{" "}
            {t("CHANGING_COST_OF_ORDER_DESCRIPTION_ITEM3")}
          </p>
          <p className="text-justify">
            <FaRegSun color="#8cc83c" />{" "}
            {t("CHANGING_COST_OF_ORDER_DESCRIPTION_ITEM4")}
          </p>
        </div>
      </div>
      <div className="line-block">
        <div className="bg-white mt-3 p-lg-3">
          <h3>
            <FaExchangeAlt color="#8cc83c" /> {t("REPLACEMENT_OF_GOODS")}
          </h3>
          <p className="text-justify">
            {t("REPLACEMENT_OF_GOODS_DESCRIPTION")}
          </p>
        </div>
        <div className="bg-white mt-3 ml-lg-3 p-lg-3">
          <h3>
            <FaUndoAlt color="#8cc83c" /> {t("RETURN_OF_GOODS")}
          </h3>
          <p className="text-justify">{t("RETURN_OF_GOODS_DESCRIPTION")}</p>
        </div>
      </div>
      <div className="bg-white mt-3 p-lg-3">
        <h3>
          <FaParachuteBox color="#8cc83c" /> {t("RECEIVING_ORDER")}
        </h3>
        <p className="text-justify">{t("RECEIVING_ORDER_DESCRIPTION_PART1")}</p>
        <p className="text-justify">{t("RECEIVING_ORDER_DESCRIPTION_PART2")}</p>
      </div>
      <div className="bg-white mt-3 p-lg-3">
        <h3>{t("QUESTIONS_AND_SUGGESTIONS")}</h3>
        <p className="text-justify">
          {t("QUESTIONS_AND_SUGGESTIONS_DESCRIPTION")}
        </p>
      </div>
    </div>
  );
};
