import React, { useState, Fragment } from "react";
import { FaClipboardList } from "react-icons/fa";
import { Spinner, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";

import SaleButton from "../SaleButton";
import { ResponsiveLayout } from "../ResponsiveLayout";
import { PersonalOrdersRepeat } from "./PersonalOrdersRepeat";

import noImg from "./no-img1.jpg";
export const PersonalOrdersDetail = ({ order }) => {
  const bitrix = useBitrix();

  const [orderInfo, setOrderInfo] = useState(null);
  const [load, setLoad] = useState(false);

  const getData = () => {
    setLoad(true);
    bitrix.sale
      .order(order.ID)
      .then((data) => {
        if (data.ACCOUNT_NUMBER) {
          setOrderInfo(data);
        }
      })
      .catch((e) => {})
      .finally(() => setLoad(false));
  };
  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <PersonalOrdersDetailD
          load={load}
          getData={getData}
          orderInfo={orderInfo}
          setOrderInfo={setOrderInfo}
        />
      )}
      renderMobile={() => (
        <PersonalOrdersDetailM
          load={load}
          getData={getData}
          orderInfo={orderInfo}
          setOrderInfo={setOrderInfo}
        />
      )}
    />
  );
};

const PersonalOrdersDetailD = ({ load, getData, orderInfo, setOrderInfo }) => {
  const { t, i18n } = useTranslation();

  const units = {
    шт: t("UNITS_PIECES"),
    "л.": t("UNITS_LITERS"),
    г: t("UNITS_GRAMMS"),
    кг: t("UNITS_KILOGRAMMS"),
  };

  return (
    <Fragment>
      <button className="btn order-button" onClick={getData}>
        {load ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <FaClipboardList color="#2882c8" className="mr-1" />
        )}{" "}
        <span>{t("DETAIL_LABEL")}</span>
      </button>
      {orderInfo ? (
        <div className="personal-orders-detail-modal-wrap">
          <div className="personal-orders-detail-modal">
            <span
              className="link-block close-button"
              onClick={() => setOrderInfo(null)}
            >
              &times;
            </span>
            <h2>
              {t("ORDER_LABEL")} №{orderInfo.ACCOUNT_NUMBER}
            </h2>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column mr-3 position-relative">
                <span>{orderInfo.DATA}</span>
                <span>
                  {orderInfo["BASKET"].length +
                    " " +
                    plural(orderInfo["BASKET"].length, [
                      t("PRODS_LABEL_FORM1"),
                      t("PRODS_LABEL_FORM2"),
                      t("PRODS_LABEL_FORM3"),
                    ]) +
                    ". " +
                    t("ON_SUM_SMALL") +
                    " " +
                    formatSum("" + parseInt(orderInfo["PRICE"])) +
                    t("TENGE")}
                </span>
                <PersonalOrdersRepeat order={{ ID: orderInfo.ID }} />
              </div>
              <div className="d-flex flex-column">
                <h6>{t("DELIVERY_LABEL")}</h6>
                <p>{orderInfo.ADDRESS}</p>
                <p>{orderInfo.DATA_TIME}</p>
                <div>
                  <h6>{t("PRICE_DELIVERY_LABEL")}:</h6>{" "}
                  {parseInt(orderInfo.PRICE_DELIVERY)}
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex flex-column">
              {orderInfo.BASKET.map((item) => {
                const prod = {
                  NAME: item.LANG[i18n.resolvedLanguage],
                  ID: item.PRODUCT_ID,
                  PROPERTY_CML2_BASE_UNIT_VALUE:
                    units[item.PROPERTY_CML2_BASE_UNIT],
                  PRICE: item.NOW_PRICE,
                  PROPERTY_AVERAGE_WEIGHT_VALUE:
                    item.PROPERTY_AVERAGE_WEIGHT_VALUE
                      ? item.PROPERTY_AVERAGE_WEIGHT_VALUE
                      : null,
                };
                const picture = item.PICTURE ? item.PICTURE : noImg;

                return (
                  <div
                    key={orderInfo.ID + item.PRODUCT_ID}
                    className="d-flex order-detail-basket-item"
                  >
                    {item.DETAIL_PAGE_URL && item.ACTIVE === "Y" ? (
                      <Link to={item.DETAIL_PAGE_URL}>
                        <Image src={picture} fluid />
                      </Link>
                    ) : (
                      <Image src={picture} fluid className="order-img" />
                    )}

                    <div className="d-flex flex-column order-detail-basket-item-name">
                      <h3>{item.LANG[i18n.resolvedLanguage]}</h3>
                      <p>
                        {parseFloat(item.QUANTITY) +
                          " " +
                          units[item.PROPERTY_CML2_BASE_UNIT] +
                          " ( " +
                          Math.round(parseInt(item.PRICE) * item.QUANTITY) +
                          t("TENGE") +
                          " )"}
                      </p>
                    </div>
                    {item.NOW_PRICE && item.ACTIVE === "Y" ? (
                      <Fragment>
                        <div className="d-flex flex-column align-items-center justify-content-center order-detail-basket-item-price">
                          <span className="catalog-section-item-price">
                            {parseInt(item.NOW_PRICE) +
                              " " +
                              t("TENGE") +
                              "/" +
                              units[item.PROPERTY_CML2_BASE_UNIT]}
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-center order-detail-basket-item-sale">
                          <SaleButton prod={prod} />
                        </div>
                      </Fragment>
                    ) : (
                      <div className="d-flex flex-column align-items-center justify-content-center order-detail-basket-item-price">
                        <span className="bg-danger text-white py-1">
                          {t("NOT_PROD")}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
const PersonalOrdersDetailM = ({ load, getData, orderInfo, setOrderInfo }) => {
  const { t, i18n } = useTranslation();

  const units = {
    шт: t("UNITS_PIECES"),
    "л.": t("UNITS_LITERS"),
    г: t("UNITS_GRAMMS"),
    кг: t("UNITS_KILOGRAMMS"),
  };

  return (
    <Fragment>
      <button className="btn order-button" onClick={getData}>
        {load ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <FaClipboardList color="#2882c8" className="mr-1" />
        )}{" "}
        <span>{t("DETAIL_LABEL")}</span>
      </button>
      {orderInfo ? (
        <div className="personal-orders-detail-modal-wrap">
          <div className="personal-orders-detail-modal">
            <span
              className="link-block close-button"
              onClick={() => setOrderInfo(null)}
            >
              &times;
            </span>
            <h2>
              {t("ORDER_LABEL")} №{orderInfo.ACCOUNT_NUMBER}
            </h2>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column w-50 position-relative">
                <span>{orderInfo.DATA}</span>
                <span>
                  {orderInfo["BASKET"].length}{" "}
                  {plural(orderInfo["BASKET"].length, [
                    t("PRODS_LABEL_FORM1"),
                    t("PRODS_LABEL_FORM2"),
                    t("PRODS_LABEL_FORM3"),
                  ])}{" "}
                  {t("ON_SUM_SMALL") +
                    " " +
                    formatSum("" + parseInt(orderInfo["PRICE"])) +
                    t("TENGE")}
                </span>
                <PersonalOrdersRepeat order={{ ID: orderInfo.ID }} />
              </div>
              <div className="d-flex flex-column w-50">
                <h6>{t("DELIVERY_LABEL")}</h6>
                <p>{orderInfo.ADDRESS}</p>
                <p>{orderInfo.DATA_AND_TIME}</p>
                <div>
                  <h6>{t("PRICE_DELIVERY_LABEL")}:</h6>{" "}
                  {parseInt(orderInfo.PRICE_DELIVERY)}
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex flex-column">
              {orderInfo.BASKET.map((item) => {
                const prod = {
                  NAME: item.PRODUCT_INFO.LANG[i18n.resolvedLanguage],
                  ID: item.PRODUCT_INFO.ID,
                  PROPERTY_CML2_BASE_UNIT_VALUE:
                    item.PRODUCT_INFO.PROPERTY_CML2_BASE_UNIT_VALUE,
                  PRICE: item.PRODUCT_INFO.PRICE,
                };
                const picture = item.PRODUCT_INFO.PREVIEW_PICTURE
                  ? item.PRODUCT_INFO.PREVIEW_PICTURE
                  : noImg;
                const discount =
                  parseInt(item.PRODUCT_INFO.OLD_PRICE) > 0
                    ? Math.round(
                        ((parseInt(item.PRODUCT_INFO.OLD_PRICE) -
                          parseInt(item.PRODUCT_INFO.PRICE)) /
                          parseInt(item.PRODUCT_INFO.OLD_PRICE)) *
                          100
                      )
                    : 0;
                return (
                  <div
                    key={orderInfo.ID + item.PRODUCT_ID}
                    className="d-flex order-detail-basket-item"
                  >
                    {item.PRODUCT_INFO ? (
                      <Link to={item.PRODUCT_INFO.DETAIL_PAGE_URL}>
                        <Image src={picture} fluid className="order-img" />
                      </Link>
                    ) : (
                      <Link to={item.PRODUCT_INFO.DETAIL_PAGE_URL}>
                        <Image src={picture} fluid className="order-img" />
                      </Link>
                    )}

                    <div className="d-flex flex-column order-detail-basket-item-name">
                      <h3>{item.LANG[i18n.resolvedLanguage]}</h3>
                      <p>
                        {t("ORDER_QUANTITY")}: {item.QUANTITY}{" "}
                        {item.PRODUCT_INFO.PROPERTY_CML2_BASE_UNIT_VALUE} (
                        {" " +
                          Math.round(parseInt(item.PRICE) * item.QUANTITY) +
                          t("TENGE") +
                          " "}
                        )
                      </p>

                      {item.PRODUCT_INFO ? (
                        <div className="d-flex flex-columnjustify-content-center order-detail-basket-item-price mt-1">
                          {parseInt(item.PRODUCT_INFO.OLD_PRICE) > 0 ? (
                            <div className="d-flex flex-column  justify-content-center old-price-wrap">
                              <div className="d-flex">
                                <span className="catalog-detail-item-old-price">
                                  {parseInt(item.PRODUCT_INFO.OLD_PRICE) +
                                    " " +
                                    t("TENGE")}
                                </span>
                                <span className="catalog-detail-item-old-price-label">
                                  -{discount}%
                                </span>
                              </div>
                              <div className="catalog-section-item-old-price-pricewrap">
                                <span className="catalog-detail-item-price">
                                  {parseInt(item.PRODUCT_INFO.PRICE) +
                                    " " +
                                    t("TENGE")}
                                  /
                                  {
                                    units[
                                      item.PRODUCT_INFO
                                        .PROPERTY_CML2_BASE_UNIT_VALUE
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                          ) : (
                            <span className="catalog-detail-item-price">
                              {parseInt(item.PRODUCT_INFO.PRICE) +
                                " " +
                                t("TENGE")}
                              /
                              {
                                units[
                                  item.PRODUCT_INFO
                                    .PROPERTY_CML2_BASE_UNIT_VALUE
                                ]
                              }
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="d-flex flex-column order-detail-basket-item-price">
                          <span className="bg-danger text-white py-1">
                            {t("NOT_PROD")}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-center basket-sale-button">
                      {item.PRODUCT_INFO ? (
                        <div>
                          <SaleButton prod={prod} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
const plural = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};
const formatSum = (summ) => {
  const separator = " ";
  return summ.replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g, "$1" + separator);
};
