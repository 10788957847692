import React from "react";
import { useTranslation } from "react-i18next";

import { AddressMap } from "./AddressMap";
import { PersonalAddressItem } from "./PersonalAddressItem";

export const PersonalAddressM = ({ address, params }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column personal-address">
      <div>
        <h3>{t("ADDRESSES_LIST")}</h3>
        <div className="d-flex flex-column mb-3">
          {address.map((item, id) => (
            <PersonalAddressItem
              address={item}
              key={"address" + id}
              delAddress={() => params.delAddress(item)}
            />
          ))}
          {address.length === 0 ? (
            <strong className="text-center">{t("EMPTY_LIST")}</strong>
          ) : null}
        </div>
      </div>
      <div className="mt-2 d-flex">
        <small>*{t("PERSONAL_ADDRESS_MSG_MOB")}</small>
      </div>
      <div className="mb-4">
        <AddressMap
          ymaps={params.ymap}
          saveAddress={params.addAddress}
          addressList={address}
        />
      </div>
    </div>
  );
};
