import React from 'react';
import './contract.css'
import { RuPage } from './RuPage';
const Contract = ({lang})=>{
    switch(lang){
        case 'ru':
            return <RuPage/>
        default:
            return <RuPage/>
    }
}
export default Contract