import React, { createContext, useContext, useState } from 'react'
import CrashPage from './StaticPage/CrashPage'
import ym from 'react-yandex-metrika'

const ErrorCtx = createContext(null)

export const ErrorProvider = ({children})=>{
    const [hasErr,setErr] = useState(false)
    const crash = ()=>{
        if(ym) ym('reachGoal','CRASH')
        setErr(true)
    }

    if(hasErr){
        return (
            <main className="d-flex justify-content-center align-items-center" style={{minHeight:'100vh'}}>
                <CrashPage/>
            </main>
        )
    }
    return (
        <ErrorCtx.Provider value={{setErr:crash}}>
            {children}
        </ErrorCtx.Provider>
    )
}
export const useCrash = ()=>useContext(ErrorCtx)