import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useFireAnalitic } from "../FireProvider";
import ym from "react-yandex-metrika";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";
import { userActions } from "../../state/actions";

import { Spinner } from "react-bootstrap";

import "./personal-favorite.css";

export const FavoriteButton = ({ prodId }) => {
  const { t } = useTranslation();
  const analist = useFireAnalitic();
  const { id, favorite } = useSelector((state) => state.user);
  const dispath = useDispatch();
  const bitrix = useBitrix();

  const [load, setLoad] = useState(false);

  const setFavorite = () => {
    let arr = [];
    if (favorite.includes(parseInt(prodId))) {
      arr = favorite.filter((item) => item !== parseInt(prodId));
    } else {
      if (ym) ym("reachGoal", "favorite_prod");
      if (analist)
        analist.logEvent("add_to_wishlist", {
          currency: "KZT",
          items: [
            {
              item_id: prodId,
            },
          ],
        });
      arr = [...favorite, parseInt(prodId)];
    }
    setLoad(true);
    bitrix.users
      .updateUser({ UF_FAVORITES: arr })
      .then((data) => {
        if (data["status"] && data["status"] === "success") {
          dispath(userActions.setFavorite(arr));
        } else {
          alert(t("SERVICE_NOT_FOUND"));
        }
      })
      .catch((e) => {
        alert(t("SERVICE_NOT_FOUND"));
      })
      .finally(() => setLoad(false));
  };
  if (load) {
    return (
      <Spinner
        animation="border"
        size="sm"
        variant="success"
        className="favorite-button"
      />
    );
  }
  if (id && favorite && favorite.includes(parseInt(prodId))) {
    return (
      <FaHeart
        onClick={setFavorite}
        className="favorite-button"
        size="22"
        color="#28a745"
      />
    );
  }
  if (id && !favorite.includes(parseInt(prodId))) {
    return (
      <FaRegHeart
        onClick={setFavorite}
        className="favorite-button"
        size="22"
        color="#28a745"
      />
    );
  }
  return <Fragment />;
};
