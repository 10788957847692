import React, { useState } from "react";
import { Image, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BannerSliderD = ({ slider }) => {
  const { i18n } = useTranslation();

  const [slide, setSlide] = useState(0);

  return (
    <div className="banner-slider">
      {slider[slide]["HREF"].includes("http") ? (
        <a href={slider[slide]["HREF"]} className="banner-slider-img">
          <Image
            src={slider[slide]["LANG"]["PICTURE"][i18n.resolvedLanguage]}
            fluid
          />
        </a>
      ) : (
        <Link to={slider[slide]["HREF"]} className="banner-slider-img">
          <Image
            src={slider[slide]["LANG"]["PICTURE"][i18n.resolvedLanguage]}
            fluid
          />
        </Link>
      )}

      <ListGroup className="bg-white banner-slider-menu">
        {slider.map((item, id) => (
          <ListGroup.Item
            onClick={() => setSlide(id)}
            key={"slide" + id}
            active={slide === id}
          >
            {item["LANG"]["NAME"][i18n.resolvedLanguage]}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};
