import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Breadcrumbs from "../components/Breadcrumbs";
import CatalogDetail from "../components/CatalogDetail";
import CatalogSlider from "../components/CatalogSlider";
import { FavoriteButton } from "../components/PersonalFavorite/FavoriteButton";

export const CatalogDetailPage = ({ id }) => {
  const { code } = useParams();

  if (!id && code) {
    return (
      <main className="pt-3">
        <Container>
          <Row>
            <Col>
              <CatalogDetail id={code} />
            </Col>
          </Row>
        </Container>
      </main>
    );
  }

  return (
    <main className="pt-3">
      <Container>
        <Breadcrumbs />
        <Row>
          <Col>
            <CatalogDetail id={id} />
          </Col>
        </Row>
        <Row>
          <Col>
            <CatalogSlider
              type="categories"
              header={(id) => <FavoriteButton prodId={id} />}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};
