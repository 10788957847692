import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { YMaps, withYMaps } from "react-yandex-maps";
import YmapsService from "../../services/ymaps/ymaps-service";
import { useTranslation } from "react-i18next";

import { userActions } from "../../state/actions";
import { useBitrix } from "../BitrixProvider";

import { ResponsiveLayout } from "../ResponsiveLayout";
import { PersonalAddressD } from "./PersonalAddressD";
import { PersonalAddressM } from "./PersonalAddressM";

import "./personal-address.css";

const PersonalAddress = ({ ymaps }) => {
  const { t } = useTranslation();
  const { address } = useSelector((state) => state.user);
  const dispath = useDispatch();
  const bitrix = useBitrix();
  const ymap = new YmapsService(ymaps);

  const delAddress = (add) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(t("DEL_ADDRESS_QUEST"))) {
      const newAddressArr = address.filter((item) => item !== add);
      dispath(userActions.setAddress(newAddressArr));
      bitrix.users.updateUser({ UF_DEL_ADDRESS: newAddressArr });
    }
  };
  const addAddress = (add) => {
    const newAddressArr = [...address, add];
    dispath(userActions.setAddress(newAddressArr));
    bitrix.users.updateUser({ UF_DEL_ADDRESS: newAddressArr });
  };
  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <PersonalAddressD
          address={address}
          params={{ delAddress, addAddress, ymap }}
        />
      )}
      renderMobile={() => (
        <PersonalAddressM
          address={address}
          params={{ delAddress, addAddress, ymap }}
        />
      )}
    />
  );
};
const PersonalAddressWithYMaps = () => {
  const PersonalAddressYMapsWrap = React.useMemo(() => {
    return withYMaps(PersonalAddress, false, ["geocode", "geoQuery"]);
  }, []);
  return (
    <YMaps
      query={{ lang: "ru_RU", apikey: "c5af4455-6b73-439c-ad0a-f2f40be2ff58" }}
    >
      {" "}
      <PersonalAddressYMapsWrap />
    </YMaps>
  );
};
export default PersonalAddressWithYMaps;
