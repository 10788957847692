import React, { useState } from "react";
import { Spinner, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DeliveryMap } from "./DeliveryMap";

export const OrderDeliveryD = ({ params }) => {
  const { t } = useTranslation();

  const [map, showMap] = useState(false);
  const [load, toggleLoad] = useState(false);

  const changeAddessRadio = async (address) => {
    toggleLoad(true);
    const deliveryInfo = await params.bitrix.sale.getDeliveryPoint(address);
    params.setAddress(deliveryInfo, address);
    toggleLoad(false);
  };

  return (
    <div className="order-delivery">
      <h6>{t("DELIVERY_TYPE")}</h6>
      <div className="order-delivery-tabs-checkbox d-flex justify-content-around">
        <Form.Check
          type="radio"
          id="couers-delivery"
          name="type-delivery"
          className="cust-radio"
          checked={params.type === 4}
          onChange={() => params.changeType(4)}
          label={t("DELIVERY_COUERS")}
          custom
        />
        <Form.Check
          type="radio"
          id="pick-delivery"
          name="type-delivery"
          className="cust-radio"
          checked={params.type === 3}
          onChange={() => params.changeType(3)}
          label={t("DELIVERY_PICK_LABEL")}
          custom
        />
      </div>
      <div className="order-delivery-body pt-2 pb-2">
        {params.type === 4 ? (
          <div className="order-delivery-custom-address">
            <h6>{t("DELIVERY_ADDRESS")}:</h6>
            <div className="order-delivery-useradress">
              {params.userAddress.map((item, id) => {
                return (
                  <Form.Check
                    key={"ad-" + id}
                    type="radio"
                    id={"adress-" + id}
                    name="user-address"
                    className="cust-radio"
                    checked={params.address === item}
                    onChange={() => changeAddessRadio(item)}
                    label={item}
                    custom
                  />
                );
              })}
            </div>
            {load ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="success"
              />
            ) : (
              <div className="d-flex justify-content-center order-delivery-add-address mt-1">
                <span onClick={() => showMap(true)}>+</span>
              </div>
            )}
            <DeliveryMap
              show={map}
              close={() => showMap(false)}
              ymaps={params.ymaps}
              api={params.bitrix}
              saveAddress={params.setAddress}
            />
          </div>
        ) : (
          <div className="order-delivery-system-address">
            <h6>{t("PICK_DELIVERY_ADDRESS")}</h6>
            <div className="d-flex align-items-center">
              <Form.Check
                type="radio"
                // id="gip"
                id="k85"
                className="cust-radio"
                checked={true}
                onChange={() => {}}
                // label={t("GIPER_SHORT_ADDRESS")}
                label={t("K85_SHORT_ADDRESS")}
                custom
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
