import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";
import { userActions } from "../../state/actions";

// import { useWindowDimensions } from "../WindowDimensionsProvider";
import PersonalAuth from "../PersonalAuth";

import ava from "./ava.svg";

const PersonalLink = ({ finish = null }) => {
  const { id: userId, name } = useSelector((state) => state.user);
  const dispath = useDispatch();
  const bitrix = useBitrix();

  useEffect(() => {
    bitrix.users
      .isAuth()
      .then((data) => {
        if (data.ID) {
          const user = {
            id: data.ID,
            name: data.NAME,
            login: data.LOGIN,
            favorite: data.FAVORITE,
            address: data.ADDRESS,
            bonuscard: data.BONUSCARD,
            phone: data.PERSONAL_MOBILE ? data.PERSONAL_MOBILE : data.LOGIN,
          };
          dispath(userActions.setUser(user));
        }
      })
      .catch((e) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (userId) {
    return <CabinetLink name={name} finish={finish} />;
  }
  return <PersonalAuth />;
};
const CabinetLink = ({ name = "", finish = null }) => {
  const { t } = useTranslation();
  // const { isMobil } = useWindowDimensions();

  // if (isMobil) {
  //   return (
  //     <Link
  //       to="/personal/"
  //       className="d-flex align-items-center text-decoration-none"
  //       onClick={finish}
  //     >
  //       <Image src={ava} width={50} className="p-2" />
  //       <div className="d-flex flex-column">
  //         <span>{name}</span>
  //         <small className="text-white text-shipment">
  //           {t("PERSONAL_CABINET")}
  //         </small>
  //       </div>
  //     </Link>
  //   );
  // }
  return (
    <Link
      to="/personal/"
      className="d-flex flex-column align-items-center text-decoration-none"
    >
      <Image src={ava} width={50} className="p-2" />
      <span className="text-white text-shipment">{t("PERSONAL_CABINET")}</span>
    </Link>
  );
};
export default PersonalLink;
