export default class YmapsService{
    constructor(ymaps){
        this.ymaps = ymaps
    }
    ymaps={}
    map={}
    zones={
        "type": "FeatureCollection",
        "features": [{
                "type": "Feature",
                "id": 0,
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [                                    
                            [53.280473, 63.573456],
                            [53.258701, 63.591508],
                            [53.248530, 63.622766],
                            [53.236216, 63.622936],
                            [53.233553, 63.630381],
                            [53.231151, 63.632962],
                            [53.228728, 63.638579],
                            [53.224329, 63.649754],
                            [53.220580, 63.656353],
                            [53.219073, 63.659775],
                            [53.210625, 63.688454],
                            [53.184075, 63.785671],
                            [53.270659, 63.891466],
                            [53.330375, 63.670679],
                            [53.281146, 63.570039]
                        ]
                    ]
                },
                "properties": { "name": "Каирбекова, 379", "base_coords": [53.239447, 63.679972], "base_id": "k379" },    
                "options": {
                    "opacity": 0
                },
            },
            {
                "type": "Feature",
                "id": 1,
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [53.267994, 63.525577],
                            [53.224001, 63.583972],
                            [53.222021, 63.588176],
                            [53.221930, 63.595094],
                            [53.221338, 63.600526],
                            [53.217725, 63.602529],
                            [53.214601, 63.604502],
                            [53.212579, 63.600648],
                            [53.210826, 63.604057],
                            [53.205197, 63.614832],
                            [53.202676, 63.619938],
                            [53.200507, 63.622818],
                            [53.199298, 63.626582],
                            [53.192078, 63.648557],
                            [53.158400, 63.614531],
                            [53.073593, 63.516360],
                            [53.141754, 63.379215],
                            [53.279010, 63.562476]
                        ]
                    ]
                },
                "properties": { "name": "Карбышева, 85", "base_coords": [53.185019, 63.586274], "base_id": "k85" },
                "options": {
                    "opacity": 0
                },
            },
            {
                "type": "Feature",
                "id": 2,
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [53.279440, 63.567372],
                            [53.247339, 63.622016],
                            [53.236055, 63.622054],
                            [53.231022, 63.632776],
                            [53.228798, 63.637781],
                            [53.227304, 63.639648],
                            [53.223096, 63.648467],
                            [53.218311, 63.658941],
                            [53.210678, 63.688381],
                            [53.184184, 63.788967],
                            [53.184745, 63.717751],
                            [53.117981, 63.674008],
                            [53.137298, 63.587454],
                            [53.170983, 63.622639],
                            [53.195933, 63.646811],
                            [53.198075, 63.624733],
                            [53.212713, 63.600972],
                            [53.224286, 63.585909],
                            [53.244749, 63.554388],
                            [53.265155, 63.524964],
                            [53.280382, 63.566429]
                        ]
                    ]
                },
                "properties": { "name": "Гипер", "base_coords": [53.202201, 63.660430], "base_id": "g1" },    
                "options": {
                    "opacity": 0
                },
            }
        ]
    }
    setMap(map){
        this.map = map
    }

    async getBaseCoords(coord){
        const deliveryZones = this.ymaps.geoQuery(this.zones).addToMap(this.map)
        const polygon = await deliveryZones.searchContaining(coord).get(0)
        deliveryZones._objects.forEach(item=>this.map.geoObjects.remove(item))
        return {base:polygon.properties._data.base_coords,coord,point:polygon.properties._data.base_id}

    }
    async getDistance(stations){
        const route = await this.ymaps.route([stations.base,stations.coord],{multiRoute: true})
        let routes = route.model.getRoutes();
        let minRoute = routes[0].properties.get("distance").value
        routes.forEach(item=>{
            if(item.properties.get("distance").value<minRoute){
                minRoute = item.properties.get("distance").value
            }
        })
        return [minRoute,stations] 
    }
    async getCoords(address){              
        const coords = await this.ymaps.geocode(address,{boundedBy:[[53.094477,63.379020],[53.338745,63.834099]]})
        return coords.geoObjects.get(0).geometry.getCoordinates()
    }
    async getDeliveryInfo(address){
        const coord = await this.getCoords(address)
        const stations = await this.getBaseCoords(coord)
        const distanceInfo = await this.getDistance(stations)
        return {
            address,
            coord:coord[0]+' '+coord[1],
            point:distanceInfo[1]['point'],
            dist:Math.round(distanceInfo[0])
        }
    }
    async getAddress(coord){
        const res = await this.ymaps.geocode(coord)
        let address = res.geoObjects.get(0)
        address = address.getAddressLine().replace('Казахстан, Костанайская область, ','')
        return address
    }
} 