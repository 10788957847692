import React from 'react';
import {RuPage} from './RuPage'
import './con-style.css'
const Contacts = ({lang})=>{
    switch(lang){
        case 'ru':
            return <RuPage/>
        default:
            return <RuPage/>
    }
}
export default Contacts