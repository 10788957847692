import React, { createContext, useContext, useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";

ReactPixel.init("594741565950307");

const FbPixelCtx = createContext(null);

export const FbPixelProvider = ({ children }) => {
  const [pixel, setPixel] = useState(null);
  useEffect(() => {
    if (ReactPixel) setPixel(ReactPixel);
    return () => setPixel(null);
  }, []);
  return <FbPixelCtx.Provider value={pixel}>{children}</FbPixelCtx.Provider>;
};
export const usePixel = () => useContext(FbPixelCtx);
