import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import { MainPage } from "../screens/MainPage";
import { CatalogRouter } from "./CatalogRouter";
import { SaleBasketPage } from "../screens/SaleBasketPage";
import { SaleOrderPage } from "../screens/SaleOrderPage";
import { PersonalPage } from "../screens/PersonalPage";
import PersonalInfo from "./PersonalInfo";
import PersonalOrders from "./PersonalOrders";
import PersonalAddress from "./PersonalAddress";
import StaticPage from "./StaticPage";
import { PersonalOrdersFeedback } from "./PersonalOrders/PersonalOrdersFeedback";
// import { BonusPage } from '../screens/BonusPage';
import { useFireAnalitic } from "./FireProvider";
import { usePixel } from "./FbPixelProvider";
import { PaymentPage } from "../screens/PaymentPage";
import { CatalogDetailPage } from "../screens/CatalogDetailPage";

export const AppNavigation = () => {
  const { pathname } = useLocation();
  const analist = useFireAnalitic();
  const pixel = usePixel();
  useEffect(() => {
    if (analist) {
      analist.logEvent("page_view", { page_location: pathname });
    }
    if (pixel) {
      pixel.pageView();
    }
  }, [pathname, analist, pixel]);
  return (
    <Switch>
      <Route path="/" component={MainPage} exact />
      <Route path="/catalog/" component={CatalogRouter} />
      <Route path="/product/:code" component={CatalogDetailPage} />
      <Route path="/discounts/" component={CatalogRouter} />
      <Route path="/newspaper/" component={CatalogRouter} />
      <Route path="/banners/" component={CatalogRouter} />
      {/* <Route path="/banner/" component={CatalogRouter} /> */}
      <Route path="/baners/" component={CatalogRouter} />
      <Route path="/brands/" component={CatalogRouter} />
      <Route path="/search/" component={CatalogRouter} />
      <Route path="/basket/" component={SaleBasketPage} />
      <Route path="/order/" component={SaleOrderPage} />
      <Route path="/personal/" component={PersonalPage} exact />
      {/* <Route path="/bonus/" component={BonusPage} exact/> */}
      <Route
        path="/personal/profile/"
        component={() => <LayerWrap Com={<PersonalInfo />} />}
        exact
      />
      <Route
        path="/personal/orders/"
        component={() => <LayerWrap Com={<PersonalOrders />} />}
        exact
      />
      <Route path="/personal/favorite/" component={CatalogRouter} exact />
      <Route
        path="/personal/address/"
        component={() => <LayerWrap Com={<PersonalAddress />} />}
        exact
      />
      <Route
        path="/shipping_and_payment/"
        component={() => (
          <LayerWrap Com={<StaticPage page="shipping_and_payment" />} />
        )}
        exact
      />
      <Route
        path="/contacts/"
        component={() => <LayerWrap Com={<StaticPage page="contacts" />} />}
        exact
      />
      <Route
        path="/supermarkets/"
        component={() => <LayerWrap Com={<StaticPage page="supermarkets" />} />}
        exact
      />
      <Route
        path="/feedback/"
        component={() => <LayerWrap Com={<PersonalOrdersFeedback />} />}
      />
      <Route path="/payment/" component={PaymentPage} />
      <Route component={() => <LayerWrap Com={<StaticPage />} />} />
    </Switch>
  );
};
const LayerWrap = ({ Com }) => {
  return (
    <main className="pt-3">
      <Container>
        <Row>
          <Col>{Com}</Col>
        </Row>
      </Container>
    </main>
  );
};
