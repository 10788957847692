import React, { Fragment } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import SaleButton from "../SaleButton";
import { FavoriteButton } from "../PersonalFavorite/FavoriteButton";
import { ProdProperty } from "./ProdProperty";

import noImg from "./no-image.jpg";

export const CatalogDetailM = ({ prod }) => {
  const { t, i18n } = useTranslation();

  const picture = prod.DETAIL_PICTURE
    ? "https://" + prod.DETAIL_PICTURE
    : noImg;
  const discount =
    parseInt(prod.OLD_PRICE) > 0
      ? Math.round(
          ((parseInt(prod.OLD_PRICE) - parseInt(prod.PRICE)) /
            parseInt(prod.OLD_PRICE)) *
            100
        )
      : 0;

  return (
    <div className="catalog-detail-mobil position-relative">
      <h2>{prod.LANG[i18n.resolvedLanguage]}</h2>
      <div className="position-relative">
        <FavoriteButton prodId={prod.ID} />
        <Image src={picture} className="mt-3 prew-img" fluid />
        {prod.PROPERTY_AVERAGE_WEIGHT_VALUE ? (
          <div className="catalog-detail-averege-weight">
            <span>
              {t("AVERAGE_WEIGHT", {
                weight: prod.PROPERTY_AVERAGE_WEIGHT_VALUE,
              })}
            </span>
          </div>
        ) : null}
      </div>

      <div className="position-relative">
        <div className="catalog-detail-sale-button">
          <SaleButton prod={prod} />
        </div>
      </div>
      <div className="prices d-flex align-items-center justify-content-start pl-3 mt-3 mb-3">
        {parseInt(prod.OLD_PRICE) ? (
          <div className="d-flex flex-column old-price-wrap">
            <div className="d-flex align-items-center">
              <span className="catalog-detail-item-old-price">
                {parseInt(prod.OLD_PRICE) + " " + t("TENGE")}
              </span>
              <span className="catalog-detail-item-old-price-label">
                -{discount}%
              </span>
            </div>
            <div>
              <span className="catalog-detail-item-price">
                {parseInt(prod.PRICE) + " " + t("TENGE")}
              </span>
            </div>
          </div>
        ) : (
          <Fragment>
            <span className="catalog-detail-item-price">
              {parseInt(prod.PRICE) + " " + t("TENGE")}
            </span>
          </Fragment>
        )}
      </div>
      <p>{prod.DETAIL_TEXT}</p>
      <ProdProperty properties={prod.PROPERTY} />
    </div>
  );
};
