import React from 'react';
import { RuPage } from './RuPage';
import './sup-style.css'
const Supermarkets = ({lang})=>{
    switch(lang){
        case 'ru':
            return <RuPage/>
        default:
            return <RuPage/>
    }
}
export default Supermarkets