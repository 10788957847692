import React, {  useState } from 'react'
import star from './star.png'
import starB from './star-b.png'
import { Image } from 'react-bootstrap'
import {useBitrix} from '../BitrixProvider'
export const PersonalOrdersRating = ({order})=>{
    const [value,setValue] = useState(order.ORDER_RATING?parseInt(order.ORDER_RATING):0)
    const bitrix = useBitrix()
    const setRating = (val)=>{
        setValue(val)
        bitrix.sale.sendRating(val,order.ID)      
    }
    return (
        <div className="d-flex mt-2 mb-2">
            <Image src={value>0?starB:star} className="orders-rating" onClick={()=>setRating(1)}/>
            <Image src={value>1?starB:star} className="orders-rating" onClick={()=>setRating(2)}/>
            <Image src={value>2?starB:star} className="orders-rating" onClick={()=>setRating(3)}/>
            <Image src={value>3?starB:star} className="orders-rating" onClick={()=>setRating(4)}/>
            <Image src={value>4?starB:star} className="orders-rating" onClick={()=>setRating(5)}/>
        </div>
    )
}