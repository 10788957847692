import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../../BitrixProvider";
import { saleActions } from "../../../state/actions";

import { ResponsiveLayout } from "../../ResponsiveLayout";
import { OrderPromoD } from "./OrderPromoD";
import { OrderPromoM } from "./OrderPromoM";

import "./order-promo.css";

const OrderPromo = () => {
  const dispatch = useDispatch();
  const bitrix = useBitrix();
  const { t } = useTranslation();
  const {
    promo: { code: value, valid },
    delivery,
  } = useSelector((state) => state.sale.order);

  const [val, setVal] = useState("");
  const [err, setErr] = useState("");
  const promoCheck = async () => {
    if (val) {
      try {
        const promo = await bitrix.sale.isValidPromo(val);
        if (promo.status) {
          dispatch(saleActions.orderSetPromo({ code: val, valid: true }));
          dispatch(saleActions.orderSetDelivery({ ...delivery, price: 0 }));
        } else {
          setErr(t("INVALID_PROMOCODE"));
          dispatch(saleActions.orderSetPromo({ code: val, valid: false }));
        }
      } catch (error) {
        console.log(error);
        setErr(t("FEED_ERROR_MSG"));
        dispatch(saleActions.orderSetPromo({ code: val, valid: false }));
      }
    } else {
      dispatch(saleActions.orderSetPromo({ code: val, valid: false }));
    }
  };
  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <OrderPromoD
          val={val}
          change={(e) => {
            setErr("");
            setVal(e.target.value);
            if (e.target.value === "") {
              dispatch(saleActions.orderSetPromo({ code: "", valid: false }));
            }
          }}
          blurHandler={promoCheck}
          valid={value && valid}
          err={err}
        />
      )}
      renderMobile={() => (
        <OrderPromoM
          val={val}
          change={(e) => {
            setErr("");
            setVal(e.target.value);
            if (e.target.value === "") {
              dispatch(saleActions.orderSetPromo({ code: "", valid: false }));
            }
          }}
          blurHandler={promoCheck}
          valid={value && valid}
          err={err}
          promo={value}
        />
      )}
    />
  );
};
export default OrderPromo;
