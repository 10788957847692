import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { YMInitializer } from "react-yandex-metrika";
import { WindowDimensionsProvider } from "./WindowDimensionsProvider";
import { BitrixProvider } from "./BitrixProvider";
import { store } from "../state/store";

import "bootstrap/dist/css/bootstrap.min.css";

import { AppMainLayout } from "./AppMainLayout";

import { FireProvider } from "./FireProvider";
import { FbPixelProvider } from "./FbPixelProvider";
import { ErrorProvider } from "./ErrorProvider";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <BitrixProvider>
          <WindowDimensionsProvider>
            <FireProvider>
              <FbPixelProvider>
                <YMInitializer
                  accounts={[47567362]}
                  options={{ webvisor: true }}
                />
                <ErrorProvider>
                  <AppMainLayout />
                </ErrorProvider>
              </FbPixelProvider>
            </FireProvider>
          </WindowDimensionsProvider>
        </BitrixProvider>
      </Router>
    </Provider>
  );
}

export default App;
