import React from "react";
import { Form, Spinner } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { FaCalendarAlt, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const PersonalInfoFormD = ({ params }) => {
  const { t } = useTranslation();

  return (
    <Form className="personal-info-form d-flex justify-content-around pt-3 w-100">
      <div className="d-flex  flex-column" style={{ flex: 1 }}>
        <h3>{t("PERSONAL_DATA")}</h3>
        <div className="d-flex w-100 justify-content-around">
          <div className="d-flex flex-column" style={{ width: "48%" }}>
            <Form.Group controlId="name">
              <Form.Label>{t("NAME_LABEL")}</Form.Label>
              <Form.Control
                type="text"
                value={params.fields.name.val}
                onChange={(e) => params.changeField("name", e.target.value)}
                onBlur={() => params.validField("name")}
                isInvalid={params.fields.name.err}
                disabled={params.load}
              />
              <Form.Control.Feedback type="invalid">
                {params.fields.name.err}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="surname">
              <Form.Label>{t("SURNAME_LABEL")}</Form.Label>
              <Form.Control
                type="text"
                value={params.fields.surname.val}
                onChange={(e) => params.changeField("surname", e.target.value)}
                onBlur={() => params.validField("surname")}
                isInvalid={params.fields.surname.err}
                disabled={params.load}
              />
              <Form.Control.Feedback type="invalid">
                {params.fields.surname.err}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="d-flex flex-column" style={{ width: "48%" }}>
            <Form.Group>
              <Form.Label>{t("BIRTH_LABEL")}</Form.Label>
              <div>
                <DatePicker
                  locale="ru-RU"
                  value={params.fields.birth.val}
                  onChange={(d) => params.changeField("birth", d)}
                  format="d.M.yyyy"
                  className={
                    params.fields.birth.err
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  calendarIcon={<FaCalendarAlt color="#ced4da" />}
                  clearIcon={<FaTimes color="#ced4da" />}
                  disabled={params.load}
                  showLeadingZeros={false}
                />
              </div>
              {params.fields.birth.err ? (
                <div className="invalid-feedback d-block">
                  {params.fields.birth.err}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("SEX_LABEL")}</Form.Label>
              <div className="d-flex justify-content-between w-50">
                <Form.Check
                  type="radio"
                  id="sex-M"
                  name="sex"
                  className="cust-radio"
                  checked={params.fields.sex.val === "M"}
                  onChange={() => params.changeField("sex", "M")}
                  disabled={params.load}
                  label={t("MAN")}
                  custom
                />
                <Form.Check
                  type="radio"
                  id="sex-F"
                  name="sex"
                  className="cust-radio"
                  checked={params.fields.sex.val === "F"}
                  onChange={() => params.changeField("sex", "F")}
                  disabled={params.load}
                  label={t("WOMAN")}
                  custom
                />
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column w-33 position-relative">
        <h3>{t("CONTACTS_DATA")}</h3>
        <Form.Group controlId="phone">
          <Form.Label>{t("PHONE_LABEL")}</Form.Label>
          <Form.Control
            type="text"
            value={params.fields.phone.val}
            onChange={(e) => params.changeField("phone", e.target.value)}
            onBlur={() => params.validField("phone")}
            isInvalid={params.fields.phone.err}
            disabled={params.load}
          />
          <Form.Control.Feedback type="invalid">
            {params.fields.phone.err}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>{t("EMAIL_LABEL")}</Form.Label>
          <Form.Control
            type="text"
            value={params.fields.email.val}
            onChange={(e) => params.changeField("email", e.target.value)}
            onBlur={() => params.validField("email")}
            isInvalid={params.fields.email.err}
            disabled={params.load}
          />
          <Form.Control.Feedback type="invalid">
            {params.fields.email.err}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="d-flex justify-content-center align-items-center personal-info-form-action">
          <button
            type="button"
            className="btn btn-success mr-2"
            onClick={params.saveFields}
            disabled={params.load}
          >
            {params.load ? <Spinner animation="border" size="sm" /> : t("SAVE")}
          </button>
          {params.status}
        </div>
      </div>
    </Form>
  );
};
