import React, { useState, Fragment } from "react";
import { FaCommentAlt, FaAngleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { OrderCommentD } from "./OrderCommentD";

export const OrderCommentM = ({ params }) => {
  const { t } = useTranslation();

  const [show, toggle] = useState(false);

  let classWrap = "d-flex position-relative order-panel-button order-comment";

  if (params.comment) {
    classWrap += " valid";
  }
  return (
    <Fragment>
      <div className={classWrap}>
        <div className="w-25 d-flex justify-content-center align-items-center  position-relative">
          <FaCommentAlt className="order-panel-button-icon" />
        </div>
        <div
          className="w-75 d-flex align-items-center order-panel-button-action pt-2 pb-2 pl-4 position-relative"
          onClick={() => toggle(true)}
        >
          <div className="d-flex flex-column">
            {params.comment ? (
              <div className="d-flex flex-column order-comment-body">
                <small>{params.comment}</small>
              </div>
            ) : (
              <small>{t("ORDER_COMMENT")}</small>
            )}
          </div>
          <FaAngleRight className="order-panel-button-right-icon" />
        </div>
      </div>
      {show ? (
        <div className="modal-panel-wrap d-flex justify-content-center  align-items-start">
          <div className="modal-panel">
            <OrderCommentD params={params} />
            <button
              className="btn btn-sm btn-block btn-success mt-3"
              onClick={() => toggle(false)}
            >
              {t("SAVE")}
            </button>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
