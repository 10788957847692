import React, { Fragment, useState } from "react";
import { FaClock, FaAngleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { OrderTimeModal } from "./OrderTimeModal";

export const OrderTimeM = ({ params }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  let classWrap = "d-flex position-relative order-panel-button";

  const save = (t) => {
    params.saveDateTime(t);
    setShow(false);
  };
  if (params.valid) {
    classWrap += " valid";
  }

  return (
    <Fragment>
      <div className={classWrap}>
        <div className="w-25 d-flex justify-content-center align-items-center order-panel-button-icon-wrap position-relative">
          <FaClock className="order-panel-button-icon" />
        </div>
        <div
          className="w-75 d-flex align-items-center order-panel-button-action pt-2 pb-2 pl-4 position-relative"
          onClick={() => setShow(true)}
        >
          <div className="d-flex flex-column">
            {params.dataTime ? (
              <div className="d-flex flex-column">
                <strong>{t("TIME_DELIVERY")}</strong>
                <small>{params.dataTime}</small>
              </div>
            ) : (
              <small>{t("TIME_DELIVERY")}</small>
            )}
          </div>
          <FaAngleRight className="order-panel-button-right-icon" />
        </div>
      </div>
      {show ? (
        <OrderTimeModal
          params={params}
          save={save}
          close={() => setShow(false)}
        />
      ) : null}
    </Fragment>
  );
};
