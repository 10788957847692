import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight, FaArrowLeft } from "react-icons/fa";
export const CatalogCategoriesM = ({ list, base }) => {
  const path = list["SECTION_PAGE_URL"]
    .replace("/catalog", "")
    .slice(1, -1)
    .split("/");

  const returnLink =
    path.length > 1
      ? list["SECTION_PAGE_URL"].replace(/[a-z0-9_]*\/$/, "")
      : "/";

  return (
    <main className="catalog-categories-mobil d-flex flex-column">
      <Link to={returnLink} className="catalog-categories-mobil-return">
        <FaArrowLeft
          color="rgba(95, 95, 95, 0.137)"
          className="catalog-categories-mobil-left-icon"
        />
        <h4>{list["NAME"]}</h4>
      </Link>
      <div className="catalog-categories-mobil-list">
        {Object.keys(list["CHILD"]).map((key) => {
          const linkUrl = list["CHILD"][key]["SECTION_PAGE_URL"].replace(
            /^\/[a-z]*/g,
            "/" + base
          );
          return (
            <Link
              to={linkUrl}
              key={key}
              className="catalog-categories-mobil-list-item"
            >
              <span>{list["CHILD"][key]["NAME"]}</span>
              <FaAngleRight
                color="rgba(95, 95, 95, 0.137)"
                className="catalog-categories-mobil-right-icon"
              />
            </Link>
          );
        })}
      </div>
    </main>
  );
};
