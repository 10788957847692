import React from "react";
import { FaMapMarkedAlt, FaUniversity, FaFax, FaAt } from "react-icons/fa";
import { YMaps, Map, ZoomControl, Placemark } from "react-yandex-maps";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../Breadcrumbs";
import FeedbackForm from "../../FeedbackForm";

export const RuPage = () => {
  const { t } = useTranslation();

  return (
    <div className="contacts-wrap mb-3">
      <Breadcrumbs />
      <h1>{t("CONTACTS")}</h1>
      <div className="contacts-line">
        <div className="bg-white p-lg-3 d-flex flex-column">
          <h2>{t("STATIC_CONTACTS_SUNNY")}</h2>
          <div className="d-flex align-items-center mt-3">
            <FaMapMarkedAlt color="#8cc83c" className="mr-2" size={30} />
            <span>{t("STATIC_CONTACTS_ADDRESS")}</span>
          </div>
          <div className="d-flex align-items-center mt-3">
            <FaUniversity color="#8cc83c" className="mr-2" size={35} />
            <span>{t("STATIC_CONTACTS_REQUISITES")}</span>
          </div>
          <div className="d-flex align-items-center mt-3">
            <FaFax color="#8cc83c" className="mr-2" size={30} />
            <span>{t("STATIC_CONTACTS_PHONE")}8 (7142) 93-22-55</span>
          </div>
          <div className="d-flex align-items-center mt-3">
            <FaAt color="#8cc83c" className="mr-2" size={30} />
            <span>info@sunny.kz</span>
          </div>
          <hr className="w-100" />
          <FeedbackForm />
        </div>
        <YMaps
          query={{
            lang: "ru_RU",
            apikey: "c5af4455-6b73-439c-ad0a-f2f40be2ff58",
          }}
        >
          <Map
            className="contacts-map"
            defaultState={{ center: [53.185019, 63.58428], zoom: 16 }}
          >
            <ZoomControl />
            <Placemark
              geometry={[53.185019, 63.586274]}
              properties={{ iconContent: 'ТОО "Супермаркет "Солнечный"' }}
              options={{ preset: "islands#greenStretchyIcon" }}
            />
          </Map>
        </YMaps>
      </div>
    </div>
  );
};
