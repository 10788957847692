import updateCatalog from './catalog'
import updateSale from './sale'
import updateSystem from './system'
import updateUser from './user'

const reducer = (state,action)=>{
    return{
        catalog:updateCatalog(state,action),
        sale:updateSale(state,action),
        system:updateSystem(state,action),
        user:updateUser(state,action)
    }
}

export default reducer