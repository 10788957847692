import React from 'react';
import ShippingAndPayment from './ShippingAndPayment'
import Contacts from './Contacts'
import Supermarkets from './Supermarkets'
import Contract from './Contract'
import NoPage from './NoPage';
const StaticPage = ({page='404',lang='ru'})=>{
    switch(page){
        case 'shipping_and_payment':
            return <ShippingAndPayment lang={lang}/>
        case 'contacts':
            return <Contacts lang={lang}/>
        case 'supermarkets':
            return <Supermarkets lang={lang}/>
        case 'contract':
            return <Contract lang={lang}/>
        default:
            return <NoPage lang={lang}/>
    }
}
export default StaticPage