import React from 'react';
import {Image} from 'react-bootstrap'
import { Link } from 'react-router-dom';

import logoImg from './logo-m.png'

export const LogoMob = ()=>{
    return (
        <div>
            <Link to="/">
                <Image src={logoImg} alt="Logo" width={140}/>
            </Link>            
        </div>
    )
}