import React from "react";

import "./qr-code-banner.css";
import banner from "./banner.png";

const QrCodeBanner = () => {
  return (
    <div className="qr-container">
      <img src={banner} className="qr-banner" alt="QR-код" />
    </div>
  );
};

export default QrCodeBanner;
