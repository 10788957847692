import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import noImg from "./error1.png";

const ErrorIndicator = ({ title = "", text = "" }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center align-items-center no-page">
      <div className="d-flex flex-column align-items-center">
        <Image src={noImg} />
        <h1 className="mt-3">{title}</h1>
        <span className="text-center">{text}</span>
        <Link to="/" className="btn btn-success mt-4">
          {t("GO_HOME_PAGE")}
        </Link>
      </div>
    </div>
  );
};
export default ErrorIndicator;
