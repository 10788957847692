import React from "react";
import { useTranslation } from "react-i18next";

import { AddressMap } from "./AddressMap";
import { PersonalAddressItem } from "./PersonalAddressItem";

export const PersonalAddressD = ({ address, params }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex p-3 personal-address">
      <div className="w-50">
        <AddressMap
          ymaps={params.ymap}
          saveAddress={params.addAddress}
          addressList={address}
        />
      </div>
      <div className="w-50 pl-3">
        <h3>{t("ADDRESSES_LIST")}</h3>
        <div className="d-flex flex-column">
          {address.map((item, id) => (
            <PersonalAddressItem
              address={item}
              key={"address" + id}
              delAddress={() => params.delAddress(item)}
            />
          ))}
          {address.length === 0 ? (
            <strong className="text-center">{t("EMPTY_LIST")}</strong>
          ) : null}
          <div className="mt-2 d-flex">
            <small>*{t("PERSONAL_ADDRESS_MSG_DESC")}</small>
          </div>
        </div>
      </div>
    </div>
  );
};
