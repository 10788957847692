const updateUser = (state,action)=>{
    if(state===undefined){
        return{
            id:false,
            name:'',
            login:'',
            favorite:[],
            address:[],
            bonuscard:'',
            phone:'',
            bonuscard2:''
        }
    }
    switch(action.type){
        case 'UPD_USER_ID':
            return{
                ...state.user,
                id:action.payload
            }
        case 'UPD_USER_NAME':
            return{
                ...state.user,
                name:action.payload
            }
        case 'UPD_USER_LOGIN':
            return{
                ...state.user,
                login:action.payload
            }
        case 'USER_LOGOUT':
            return{
                ...state.user,
                id:false,
                name:'',
                login:'',
                favorite:[],
                address:[]
            }
        case 'UPD_ADDRESS':
            return{
                ...state.user,
                address:action.payload 
            }
        case 'UPD_FAVORITE':
            return{
                ...state.user,
                favorite:action.payload
            }
        case 'BONUS_CARD_UPD':
            return{
                ...state.user,
                bonuscard:action.payload
            }
        case 'SET_USER':
            return {
                ...state.user,
                ...action.payload
            }
        default:
            return state.user
    }
}
export default updateUser