import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useWindowDimensions } from "./WindowDimensionsProvider";
import { catalogActions, systemActions } from "../state/actions";
import { CatalogDetailPage } from "../screens/CatalogDetailPage";
import { CatalogSectionPage } from "../screens/CatalogSectionPage";
import CatalogCategories from "./CatalogCategories";
import { DelFavoriteButton } from "./PersonalFavorite/DelFavoriteButton";
import { FavoriteButton } from "./PersonalFavorite/FavoriteButton";
import { useFireAnalitic } from "./FireProvider";

export const CatalogRouter = () => {
  const { t, i18n } = useTranslation();
  const analist = useFireAnalitic();
  const { categories } = useSelector((state) => state.catalog.categories);
  const { favorite } = useSelector((state) => state.user);
  const dispath = useDispatch();
  const { isMobil } = useWindowDimensions();
  const { pathname } = useLocation();

  const keys = /\/$/.test(pathname)
    ? pathname.slice(1, -1).split("/")
    : pathname.slice(1).split("/");
  if (Object.keys(categories).length > 0) {
    if (keys[0] === "catalog" || keys[0] === "discounts") {
      const view = keys[0];
      keys.shift();
      const elem = keys.reduce((prev, curr) => {
        if (typeof prev === "object" && prev[curr]) return prev[curr];
        if (typeof prev === "object" && prev["CHILD"][curr] !== undefined)
          return prev["CHILD"][curr];
        return curr;
      }, categories);
      if (typeof elem === "string") return <CatalogDetailPage id={elem} />;
      if (typeof elem === "object" && isMobil && elem["CHILD"])
        return <CatalogCategories />;

      dispath(catalogActions.main.settigs.sectChange(elem["CODE"]));
      if (view === "catalog") dispath(systemActions.updateTitle(elem["NAME"]));
      if (view === "discounts")
        dispath(
          catalogActions.main.settigs.setFilterDef(
            "filterDef[discounts]=Товар со скидкой&"
          )
        );

      if (elem.LANG) {
        return (
          <CatalogSectionPage
            name={elem.LANG[i18n.resolvedLanguage]}
            header={(id) => <FavoriteButton prodId={id} />}
          />
        );
      }

      return (
        <CatalogSectionPage header={(id) => <FavoriteButton prodId={id} />} />
      );
    }

    if (keys[0] === "newspaper") {
      dispath(catalogActions.main.resetMain());
      dispath(
        catalogActions.main.settigs.setFilterDef("filterDef[newspaper]=Да&")
      );
      return (
        <CatalogSectionPage
          name={t("SUN_NEWSPAPER")}
          header={(id) => <FavoriteButton prodId={id} />}
        />
      );
    }
    if (keys[0] === "banners" || keys[0] === "baners" || keys[0] === "banner") {
      dispath(catalogActions.main.resetMain());
      dispath(
        catalogActions.main.settigs.setFilterDef("filterDef[productMonth]=Да&")
      );
      return (
        <CatalogSectionPage
          name={t("PROD_MONTH")}
          header={(id) => <FavoriteButton prodId={id} />}
        />
      );
    }
    if (keys[0] === "brands") {
      dispath(catalogActions.main.resetMain());
      dispath(catalogActions.main.settigs.setBrands(keys[1]));
      return (
        <CatalogSectionPage
          name={t("BRANDS")}
          header={(id) => <FavoriteButton prodId={id} />}
        />
      );
    }
    if (keys[0] === "search") {
      dispath(catalogActions.main.settigs.setSeach(keys[1]));
      if (analist) analist.logEvent("search", { search_term: keys[1] });
      return (
        <CatalogSectionPage
          name={t("SEARCH")}
          header={(id) => <FavoriteButton prodId={id} />}
        />
      );
    }
    if (keys[0] === "personal" && keys[1] === "favorite") {
      if (Array.isArray(favorite) && favorite.length > 0) {
        let list = "";
        favorite.forEach((item) => (list += `list[]=${item}&`));
        dispath(catalogActions.main.resetMain());
        dispath(catalogActions.main.settigs.setFilterDef(list));
      }
      return (
        <CatalogSectionPage
          name={t("FAVORITES_PROD")}
          header={(id) => <DelFavoriteButton prodId={id} />}
        />
      );
    }
  }
  return <Fragment />;
};
