import React from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { AppDatePicker } from "./AppDatePicker";
import { AppTimePicker } from "./AppTimePicker";

export const OrderTimeModal = ({ params, save, close }) => {
  const { t } = useTranslation();

  const getBody = () => {
    if (params.point) {
      if (Object.keys(params.times).length > 0) {
        return (
          <div className="d-flex flex-column">
            <h6>{t("DATA_LABEL")}</h6>
            <div className="order-time-modal">
              <AppDatePicker params={params} />
              <AppTimePicker param={params} save={save} close={close} />
            </div>
          </div>
        );
      } else {
        return (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="success" />
          </div>
        );
      }
    } else {
      return <strong>{t("TIME_WARNING")}</strong>;
    }
  };
  return (
    <div className="order-time-modal-wrap">
      <div className="order-time-modal-panel">
        {getBody()}
        <span className="order-time-modal-close" onClick={close}>
          &times;
        </span>
      </div>
    </div>
  );
};
