export default class User {
  //Получение информации о пользователе
  getUserProfile() {
    return {
      method: "GET",
      url: "users",
    };
  }
  //Авторизация, регистрация пользователей
  auth(data = {}) {
    return {
      method: "POST",
      url: "users/auth",
      data,
    };
  }
  //Обновление информации о пользователе
  updateUser(data = {}) {
    return {
      method: "PUT",
      url: "users",
      data,
    };
  }
  //Выход пользователя
  logout() {
    return {
      method: "POST",
      url: "users/logout",
    };
  }
  //Проверка находится ли телефон пользователя в черном списке в черном списке
  isPhoneBaned(phone) {
    return {
      method: "GET",
      url: "users/ban",
      data: {
        phone,
      },
    };
  }
  //Проверка телефона
  validPhone(data) {
    return {
      method: "GET",
      url: "users/phone",
      data,
    };
  }
}
