import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { YMaps, withYMaps } from "react-yandex-maps";
import { useTranslation } from "react-i18next";

import YmapsService from "../../../services/ymaps/ymaps-service";

import { useBitrix } from "../../BitrixProvider";
import { saleActions, userActions } from "../../../state/actions";

import { ResponsiveLayout } from "../../ResponsiveLayout";
import { OrderDeliveryD } from "./OrderDeliveryD";
import { OrderDeliveryM } from "./OrderDeliveryM";

import "./sale-order-delivery.css";

const OrderDelivery = ({ ymaps }) => {
  const { t, i18n } = useTranslation();
  const dispath = useDispatch();

  const [msg, setMsg] = useState("");

  const {
    order: {
      delivery: { type, address, price, valid, coord, point },
    },
  } = useSelector((state) => state.sale);
  const { address: userAddress } = useSelector((state) => state.user);
  const ymap = new YmapsService(ymaps);
  const bitrix = useBitrix();

  const changeType = (id) => {
    let delivery = {};
    if (id === 3) {
      delivery = {
        type: 3,
        // address: 'Гипермаркет "Солнечный"',
        address: "улица Карбышева, 85",
        price: 0,
        valid: true,
        // coord: "53.202237, 63.660460",
        coord: "53.185066, 63.586430",
        // point: "g1",
        point: "k85",
      };
    }
    if (id === 4) {
      delivery = {
        type: 4,
        address: "",
        price: 0,
        valid: false,
        coord: "",
        point: "",
      };
    }
    dispath(saleActions.orderSetDelivery(delivery));
  };

  const setAddress = async (addressInfo, address = "") => {
    let priceInfo = {};
    try {
      priceInfo = await bitrix.sale.getPriceDelivery(
        addressInfo.dist,
        addressInfo.point
      );
      if (priceInfo.error) {
        priceInfo.msg = priceInfo.error;
        priceInfo.valid = false;
      } else {
        priceInfo.valid = true;
      }
    } catch (error) {}
    const delivery = {
      type: 4,
      address: address,
      price: priceInfo.price,
      valid: priceInfo.valid,
      coord: addressInfo.coord,
      point: addressInfo.point,
    };
    dispath(saleActions.orderSetDelivery(delivery));
    if (!userAddress.includes(address))
      dispath(userActions.setAddress([...userAddress, address]));
    if (priceInfo.msg) {
      setMsg(priceInfo.lang[i18n.resolvedLanguage] || priceInfo.msg);
    }
  };
  return (
    <Fragment>
      <ResponsiveLayout
        renderDesktop={() => (
          <OrderDeliveryD
            params={{
              type,
              address,
              price,
              valid,
              coord,
              point,
              changeType,
              userAddress,
              setAddress,
              ymaps: ymap,
              bitrix,
            }}
          />
        )}
        renderMobile={() => (
          <OrderDeliveryM
            params={{
              type,
              address,
              price,
              valid,
              coord,
              point,
              changeType,
              userAddress,
              setAddress,
              ymaps: ymap,
            }}
          />
        )}
      />
      {msg ? (
        <Msg
          text={msg}
          close={() => setMsg("")}
          title={t("PRICE_DELIVERY_LABEL")}
        />
      ) : null}
    </Fragment>
  );
};
const Msg = ({ text, close, title = "" }) => {
  return (
    <div className="order-delivery-msg-wrap">
      <div className="order-delivery-msg">
        {title ? <h3>{title}</h3> : null}
        <p className="text-center">{text}</p>
        <span className="order-delivery-msg-close" onClick={close}>
          &times;
        </span>
      </div>
    </div>
  );
};

const OrderDeliveryWithYMaps = () => {
  const OrderDeliveryYMapsWrap = React.useMemo(() => {
    return withYMaps(OrderDelivery, false, ["route", "geocode", "geoQuery"]);
  }, []);
  return (
    <YMaps
      query={{ lang: "ru_RU", apikey: "c5af4455-6b73-439c-ad0a-f2f40be2ff58" }}
    >
      {" "}
      <OrderDeliveryYMapsWrap />
    </YMaps>
  );
};
export default OrderDeliveryWithYMaps;
