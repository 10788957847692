import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {ResponsiveLayout} from '../../ResponsiveLayout'
import { OrderCommentD } from './OrderCommentD';
import { OrderCommentM } from './OrderCommentM';
import {saleActions} from '../../../state/actions'
import './order-comment.css'
const OrderComment = ()=>{
    const {comment} = useSelector(state=>state.sale.order)
    const dispath = useDispatch()
    const setComment = text=>{
        dispath(saleActions.orderSetComment(text))
    }
    return (
        <ResponsiveLayout
            renderDesktop={()=><OrderCommentD params={{comment,setComment}}/>}
            renderMobile={()=><OrderCommentM params={{comment,setComment}}/>}
        />
    )
}
export default OrderComment