const updateCatalog = (state,action)=>{
    if(state === undefined){
        return{
            main:{
                error:null,
                settings:{
                    filter:'',
                    limit:16,
                    page:1,
                    sort:'',
                    pagesNav:[],
                    sect:'',
                    typeReq:'new',
                    searchList:'',
                    filterDef:'',
                    brands:''
                },
                filters:false
            },            
            categories:{
                categories:{},
                loading:true,
                error:null
            },
            search:{
                list:[],
                input:''
            }            
        }
    }
    switch(action.type){
        case 'MAIN_REQ':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    loading:action.payload,
                }
            }
        case 'MAIN_LOAD':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    products:action.payload,
                    loading:false                                       
                }
            }
        case 'MAIN_LOADELEM':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    elem:action.payload,
                    loading:false                                       
                }
            }
        case 'MAIN_ERR':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    loading:false,
                    error:action.payload
                }
            } 
        case 'MAIN_LOAD_VIEW':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    loading:false,
                    view:action.payload
                } 
            }
        case 'MAIN_SETTING_SECT_CHANGE':                    
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    settings:{
                        filter:'',
                        limit:16,
                        page:1,
                        sort:'',
                        pagesNav:[],
                        sect:action.payload,
                        typeReq:'new',
                        searchList:'',
                        filterDef:'',
                        brands:''
                    }
                }
            } 
        case 'MAIN_NAME':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    name:action.payload 
                }
            } 
        case 'MAIN_FILTERS':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    filters:action.payload 
                }
            } 
        case 'MAIN_SETTINGS_PAGE_CHANGE':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    settings:{
                        ...state.catalog.main.settings,
                        page:action.payload
                    }
                }
            }     
        case 'MAIN_SETTINGS_SET_PAGES':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    settings:{
                        ...state.catalog.main.settings,
                        pagesNav:action.payload
                    }
                }
            } 
        case 'MAIN_SETTINGS_SET_FILTERDEF':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    settings:{
                        ...state.catalog.main.settings,
                        filterDef:action.payload
                    }
                }
            }
        case 'MAIN_SETTING_SORT_CAHNGE':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    settings:{
                        ...state.catalog.main.settings,
                        sort:action.payload
                    }
                } 
            }
        case 'MAIN_SETTING_FILTER_CAHNGE':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    settings:{
                        ...state.catalog.main.settings,
                        filter:action.payload
                    }
                } 
            }
        case 'MAIN_SETTING_TYPE_CAHNGE':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    settings:{
                        ...state.catalog.main.settings,
                        typeReq:action.payload
                    }
                } 
            }
        case 'MAIN_SETTING_SEARCH_CAHNGE':            
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    settings:{
                        filter:'',
                        limit:16,
                        page:1,
                        sort:'',
                        pagesNav:[],
                        sect:'',
                        typeReq:'new',
                        searchList:action.payload,
                        filterDef:'',
                        brands:''                       
                    }
                } 
            }
        case 'CATEGORIES_REQ':
            return{
                ...state.catalog,
                categories:{
                    categories:[],
                    loading:true,
                    error:null
                }
            }
        case 'CATEGORIES_LOAD':
            return{
                ...state.catalog,
                categories:{
                    categories:action.payload,
                    loading:false,
                    error:null
                }
            }
        case 'CATEGORIES_ERR':
            return{
                ...state.catalog,
                categories:{
                    categories:[],
                    loading:false,
                    error:action.payload
                }
            }
        case 'SEARCH_INPUT_CHANGE':
            return {
                ...state.catalog,
                search:{
                    ...state.catalog.search,
                    input:action.payload
                }
            }
        case 'SEARCH_LIST_CHANGE':
            return {
                ...state.catalog,
                search:{
                    ...state.catalog.search,
                    list:action.payload
                } 
            } 
        case 'MAIN_RESET':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    error:null,
                    settings:{
                        filter:'',
                        limit:12,
                        page:1,
                        sort:'',
                        pagesNav:[],
                        sect:'',
                        typeReq:'new',
                        searchList:'',
                        filterDef:''
                    }
                }
            } 
        case 'MAIN_SETTINGS_SET_BRAND':
            return{
                ...state.catalog,
                main:{
                    ...state.catalog.main,
                    settings:{
                        filter:'',
                        limit:16,
                        page:1,
                        sort:'',
                        pagesNav:[],
                        sect:'',
                        typeReq:'new',
                        searchList:'',
                        filterDef:'',
                        brands:action.payload
                    }
                }
            }      
        default:
            return state.catalog
    }
}
export default updateCatalog