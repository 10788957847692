import React, { useState, Fragment } from "react";
import { FaBarcode, FaAngleRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { OrderPromoD } from "./OrderPromoD";

export const OrderPromoM = ({
  val,
  change,
  blurHandler,
  valid,
  err = "",
  promo,
}) => {
  const { t } = useTranslation();

  const [show, toggle] = useState(false);

  let classWrap = "d-flex position-relative order-panel-button order-comment";

  if (valid) {
    classWrap += " valid";
  }
  if (err) {
    classWrap += " invalid";
  }
  return (
    <Fragment>
      <div className={classWrap}>
        <div className="w-25 d-flex justify-content-center order-panel-button-icon-wrap align-items-center  position-relative">
          <FaBarcode className="order-panel-button-icon" />
        </div>
        <div
          className="w-75 d-flex align-items-center order-panel-button-action pt-2 pb-2 pl-4 position-relative"
          onClick={() => toggle(true)}
        >
          <div className="d-flex flex-column">
            {promo ? (
              <div className="d-flex flex-column order-comment-body">
                <strong>{t("PROMOCODE")}</strong>
                <small>{promo}</small>
              </div>
            ) : (
              <small>{t("PROMOCODE")}</small>
            )}
          </div>
          <FaAngleRight className="order-panel-button-right-icon" />
        </div>
      </div>
      {show ? (
        <div className="modal-panel-wrap d-flex justify-content-center  align-items-start">
          <div className="modal-panel">
            <OrderPromoD
              val={val}
              change={change}
              blurHandler={blurHandler}
              valid={valid}
              err={err}
            />
            <button
              className="btn btn-sm btn-block btn-success mt-3"
              onClick={() => toggle(false)}
            >
              {t("SAVE")}
            </button>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
