import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import SaleButton from "../SaleButton";
import { Image } from "react-bootstrap";
import noImg from "./no-img1.jpg";
import { FavoriteButton } from "../PersonalFavorite/FavoriteButton";
import { ProdProperty } from "./ProdProperty";
import { BlurbCatalogMoreCategory } from "../blurb-catalog-more-category/BlurbCatalogMoreCategory";
// import { PriceInfo } from "./PriceInfo";

export const CatalogDetailD = ({ prod }) => {
  const { t, i18n } = useTranslation();

  const picture = prod.DETAIL_PICTURE
    ? "https://" + prod.DETAIL_PICTURE
    : noImg;

  const discount =
    parseInt(prod.OLD_PRICE) > 0
      ? Math.round(
          ((parseInt(prod.OLD_PRICE) - parseInt(prod.PRICE)) /
            parseInt(prod.OLD_PRICE)) *
            100
        )
      : 0;

  const units = {
    шт: t("UNITS_PIECES"),
    "л.": t("UNITS_LITERS"),
    г: t("UNITS_GRAMMS"),
    кг: t("UNITS_KILOGRAMMS"),
  };

  return (
    <Fragment>
      <div className="catalog-detail-wrap">
        <div className="catalog-detail-img-wrap p-3 d-flex justify-content-center position-relative">
          <FavoriteButton prodId={prod.ID} />
          <Image src={picture} className="prew-img" />
        </div>
        <div className="catalog-detail-info pt-3">
          <h2>{prod.LANG[i18n.resolvedLanguage]}</h2>
          {prod.PROPERTY_AVERAGE_WEIGHT_VALUE ? (
            <div className="catalog-detail-averege-weight">
              <span>
                {t("AVERAGE_WEIGHT", {
                  weight: prod.PROPERTY_AVERAGE_WEIGHT_VALUE,
                })}
              </span>
            </div>
          ) : null}
          <div className="position-relative">
            <div className="catalog-detail-sale-button pr-5">
              <SaleButton prod={prod} />
            </div>
          </div>
          <div className="prices d-flex justify-content-start pr-5">
            {parseInt(prod.OLD_PRICE) ? (
              <div className="d-flex flex-column old-price-wrap">
                <div className="d-flex align-items-center">
                  <span className="catalog-detail-item-old-price">
                    {parseInt(prod.OLD_PRICE) + " " + t("TENGE")}
                  </span>
                  <span className="catalog-detail-item-old-price-label">
                    -{discount}%
                  </span>
                </div>
                <div className="catalog-detail-item-price-wrap">
                  <span className="catalog-detail-item-price">
                    {parseInt(prod.PRICE) + " " + t("TENGE")}/
                    {units[prod.PROPERTY_CML2_BASE_UNIT_VALUE]}
                  </span>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-end">
                <span className="catalog-detail-item-price">
                  {parseInt(prod.PRICE) + " " + t("TENGE")}/
                  {units[prod.PROPERTY_CML2_BASE_UNIT_VALUE]}
                </span>
              </div>
            )}
            {/* <div className="d-flex align-items-end ml-2 mb-1">
              <PriceInfo />
            </div> */}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: prod.DETAIL_TEXT }}
            className="catalog-detail-info-text mt-3"
          />
          <div className="catalog-detail-properties mt-2 mb-2">
            <ProdProperty properties={prod.PROPERTY} />
          </div>
        </div>
      </div>
      <BlurbCatalogMoreCategory category={prod.IBLOCK_SECTION_ID} size={12} />
    </Fragment>
  );
};
