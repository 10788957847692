import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Form, FormControl, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ym from "react-yandex-metrika";
import { useFireAnalitic } from "../FireProvider";
import { usePixel } from "../FbPixelProvider";

import { useBitrix } from "../BitrixProvider";
import { saleActions } from "../../state/actions";

import AppSpinner from "../AppSpinner";
import { ResponsiveLayout } from "../ResponsiveLayout";
import { SaleOrderD } from "./SaleOrderD";
import { SaleOrderM } from "./SaleOrderM";
import OrderEditSave from "./OrderEditSave";

import "./sale-order.css";

const SaleOrder = () => {
  const analist = useFireAnalitic();
  const pixel = usePixel();
  const { t } = useTranslation();
  const bitrix = useBitrix();
  const {
    total,
    basket,
    order: {
      personal: { name, phone, valid: conValid, ban },
      delivery,
      time: { dataTime, valid: timeValid },
      payment: { type, valid: payValid, text: pText },
      comment,
      bonus: { value: bValue, valid: bValid },
      promo: { code: pValue, valid: pValid },
      kaspi: { number: kNumber, valid: kValid },
    },
    orderId,
  } = useSelector((state) => state.sale);
  const { id: user, login } = useSelector((state) => state.user);
  const dispath = useDispatch();

  const [err, setErr] = useState([]);
  const [modal, showModal] = useState(null);
  const [haveOrder, setHaveOrder] = useState(null);

  const orderSend = async () => {
    const basketList = basket.map((item) => ({
      item_id: item.PRODUCT_ID,
      item_name: item.NAME,
      quantity: item.QUANTITY,
      price: parseInt(item.PRICE),
      currency: "KZT",
    }));
    const basketForPixel = basket.map((item) => ({
      id: item.PRODUCT_ID,
      quantity: item.QUANTITY,
    }));
    const order = {
      FIO: name,
      PHONE: phone,
      DATA_AND_TIME: dataTime,
      ADDRESS: delivery.address,
      PRICE_DELIVERY: delivery.price,
      PAYSYSTEM: type,
      COMMENT: comment,
      SYSTEM_COMMENTS: delivery.coord + " DW",
      DELIVERY_POINT: delivery.point,
      SHIPMENT: delivery.type,
    };
    if (bValue > 0 && bValid) order["BONUS_QUANTITY"] = bValue;
    if (pValue) order["PROMO"] = pValue;
    if (kNumber && kValid) {
      if (type === 9) order["KASPI_NUMBER"] = kNumber;
      if (type === 11) order["PAYMENT_CARD_ID"] = kNumber;
    }
    if (pText) order["SYSTEM_COMMENTS"] += " Платежные реквизиты: " + pText;
    showModal(<SaleOrderModal type="spinner" />);
    bitrix.sale.addOrder(order).then((data) => {
      if (data.ID) {
        if (analist)
          analist.logEvent("purchase", {
            currency: "KZT",
            items: basketList,
            transaction_id: data.NUMBER,
            shipping: delivery.price,
            value: delivery.price + total,
          });
        if (ym) ym("reachGoal", "order-make");
        if (pixel) {
          pixel.track("Purchase", {
            currency: "KZT",
            value: delivery.price + total,
            content_type: "product",
            contents: basketForPixel,
          });
        }
        dispath(saleActions.updateBasket([]));
        dispath(saleActions.orderReset());
        showModal(<SaleOrderModal type="order" order={{ ...data, user }} />);
      }
      if (data.error) {
        showModal(
          <SaleOrderModal
            type="error"
            msg={data.error}
            close={() => showModal(null)}
          />
        );
      }
    });
  };
  const orderSave = async () => {
    let isValid = true;
    const errorList = [];
    if (!conValid) {
      isValid = false;
      errorList.push("contacts");
    }
    if (!delivery.valid) {
      isValid = false;
      errorList.push("delivery");
    }
    if (!timeValid) {
      isValid = false;
      errorList.push("dataTime");
    }
    if (!payValid) {
      isValid = false;
      errorList.push("payment");
    }
    if (bValue && !bValid) {
      isValid = false;
      errorList.push("bonus");
    }
    if (pValue && !pValid) {
      isValid = false;
      errorList.push("promo");
    }
    if (pValue && pValid) {
      try {
        const promo = await bitrix.sale.isValidPromo(pValue);
        if (!promo.status) {
          isValid = false;
          errorList.push("promo");
        }
      } catch (error) {
        isValid = false;
        errorList.push("promo");
      }
    }
    if (type === 9 || type === 11) {
      if (kNumber && kValid) {
        try {
          const response = await bitrix.sale.checkPhoneInKaspi(kNumber);
          if (response.status === "error") {
            isValid = false;
            errorList.push("kaspiNumber");
          }
        } catch (error) {
          isValid = false;
          errorList.push("kaspiNumber");
        }
      } else {
        isValid = false;
        errorList.push("kaspiNumber");
      }
    }
    setErr(errorList);
    if (isValid) {
      if (!ban) {
        if (login) {
          orderSend();
        } else {
          showModal(
            <SaleOrderModal
              type="phone"
              order={{ phone, save: orderSend }}
              close={() => showModal(null)}
            />
          );
        }
      } else {
        showModal(
          <SaleOrderModal
            type="error"
            msg={t("SERVICE_NOT_FOUND")}
            close={() => showModal(null)}
          />
        );
      }
    } else {
      //Обработать ошибку
      showModal(
        <SaleOrderModal
          type="error"
          msg={t("ORDER_ERROR")}
          close={() => showModal(null)}
        />
      );
    }
  };

  useEffect(() => {
    if (!orderId) dispath(saleActions.orderDeliveryReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);
  useEffect(() => {
    if (!orderId) {
      dispath(saleActions.orderDeliveryReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, orderId]);
  useEffect(
    (e) => {
      setErr([...err.filter((item) => item !== "contacts")]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phone, name]
  );
  useEffect(() => {
    if (delivery.address && !delivery.valid) {
      setErr([...err, "delivery"]);
    }
    if (delivery.address && delivery.valid) {
      setErr([...err.filter((item) => item !== "delivery")]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery.address, delivery.valid]);
  useEffect(
    (e) => {
      setErr([...err.filter((item) => item !== "dataTime")]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataTime]
  );
  useEffect(
    (e) => {
      setErr([...err.filter((item) => item !== "payment")]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type]
  );
  useEffect(() => {
    if (!pValue || (pValue && pValid)) {
      setErr([...err.filter((item) => item !== "promo")]);
    }
    if (pValue && !pValid) {
      setErr([...err, "promo"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pValid, pValue]);

  useEffect(() => {
    if (!kNumber || (kNumber && kValid)) {
      setErr([...err.filter((item) => item !== "kaspiNumber")]);
    }
    if (kNumber && !kValid) {
      setErr([...err, "kaspiNumber"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kValid, kNumber]);

  useEffect(() => {
    if (phone && !ban && phone.length === 11) {
      bitrix.users
        .isUserBaned(phone.replace(/^8/, "7"))
        .then((res) => {
          if (res.status && res.status === "Y") {
            dispath(
              saleActions.orderSetPersonal({
                name,
                phone,
                valid: conValid,
                ban: true,
              })
            );
          }
        })
        .catch((e) => {});
    }
    if (login && !ban && login !== phone) {
      bitrix.users
        .isUserBaned(login)
        .then((res) => {
          if (res.status && res.status === "Y") {
            dispath(
              saleActions.orderSetPersonal({
                name,
                phone,
                valid: conValid,
                ban: true,
              })
            );
          }
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, login]);
  useEffect(() => {
    const getActiveOrder = async () => {
      try {
        const res = await bitrix.sale.getActiveOrder();
        if (res && Array.isArray(res) && res.length === 1) setHaveOrder(res[0]);
      } catch (error) {}
    };
    if (user) getActiveOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (orderId) {
    return <OrderEditSave />;
  }

  if (total === 0) {
    return (
      <Fragment>
        {modal ? (
          <div className="modal-panel-wrap">
            <div className="modal-panel position-relative">{modal}</div>
          </div>
        ) : null}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <ResponsiveLayout
        renderDesktop={() => (
          <SaleOrderD
            err={err}
            haveOrder={haveOrder}
            save={orderSave}
            sum={{
              basket: total,
              delivery: delivery.price,
              bonus: bValue > 0 && bValid ? bValue : 0,
              total: total + delivery.price - (bValue && bValid ? bValue : 0),
            }}
          />
        )}
        renderMobile={() => (
          <SaleOrderM
            err={err}
            save={orderSave}
            sum={{
              basket: total,
              delivery: delivery.price,
              bonus: bValue > 0 && bValid ? bValue : 0,
              total: total + delivery.price - (bValue && bValid ? bValue : 0),
            }}
          />
        )}
      />
      {modal ? (
        <div className="modal-panel-wrap">
          <div className="modal-panel position-relative">{modal}</div>
        </div>
      ) : null}
    </Fragment>
  );
};
const SaleOrderModal = ({ type, msg = "", order = null, close = () => {} }) => {
  const { t } = useTranslation();

  if (type === "spinner") {
    return (
      <div className="d-flex flex-column sale-order-modal">
        <h2>{t("WAIT")}...</h2>
        <div className="d-flex justify-content-center align-items-center p-5">
          <AppSpinner />
        </div>
      </div>
    );
  }
  if (type === "error") {
    return (
      <div className="d-flex flex-column sale-order-modal">
        <h2>{t("ERROR_LABEL")}</h2>
        <div className="d-flex p-2">
          <p>{msg}</p>
          <span className="modal-panel-close" onClick={close}>
            &times;
          </span>
        </div>
      </div>
    );
  }
  if (type === "order" && order) {
    return (
      <div className="d-flex flex-column sale-order-modal">
        <h2>{t("ORDER_IS_SAVE")}</h2>
        <div className=" p-2">
          <p>
            {t("ORDER_NUMBER")} <strong>{order.NUMBER}</strong>.{" "}
            {order.user
              ? t("ORDER_MSG_USER_AUTH")
              : t("ORDER_MSG_USER_NO_AUTH")}
          </p>
          <div className="sale-order-modal-buttons">
            <Link to="/" className="btn btn-sm btn-success">
              {t("GO_HOME_PAGE")}
            </Link>
            {order.user ? (
              <Link to="/personal/" className="btn btn-sm btn-success">
                {t("GO_MY_ORDERS_PAGE")}
              </Link>
            ) : null}
            {order.HREF ? (
              <a href={order.HREF} className="btn btn-sm btn-success">
                {t("GO_PAYMENT_PAGE")}
              </a>
            ) : null}
          </div>
          <span className="modal-panel-close" onClick={close}>
            &times;
          </span>
        </div>
      </div>
    );
  }
  if (type === "phone" && order) {
    return <PhoneValid order={order} close={close} />;
  }

  return <Fragment />;
};

const PhoneValid = ({ order, close }) => {
  const bitrix = useBitrix();
  const { t } = useTranslation();

  const [sms, setSms] = useState("");
  const [smsErr, setSmsErr] = useState("");
  const [state, setState] = useState(null);
  const [load, setLoad] = useState(false);

  const phoneValidStart = async () => {
    setLoad(true);
    const validPhone = order.phone.replace(/[^0-9]/g, "").replace(/^[8]/, "7");
    try {
      const res = await bitrix.users.validPhone({ phone: validPhone });
      setLoad(false);
      if (res && res.phone) setState(res);
      if (res && res.error) alert(res.error);
      if (res && res.status && res.status === "valid") order.save();
    } catch (error) {
      setLoad(false);
      alert(t("ORDER_PHONE_ERROR"));
    }
  };
  const phoneValidEnd = async () => {
    const validSms = sms.replace(/[^0-9]/g, "");
    if (validSms.length > 0) {
      setLoad(true);
      try {
        const res = await bitrix.users.validPhone({ ...state, sms: validSms });
        setLoad(false);
        if (res && res.error) setSmsErr(res.error);
        if (res && res.status && res.status === "valid") order.save();
      } catch (error) {
        setLoad(false);
        setSmsErr(t("SERVICE_NOT_FOUND"));
      }
    } else {
      setSmsErr("Неверный код");
    }
  };
  useEffect(() => {
    phoneValidStart();
    // eslint-disable-next-line
  }, []);

  if (state) {
    return (
      <Form className="order-contacts">
        <h3>{t("ORDER_CONFIRM_PHONE_NUMBER")}</h3>
        <p>{state.msg ? state.msg : t("ORDER_WAITING_CODE")}</p>
        <Form.Group controlId="form-sms">
          <Form.Control
            type="text"
            placeholder="XXXX"
            value={sms}
            onChange={(e) => {
              setSmsErr("");
              setSms(e.target.value);
            }}
            isInvalid={smsErr}
            disabled={load}
          />
          <FormControl.Feedback type="invalid">{smsErr}</FormControl.Feedback>
        </Form.Group>
        <div className="d-flex justify-content-center p-3">
          <Button variant="success" onClick={phoneValidEnd} disabled={load}>
            {load ? <Spinner as="span" size="sm" animation="border" /> : null}{" "}
            <span>{t("SAVE")}</span>
          </Button>
        </div>
        <span className="modal-panel-close" onClick={close}>
          &times;
        </span>
      </Form>
    );
  }
  return (
    <div className="d-flex justify-content-center p-3">
      <AppSpinner />
      <span className="modal-panel-close" onClick={close}>
        &times;
      </span>
    </div>
  );
};
export default SaleOrder;
