import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../../BitrixProvider";
import { saleActions } from "../../../state/actions";

import { ResponsiveLayout } from "../../ResponsiveLayout";
import { OrderPaymentD } from "./OrderPaymentD";
import { OrderPaymentM } from "./OrderPaymentM";

import "./order-payment.css";

const OrderPayment = () => {
  const { t } = useTranslation();
  const dispath = useDispatch();
  const {
    order: {
      payment: { type, valid, text },
      delivery: { price, type: deliveryId },
    },
    total,
  } = useSelector((state) => state.sale);
  const bitrix = useBitrix();

  const [active, setActive] = useState([]);
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState("");
  const setPayment = (t) => {
    if (t)
      dispath(
        saleActions.orderSetPayment({
          text,
          type: t,
          valid: true,
        })
      );
  };
  const setText = (text) => {
    dispath(saleActions.orderSetPayment({ type, valid, text }));
  };
  useEffect(() => {
    if (deliveryId) {
      (async () => {
        setLoad(true);
        try {
          let res = await bitrix.sale.getPaymentList(deliveryId);
          setLoad(false);
          if (Array.isArray(res)) {
            const newPaymentList = res.filter((el) => el.ID !== 10);
            setActive(newPaymentList);
          } else {
            setErr(t("SERVICE_NOT_FOUND"));
          }
        } catch (error) {
          setLoad(false);
          setErr(t("SERVICE_NOT_FOUND"));
        }
      })();
    }
    // eslint-disable-next-line
  }, [deliveryId, total]);

  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <OrderPaymentD
          params={{
            type,
            text,
            total: total + price,
            setPayment,
            setText,
            active,
            load,
            err,
          }}
        />
      )}
      renderMobile={() => (
        <OrderPaymentM
          params={{
            type,
            text,
            valid,
            total: total + price,
            setPayment,
            setText,
          }}
        />
      )}
    />
  );
};
export default OrderPayment;
