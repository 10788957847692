import React, { useState, useEffect } from 'react';
import {ResponsiveLayout} from '../ResponsiveLayout'
import { PersonalInfoD } from './PersonalInfoD';
import { PersonalInfoM } from './PersonalInfoM';
import {useBitrix} from '../BitrixProvider'
import { useSelector } from 'react-redux';
import './personal-info.css'
const PersonalInfo = ()=>{
    const userFilds = useSelector(state=>state.user)
    const bitrix = useBitrix() 
    const [user,setUser] = useState({NAME:userFilds.name,PERSONAL_MOBILE:'+'+userFilds.login,BONUSCARD:userFilds.bonuscard2}) 
    useEffect(()=>{
        bitrix.users.user()
            .then(data=>{                
                setUser(data)
            })
            .catch(e=>{})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return <ResponsiveLayout renderDesktop={()=><PersonalInfoD user={user} setUser={setUser}/>} renderMobile={()=><PersonalInfoM user={user} setUser={setUser}/>}/>
}
export default PersonalInfo