import React from 'react'
import CatalogSectionItem from '../CatalogSectionItem'
export const CatalogSectionM = ({prods=[],cardHead=null})=>{
    return (
        <div className="catalog-section-mobil-wrap  mb-3">                                  
            {
                prods.map(item=>(
                    <div className="w-50 d-flex position-relative" key={item.ID}>
                        {cardHead?cardHead(item.ID):null}
                        <CatalogSectionItem prods={item}/>
                    </div>
                ))                                       
            }           
        </div>
    )
}