import React from "react";
export const RuPage = ({ lang }) => {
  return (
    <div className="contacts">
      <p className="text-center">
        <strong>
          ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ В ОТНОШЕНИИ ИНТЕРНЕТ-РЕСУРСА &laquo;ИМ
          СОЛНЕЧНЫЙ&raquo; (https://cc.kz)
        </strong>
      </p>
      <p>
          <strong>1.ОБЩИЕ ПОЛОЖЕНИЯ</strong>
        </p>
      <p>
        1.1. Настоящее Пользовательское соглашение (далее -
        &laquo;Соглашение&raquo;) регламентирует отношения между Товариществом с
        ограниченной ответственностью &laquo;Супермаркет &laquo;Солнечный&raquo;
        зарегистрированным под основным государственным регистрационным номером
        БИН 120440002897 (далее - &laquo;Компания&raquo;), и дееспособным
        физическим лицом, надлежащим образом присоединившимся к настоящему
        Соглашению для использования интернет-ресурсом &laquo;ИМ
        Солнечный&raquo; (далее - &laquo;Пользователь&raquo;).
      </p>
      <p>
        1.2. Интернет-ресурс &laquo;ИМ Солнечный&raquo; (далее -
        &laquo;Сайт&raquo;) является программным обеспечением, доступным
        Пользователю в сети Интернет по адресу{" "}
        <a href="https://cc.kz">https://cc.kz</a>. Права интеллектуальной
        собственности на Сайт принадлежат Компании.
      </p>
      <p>
        1.3. Настоящее Соглашение является открытым и общедоступным документом.
        Действующая редакция Соглашения располагается в сети Интернет по адресу:{" "}
        <a href="https://cc.kz">https://cc.kz/contract/</a>        
      </p>
      <p>
        &nbsp;1.4. Использование Сайта является акцептом настоящего Соглашения и
        подтверждением согласия Пользователя с его условиями.
      </p>
      <p>
        1.5. Условия настоящего Соглашения в соответствии с статьей 395
        Гражданского кодекса Республики Казахстан являются публичной офертой -
        предложением заключить с Компанией договор присоединения, согласно
        которого Компания предоставляет Пользователю доступ к Сайту на условиях
        настоящего Соглашения.
      </p>
      <p>
        1.6. Обращения, предложения и претензии физических и юридических лиц к
        Компании, связанные с содержанием и функционированием Сайта, нарушениями
        прав и интересов третьих лиц, требований законодательства Республики
        Казахстан, а также для запросов уполномоченных законодательством
        Республики Казахстан лиц могут быть направлены на адрес электронной
        почты: info@sunny.kz
      </p>
      <p>
        1.7. Настоящее Соглашение составлено в соответствии с законодательством
        Республики Казахстан.
        <br />
        Вопросы, не урегулированные Соглашением, подлежат разрешению в
        соответствии с<br />
        законодательством Республики Казахстан.
      </p>
      <p>
        1.8. Соглашаясь с условиями настоящего Соглашения, Пользователь
        подтверждает свою правоспособность и свою дееспособность, подтверждает
        достоверность своих данных и принимает на себя всю ответственность за их
        точность, полноту и достоверность.
      </p>
      <p>
          <strong>2.ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</strong>
        </p>
      <p>
        2.1. Пользователь обязуется надлежащим образом соблюдать условия
        настоящего Соглашения.
      </p>
      <p>
        2.2. Пользователь обязан самостоятельно обеспечивать неразглашение
        (тайну) данных, хранящихся в учетной записи, в том числе пароля, и несет
        ответственность за их разглашение любым способом, в том числе за все
        риски и убытки, возникшие в связи с этим. Разглашение данных учетной
        записи является существенным нарушением настоящего Соглашения.
      </p>
      <p>
        2.3. Пользователь несет ответственность за все действия, совершенные под
        его учетной записью, и с использованием его логина и пароля.
        Пользователь обязуется незамедлительно уведомлять Компанию обо всех
        случаях нарушения безопасности и несанкционированного доступа к учетной
        записи. При невыполнении Пользователем данной обязанности Пользователь
        самостоятельно и в полном объеме несет ответственность за действия,
        совершенные под его учетной записью. Для проведения проверки учетная
        запись может подвергнуться технической блокировке.
      </p>
      <p>
        2.4. Пользователь обязуется не использовать Сайт для любых иных целей,
        кроме как для целей, связанных с личным некоммерческим использованием.
      </p>
      <p>
        2.5. Пользователь обязуется, пользуясь Сайтом, не вводить в заблуждение
        других Пользователей и третьих лиц.
      </p>
      <p>
        <strong>3.ПРАВА И ОБЯЗАННОСТИ КОМПАНИИ</strong>
      </p>
      <p>
        3.1. Компания вправе направлять Пользователю любым способом информацию о
        функционировании Сайта, в том числе размещать информационные и иные
        сообщения внутри Сайта.
      </p>
      <p>
        <br />
        3.2. Компания вправе передавать права и обязанности по настоящему
        Соглашению, третьим лицам в целях исполнения настоящего Соглашения без
        дополнительного согласия Пользователя.
      </p>
      <p>
        3.3. Компания не отвечает за любые технические сбои или иные проблемы
        любых телефонных сетей или служб, компьютерных систем, серверов или
        провайдеров, компьютерного или телефонного оборудования, программного
        обеспечения, сбоев сервисов электронной почты или скриптов по
        техническим причинам.
      </p>
      <p>
        3.4. Компания не несет ответственности за любой ущерб компьютеру
        пользователя или иного лица, мобильным устройствам, любому другому
        оборудованию или программному обеспечению, вызванный или связанный со
        скачиванием материалов Сайта или по ссылкам, размещенным на Сайте.
      </p>
      <p>
        3.5. Ни при каких обстоятельствах Компания или ее представители не несут
        ответственность перед Пользователем или перед любыми третьими лицами за
        любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду
        или потерянные данные, вред чести, достоинству или деловой репутации,
        вызванный в связи с использованием Сайта, содержимого Сайта или иных
        материалов, к которым вы или иные лица получили доступ с помощью Сайта,
        даже если администрация предупреждала или указывала на возможность
        такого вреда.
      </p>
      <p>
        3.6. Компания вправе собирать и использовать технические данные и
        связанную с ними информацию, включая, помимо прочего, технические данные
        о внешних устройствах, а также о программном обеспечении устройства
        Пользователя, системы и приложения. Данная информация собирается
        периодически и преследует цель упростить предоставление Пользователю
        технической поддержки и услуг (если таковые имеются), относящихся к
        Сайту. Компания вправе использовать данную информацию (при условии, что
        по ней невозможно установить личность Пользователя) в целях
        усовершенствования своих продуктов или предоставления вам своих услуг и
        технологий.
      </p>
      <p>
        3.7. Компания вправе по своему усмотрению изменять, редактировать,
        удалять любой контент Сайта.
      </p>
      <p>
        <strong>4.ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ СТОРОН</strong>
      </p>
      <p>
        4.1. Пользователь гарантирует, что не будет предпринимать каких-либо
        действий, направленных на причинение ущерба обладателю прав на Сайт,
        Компании, операторам сотовой мобильной связи, правообладателям и иным
        лицам.
      </p>
      <p>
        4.2. В случае нарушения правил использования Сайта, указанных в разделе
        2 настоящего Соглашения, а также в случае нарушения пункта 4.1
        настоящего Соглашения, Пользователь обязуется возместить Компании вред и
        все убытки, причиненный такими действиями.
      </p>
      <p>
        4.3. Если Пользователем не доказано обратное, любые действия,
        совершенные с использованием его логина и пароля, считаются совершенными
        соответствующим Пользователем. В случае несанкционированного доступа к
        логину и паролю Пользователя, или распространения логина и пароля
        Пользователь обязан незамедлительно сообщить об этом Компании в
        установленном порядке.
      </p>
      <p>
        4.4. Признавая международный характер сети Интернет, Пользователь
        принимает на себя ответственность за соблюдение всех соответствующих
        правил и законов, касающихся действий Пользователя в сети Интернет.
      </p>
      <p>
        <strong>5.УСЛОВИЯ О КОНФИДЕНЦИАЛЬНОСТИ</strong>
      </p>
      <p>5.1. В рамках настоящего соглашения:</p>
      <p>
        5.1.1. Компания вправе передавать права и обязанности по настоящему
        Соглашению третьим лицам в целях исполнения настоящего Соглашения, без
        дополнительного согласия Пользователя.
      </p>
      <p>
        5.1.2. Пользователь, соглашаясь с данной офертой, дает свое
        информированное и добровольное согласие на участие в стимулирующих,
        рекламных, маркетинговых и иных мероприятиях, направленных на
        продвижение услуг Компании, партнеров Компании и иных третьих лиц.
        Компания вправе направлять Пользователю любым способом информацию о
        функционировании Сайта, в том числе на адрес электронной почты,
        указанный Пользователем, а также направлять собственные или любых
        третьих лиц информационные, рекламные или иные сообщения, или размещать
        соответствующую информацию на самом Сайте.
      </p>
      <p>
        5.1.3. Пароль Пользователя хранится в зашифрованном виде на сервере
        Компании.
      </p>
      <p>
        5.1.4. Компания производит сбор, систематизацию, хранение, обработку
        персональной и/или личной информации Пользователя.
      </p>
      <p>
        5.1.5. Компания вправе заблокировать доступ Пользователя к Сайту в
        случае обнаружения нарушений Пользователем обязанностей, указанных в
        разделе 2 настоящего Соглашения.
      </p>
      <p>
        <strong>6.ССЫЛКИ НА САЙТЫ ТРЕТЬИХ ЛИЦ</strong>
      </p>
      <p>
        6.1. Сайт может содержать ссылки или представлять доступ на другие
        ресурсы в сети Интернет (сайты третьих лиц) и размещенный на данных
        ресурсах Контент, являющиеся результатом интеллектуальной деятельности
        третьих лиц и охраняемые в соответствии с законодательством РК.
        Указанные сайты и размещенный на них Контент не проверяются Компанией на
        соответствие требованиям законодательства РК
      </p>
      <p>
        6.2. Компания не несет ответственность за любую информацию или Контент,
        размещенные на сайтах третьих лиц, к которым Пользователь получает
        доступ посредством Сайта, включая, в том числе, любые мнения или
        утверждения, выраженные на сайтах третьих лиц.
      </p>
      <p>
        6.3. Пользователь подтверждает, что с момента перехода Пользователя по
        ссылке, содержащейся на Сайте, на сайт третьего лица, взаимоотношения
        Компании и Пользователя прекращаются, настоящее Соглашение в дальнейшем
        не распространяется на Пользователя, и Компания не несет ответственность
        за достоверность размещенной на сайтах третьих лиц информации,
        использование Пользователем Контента, правомерность такого использования
        и качество Контента, размещенного на сайтах третьих лиц.
      </p>
      <p>
        <strong>7.ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
      </p>
      <p>
        7.1. Вопросы, не урегулированные настоящим Соглашением, подлежат
        разрешению в соответствии с законодательством Республики Казахстан.
      </p>
      <p>
        7.2. В случае возникновения любых споров или разногласий, связанных с
        исполнением настоящего Соглашения, Пользователь и Компания приложат все
        усилия для их разрешения путем проведения переговоров между ними. В
        случае, если споры не будут разрешены путем переговоров, споры подлежат
        разрешению в суде общей юрисдикции по месту нахождения Компании в
        порядке, установленном действующим законодательством Республики
        Казахстан.
      </p>
      <p>
        7.3. Настоящее Соглашение вступает в силу для Пользователя с момента
        открытия Сайта в браузере. Настоящее Соглашение действует бессрочно.
      </p>
      <p>
        7.4. Настоящее Соглашение составлено на русском языке, являющимся
        понятным для Пользователя и Компании.
      </p>
      <p>
        7.5. Настоящее Соглашение может быть изменено и/или дополнено Компанией
        в любое время в течение срока действия Соглашения. Все изменения и/или
        дополнения размещаются Компанией в соответствующем разделе Сайта и
        вступают в силу в день такого размещения. Пользователь обязуется
        своевременно и самостоятельно знакомиться со всеми изменениями и/или
        дополнениями. При наличии возражений Пользователь вправе направить
        извещение в течение 3 календарных дней с момента размещения изменений
        и/или дополнений к Соглашению. В таком случае Соглашение считается
        расторгнутым Сторонами с даты получения Компанией такого извещения от
        Пользователя.
      </p>
      <p>
        7.6. Если какое-либо из положений настоящего Соглашения будет признано
        недействительным, это не оказывает влияния на действительность или
        применимость остальных положений настоящего Соглашения.
      </p>
    </div>
  );
};
