export default class Content {
  //Баннеры для главной страницы
  getSlidersImg() {
    return {
      method: "GET",
      url: "contents/slids",
    };
  }
  //Реклама: баннеры брендов и баннер категории
  getBlurbContent(data = {}) {
    return {
      method: "GET",
      url: "contents/blurb",
      data,
    };
  }
  //Гугл капча
  validCaptcha(token) {
    return {
      method: "GET",
      url: "contents/captcha",
      data: {
        token,
      },
    };
  }
  //Баннер категории
  getCategoryBanner() {
    return {
      method: "GET",
      url: "contents/categorybanner",
    };
  }
}
