import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const OrderCommentD = ({ params }) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState(false);

  return (
    <div className="form-group order-comment-form">
      <label htmlFor="order-comment">{t("ORDER_COMMENT")}:</label>
      <textarea
        className="form-control"
        id="order-comment"
        rows={rows ? 3 : 1}
        onFocus={() => setRows(true)}
        onBlur={() => setRows(false)}
        value={params.comment}
        onChange={(e) => params.setComment(e.target.value)}
      ></textarea>
    </div>
  );
};
