import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const FilterItems = ({ filter, action, active }) => {
  const { i18n } = useTranslation();

  active = active.replace("%26", "&");
  const [show, toggleShow] = useState(true);
  if (show) {
    return (
      <div className="catalog-filter">
        <div
          className="catalog-filter-title show"
          onClick={() => toggleShow(false)}
        >
          <h6>{filter.LANG[i18n.resolvedLanguage]}</h6>
        </div>
        <div className="catalog-filter-list">
          {filter.VALUES.map((item, id) => (
            <div
              className="custom-control custom-checkbox catalog-filter-list-item"
              key={"item-" + id}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                checked={active.includes(item)}
                id={"item-" + id + filter.CODE}
                onChange={() => action(filter.CODE, item)}
              />
              <label
                className="custom-control-label cust-check"
                htmlFor={"item-" + id + filter.CODE}
              >
                {item}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="catalog-filter" onClick={() => toggleShow(true)}>
      <div className="catalog-filter-title">
        <h5>{filter.LANG[i18n.resolvedLanguage]}</h5>
      </div>
    </div>
  );
};
