import React, { useState, useEffect } from "react";
import { ResponsiveLayout } from "../ResponsiveLayout";
import { CatalogSearchD } from "./CatalogSearchD";
import { CatalogSearchM } from "./CatalogSearchM";

import { useBitrix } from "../BitrixProvider";

import "./catalog-search.css";

const CatalogSearch = () => {
  const bitrix = useBitrix();
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const clear = () => {
    setSearch("");
    setList([]);
  };
  const send = async () => {
    const res = await bitrix.catalog.searchTitleProd(clearReq(search));
    setList(res);
  };
  const clearReq = (text = "") =>
    text.replace(/[^\sа-яА-ЯёЁa-zA-Z0-9әӘіІңҢғҒүҮұҰқҚөӨһҺ]*/g, "");

  useEffect(() => {
    if (search.length > 4) send();
    else {
      if (list.length > 0) setList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <CatalogSearchD
          search={search}
          setSearch={setSearch}
          list={list}
          clear={clear}
          clearReq={clearReq}
        />
      )}
      renderMobile={() => (
        <CatalogSearchM
          search={search}
          setSearch={setSearch}
          list={list}
          clear={clear}
          clearReq={clearReq}
        />
      )}
    />
  );
};
export default CatalogSearch;
