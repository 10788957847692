import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import i18next from "i18next";

import { useWindowDimensions } from "../WindowDimensionsProvider";

import "./breadcrumbs.css";

const getLinkArr = (path = "", categories) => {
  const links = [{ url: "/", title: i18next.t("HOME_PAGE") }];
  const pathArr = path.slice(1, -1).split("/");
  let curretCateg = categories;
  switch (pathArr[0]) {
    case "catalog":
      pathArr.pop();
      pathArr.shift();
      pathArr.forEach((key) => {
        if (curretCateg[key]) {
          links.push({
            url: curretCateg[key]["SECTION_PAGE_URL"],
            title: curretCateg[key]["LANG"][i18next.resolvedLanguage],
          });
          curretCateg = curretCateg[key]["CHILD"];
        }
      });
      break;
    case "discounts":
      pathArr.pop();
      if (pathArr.length > 0) {
        pathArr.shift();
        links.push({ url: "/discounts/", title: i18next.t("DISCOUNTS_WORD") });
        curretCateg = categories;
        pathArr.forEach((key) => {
          links.push({
            url: curretCateg[key]["SECTION_PAGE_URL"].replace(
              "catalog",
              "discounts"
            ),
            title: curretCateg[key]["LANG"][i18next.resolvedLanguage],
          });
          curretCateg = curretCateg[key]["CHILD"];
        });
      }
      break;
    default:
  }
  return links;
};

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const { categories } = useSelector((state) => state.catalog.categories);
  const { isMobil } = useWindowDimensions();

  if (isMobil) return <Fragment />;
  if (!categories) return <Fragment />;
  const links = getLinkArr(pathname, categories);
  return (
    <div className="bread-wrap">
      {links.map((item, id) => (
        <Link to={item.url} className="bread-link" key={"link" + id}>
          {item.title}
        </Link>
      ))}
    </div>
  );
};

export default Breadcrumbs;
