import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useWindowDimensions } from "../WindowDimensionsProvider";

export const CatalogScroll = ({ page, action, maxPage }) => {
  const { t } = useTranslation();
  const { isMobil } = useWindowDimensions();

  const [load, setLoad] = useState(false);

  const changePage = () => {
    if (page < maxPage) {
      setLoad(true);
      action(() => setLoad(false), page);
    }
  };

  useEffect(() => {
    const scrollHandler = () => {
      if (isMobil) {
        if (
          document.getElementById("scroll-trigger") &&
          document.getElementById("scroll-trigger").getBoundingClientRect()
            .bottom <=
            window.innerHeight + 300
        )
          changePage();
      }
    };
    if (isMobil) document.addEventListener("scroll", scrollHandler);
    return () => document.removeEventListener("scroll", scrollHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (load) {
    return (
      <div className="d-flex justify-content-center mb-3">
        <Spinner animation="border" size="sm" variant="success" />
      </div>
    );
  }

  if (page === maxPage) {
    return <Fragment />;
  }

  if (isMobil) {
    return <div id="scroll-trigger"></div>;
  }

  return (
    <div className="d-flex justify-content-center mb-3">
      <button className="btn btn-sm page-loader" onClick={changePage}>
        {load ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          t("SEE_MORE")
        )}
      </button>
    </div>
  );
};
