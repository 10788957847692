import React, { Fragment, useState } from "react";
import { FaBars, FaPhoneVolume, FaWhatsapp } from "react-icons/fa";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Twemoji } from "react-emoji-render";
import { useTranslation } from "react-i18next";

import CatalogTree from "../CatalogTree";
import PersonalLink from "../PersonalLink";
import LanguageSwitcher from "../LanguageSwitcher";

import "./main-menu.css";

export const MainMenuM = ({ elems = {} }) => {
  const { t } = useTranslation();

  const [show, toggleShow] = useState(false);

  return (
    <Fragment>
      <FaBars color="#FFEF16" size={30} onClick={() => toggleShow(!show)} />
      <div className={show ? "main-menu-sidebar show" : "main-menu-sidebar"}>
        <div
          className={
            show ? "main-menu-sidebar-body show" : "main-menu-sidebar-body"
          }
        >
          <div className="main-menu-sidebar-header">
            <PersonalLink finish={() => toggleShow(!show)} />
            <LanguageSwitcher />
          </div>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Link
                to="/"
                className="d-flex align-items-center"
                onClick={() => toggleShow(!show)}
              >
                <Twemoji text=":house:" className="mr-1" />
                <span className="main-menu-sidebar-link">{t("HOME_PAGE")}</span>
              </Link>
            </ListGroup.Item>

            <CatalogTree close={() => toggleShow(false)} />
            <ListGroup.Item>
              <Link
                to="/discounts/"
                className="d-flex align-items-center"
                onClick={() => toggleShow(!show)}
              >
                <Twemoji text=":bookmark:" className="mr-1" />
                <span className="main-menu-sidebar-link">{t("DISCOUNTS")}</span>
              </Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link
                to="/shipping_and_payment"
                className="d-flex align-items-center"
                onClick={() => toggleShow(!show)}
              >
                <Twemoji text=":question:" className="mr-1" />
                <span className="main-menu-sidebar-link">{t("SHIPMENT")}</span>
              </Link>
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <Link
                to="/bonus/"
                className="d-flex align-items-center"
                onClick={() => toggleShow(!show)}
              >
                <Twemoji text=":credit_card:" className="mr-1" />
                <span className="main-menu-sidebar-link">
                  {t("BONUS_CARD")}
                </span>
              </Link>
            </ListGroup.Item> */}
            <ListGroup.Item>
              <Link
                to="/supermarkets/"
                className="d-flex align-items-center"
                onClick={() => toggleShow(!show)}
              >
                <Twemoji text=":convenience_store:" className="mr-1" />
                <span className="main-menu-sidebar-link">
                  {t("OUR_MARKETS")}
                </span>
              </Link>
            </ListGroup.Item>
          </ListGroup>
          <div className="sidebar-buttons pt-3 pb-3 d-flex flex-column align-items-center">
            <div className="d-flex">
              <a href="tel:+77084050000">
                <FaPhoneVolume size={30} color="#00B059" />
              </a>
              <a href="https://wa.me/77084050000" target="_blanck">
                <FaWhatsapp size={30} color="#00B059" />
              </a>
            </div>
            <div className="d-flex flex-column align-items-center">
              <span>{t("SUPPORT")}</span>
              <span>{t("SUPPORT_PHONE")}</span>
            </div>
          </div>
        </div>
        <div
          className="main-menu-sidebar-close"
          onClick={() => toggleShow(!show)}
        ></div>
      </div>
    </Fragment>
  );
};
