import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  FaInstagram,
  FaVk,
  FaFacebookF,
  FaOdnoklassniki,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./footer.css";
import mc from "./mc.png";
import vs from "./vs.png";
import ios from "./ios.png";
import play from "./play.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer-empty"></div>
      <Container className="app-footer pt-lg-5 pt-2">
        <Row>
          <Col lg={5} className="d-flex flex-column">
            {/* <div className="call-center">{t("CALL_CANTER_INFO")}</div>
            <h4>{t("SUPPORT_PHONE")}</h4> */}
            <div className="mt-2">
              Товарищество с ограниченной ответственностью
              <br />
              «Супермаркет «Солнечный»
              <br />
              (cc.kz – доставка продуктов)
              <br />
              БИН 120440002897
              <br />
              110000, Республика Казахстан, Костанайская область,
              <br />
              г. Костанай, ул.Карбышева, 85
              <br />
              +7 (708) 405-00-00
              <br />
              im@cc.kz
            </div>
          </Col>
          <Col lg={2} className="mb-2 d-none d-lg-block d-xl-block">
            <h4>{t("ABOUT_COMPANY")}</h4>
            <Link to="/contacts/" className="footer-link">
              {t("CONTACTS")}
            </Link>
            <Link to="/supermarkets/" className="footer-link">
              {t("OUR_MARKETS")}
            </Link>
            <a
              href="https://hh.kz/employer/1000260"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              {t("VACANCY")}
            </a>
            <a
              href="https://cc.kz/upload/info/partners.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              {t("FOOTER_PARTNERS")}
            </a>
          </Col>
          <Col lg={5} className="mb-2 d-flex flex-column align-items-center">
            <h3 className="d-none d-lg-block d-xl-block">{t("FOLLOW_US")}</h3>
            <div>
              <a
                href="https://www.instagram.com/cckzkst"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-icon-link insta"
              >
                <FaInstagram color="#b23a94" />
              </a>
              <a
                href="https://vk.com/supermarketkst"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-icon-link vk"
              >
                <FaVk color="#007bb6" />
              </a>
              <a
                href="https://www.facebook.com/supermarketkst"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-icon-link fb"
              >
                <FaFacebookF color="#4867aa" />
              </a>
              <a
                href="https://ok.ru/supermarketkst"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-icon-link ok"
              >
                <FaOdnoklassniki color="#f99c3a" />
              </a>
            </div>
            <div className="mt-3">
              <a
                href="https://play.google.com/store/apps/details?id=kz.cc.jackcat1&utm_source=offline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={play} height={40} />
              </a>
              <a
                href="http://apps.apple.com/ru/app/солнечный-доставка-продуктов/id1544924750?utm_source=offline"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-3"
              >
                <Image src={ios} height={40} />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={5} className="d-flex flex-column"></Col>
        </Row>
        <Row>
          <Col className="text-center">
            <div className="call-center mt-3">
              {t("CALL_CANTER_INFO")}
              <h4>{t("SUPPORT_PHONE")}</h4>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="d-flex justify-content-center mt-3 mb-2">
        <div className="footer-line w-75"></div>
      </div>
      <Container className="pb-3">
        <Row>
          <Col className="d-flex justify-content-between">
            <span className="text-copyright">
              {t("COPYRIGHT")} | {t("PRIVACY_INFO")} |{" "}
              <a
                href="https://cc.kz/upload/info/policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link-inline"
              >
                {t("CONTRACT_LABEL")}
              </a>
            </span>
            <div className="d-flex align-items-center">
              <Image src={mc} />
              <Image src={vs} />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
