const getPath = (path)=>{
    return []
}
const getMapApi = (ymaps)=>{
    const map = new ymaps.Map('map',{center:[53.214480, 63.632073],zoom:10})
    const deliveryZones = ymaps.geoQuery(getZone()).addToMap(map)
    return {
        ymaps,
        map,
        deliveryZones
    }

}
const getZone = ()=>{
    return {
        "type": "FeatureCollection",
        "features": [{
                "type": "Feature",
                "id": 0,
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [                                    
                            [53.280473, 63.573456],
                            [53.258701, 63.591508],
                            [53.248530, 63.622766],
                            [53.236216, 63.622936],
                            [53.233553, 63.630381],
                            [53.231151, 63.632962],
                            [53.228728, 63.638579],
                            [53.224329, 63.649754],
                            [53.220580, 63.656353],
                            [53.219073, 63.659775],
                            [53.210625, 63.688454],
                            [53.184075, 63.785671],
                            [53.270659, 63.891466],
                            [53.330375, 63.670679],
                            [53.281146, 63.570039]
                        ]
                    ]
                },
                "properties": { "name": "Каирбекова, 379", "base_coords": [53.239447, 63.679972], "base_id": "k379" },    
                "options": {
                    "opacity": 1
                },
            },
            {
                "type": "Feature",
                "id": 1,
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [53.267994, 63.525577],
                            [53.224001, 63.583972],
                            [53.222021, 63.588176],
                            [53.221930, 63.595094],
                            [53.221338, 63.600526],
                            [53.217725, 63.602529],
                            [53.214601, 63.604502],
                            [53.212579, 63.600648],
                            [53.210826, 63.604057],
                            [53.205197, 63.614832],
                            [53.202676, 63.619938],
                            [53.200507, 63.622818],
                            [53.199298, 63.626582],
                            [53.192078, 63.648557],
                            [53.158400, 63.614531],
                            [53.073593, 63.516360],
                            [53.141754, 63.379215],
                            [53.279010, 63.562476]
                        ]
                    ]
                },
                "properties": { "name": "Карбышева, 85", "base_coords": [53.185019, 63.586274], "base_id": "k85" },
                "options": {
                    "opacity": 1
                },
            },
            {
                "type": "Feature",
                "id": 2,
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [53.279440, 63.567372],
                            [53.247339, 63.622016],
                            [53.236055, 63.622054],
                            [53.231022, 63.632776],
                            [53.228798, 63.637781],
                            [53.227304, 63.639648],
                            [53.223096, 63.648467],
                            [53.218311, 63.658941],
                            [53.210678, 63.688381],
                            [53.184184, 63.788967],
                            [53.184745, 63.717751],
                            [53.117981, 63.674008],
                            [53.137298, 63.587454],
                            [53.170983, 63.622639],
                            [53.195933, 63.646811],
                            [53.198075, 63.624733],
                            [53.212713, 63.600972],
                            [53.224286, 63.585909],
                            [53.244749, 63.554388],
                            [53.265155, 63.524964],
                            [53.280382, 63.566429]
                        ]
                    ]
                },
                "properties": { "name": "Гипер", "base_coords": [53.202201, 63.660430], "base_id": "g1" },    
                "options": {
                    "opacity": 1
                },
            }
        ]
    }
}
const updateSystem = (state,action)=>{
    if(state===undefined){
        return{
            path:'',
            title:false,
            toast:{
                msg:'',
                show:false
            },
            mapApi:{
                ymaps:{},
                map:{},
                deliveryZone:{}
            },
            slider:false
        }
    }
    switch(action.type){
        case 'UPD_PATH':
            return{
                ...state.system,
                path:getPath(action.payload)
            }
        case 'UPD_TITLE':
            return{
                ...state.system,
                title:action.payload  
            }
        case 'TOAST_SHOW':
            return{
                ...state.system,
                toast:{
                    msg:action.payload,
                    show:true
                }
            }
        case 'TOAST_HIDE':
            return{
                ...state.system,
                toast:{
                    msg:'',
                    show:false
                }
            }
        case 'SET_YMAPS':
            return{
                ...state.system,
                mapApi:getMapApi(action.payload) 
            }
        case 'SET_SLIDER':
            return{
                ...state.system,
               slider:action.payload 
            }
        default:
            return state.system
    }
}

export default updateSystem