import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Alert, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useBitrix } from "../BitrixProvider";

import "./feedback.css";
const FeedbackForm = () => {
  const { t } = useTranslation();
  const bitrix = useBitrix();

  const [name, setName] = useState("");
  const [errName, setErrName] = useState("");
  const [contact, setContact] = useState("");
  const [errContact, setErrContact] = useState("");
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [load, setLoad] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [captErr, setCaptErr] = useState("");

  const captchaHandler = async (value) => {
    setCaptErr("");
    if (value) {
      let hasError = false;
      try {
        const res = await bitrix.content.validCaptcha(value);
        const serverRes = JSON.parse(res);
        if (serverRes.success) {
          setIsCaptcha(true);
        } else {
          hasError = true;
        }
      } catch (error) {
        hasError = true;
      }
      if (hasError) setCaptErr(t("FEEDBACK_ERROR_REQUEST"));
    }
  };
  const sendMsg = () => {
    let valid = true;
    const validPhone = contact.replace(/[^0-9]*/g, "");
    if (!name) {
      valid = false;
      setErrName(t("EMPTY_FIELD"));
    }
    if (!contact) {
      valid = false;
      setErrContact(t("EMPTY_FIELD"));
    }
    if (
      validPhone.length === 11 ||
      /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(
        contact
      )
    ) {
    } else {
      valid = false;
      setErrContact(t("INVALID_VALUE"));
    }
    if (!msg) {
      valid = false;
      setErrMsg(t("EMPTY_FIELD"));
    }
    if (valid && isCaptcha) {
      let to = 3362;
      let type = "USER";
      let message = `Поступило новое обращение в раздел обратной связи. Контакт: ${name} - ${contact}, текст обращения: ${msg}.`;
      setLoad(true);
      fetch(
        "https://sunny.bitrix24.kz/rest/3362/aten2go09x3mlg6x/im.notify/?to=" +
          to +
          "&type=" +
          type +
          "&message=" +
          message
      ).then((res) => {
        setLoad(false);
        if (res.ok) {
          setResMsg(t("FEEDBACK_SEND"));
          setName("");
          setMsg("");
          setContact("");
        } else {
          setResMsg(t("SERVICE_NOT_FOUND"));
        }
      });
    }
  };
  return (
    <Form className="feedback-wrap">
      <h3>{t("FEEDBACK_FORM")}</h3>
      {resMsg ? <Alert variant="info">{resMsg}</Alert> : null}
      <div className="d-flex justify-content-between">
        <Form.Group className="feedback-line mr-2" controlId="feed-name">
          <Form.Label>{t("NAME_LABEL")}</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => {
              setErrName("");
              setName(e.target.value);
            }}
            isInvalid={errName}
            disabled={load}
          />
          <Form.Control.Feedback type="invalid">
            {errName}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="feedback-line" controlId="feed-contact">
          <Form.Label>{t("PHONE_EMAIL")}</Form.Label>
          <Form.Control
            type="text"
            value={contact}
            onChange={(e) => {
              setErrContact("");
              setContact(e.target.value);
            }}
            isInvalid={errContact}
            disabled={load}
          />
          <Form.Control.Feedback type="invalid">
            {errContact}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <Form.Group className="w-100" controlId="feed-text">
        <Form.Label>{t("TEXT_FEED")}</Form.Label>
        <Form.Control
          type="text"
          value={msg}
          onChange={(e) => {
            setErrMsg("");
            setMsg(e.target.value);
          }}
          isInvalid={errMsg}
          disabled={load}
          as="textarea"
          rows="3"
        />
        <Form.Control.Feedback type="invalid">{errMsg}</Form.Control.Feedback>
      </Form.Group>
      <ReCAPTCHA
        sitekey="6LcZ078UAAAAANEzFJ175IPwBedTLRfPvfDgjYp0"
        onChange={captchaHandler}
      />
      <small className="text-danger">{captErr}</small>
      <div className="mt-3 d-flex justify-content-center mb-3">
        <button
          className="btn btn-success w-50"
          disabled={load || !isCaptcha}
          onClick={sendMsg}
          type="button"
        >
          {load ? <Spinner animation="border" size="sm" /> : t("SEND_LADEL")}
        </button>
      </div>
    </Form>
  );
};
export default FeedbackForm;
