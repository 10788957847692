import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../../BitrixProvider";
import { saleActions } from "../../../state/actions";

import { OrderKaspiNumberD } from "./OrderKaspiNumberD";

import "./order-kaspi-number.css";

const OrderPromo = () => {
  const dispatch = useDispatch();
  const bitrix = useBitrix();
  const { t } = useTranslation();
  const { number, valid } = useSelector((state) => state.sale.order.kaspi);

  const [val, setVal] = useState("");
  const [err, setErr] = useState("");

  const kaspiNumberCheck = async () => {
    if (val) {
      try {
        const response = await bitrix.sale.checkPhoneInKaspi(val);
        if (response.status === "success") {
          dispatch(saleActions.setKaspiNumber({ number: val, valid: true }));
        }
        if (response.status === "error") {
          setErr(t("INVALID_KASPI_NUMBER"));
          dispatch(saleActions.setKaspiNumber({ number: "", valid: false }));
        }
      } catch (error) {
        console.log(error);
        setErr(t("FEED_ERROR_MSG"));
        dispatch(saleActions.setKaspiNumber({ number: "", valid: false }));
      }
    } else {
      dispatch(saleActions.setKaspiNumber({ number: "", valid: false }));
    }
  };
  useEffect(() => {
    setVal(number);
  }, [number]);

  return (
    <OrderKaspiNumberD
      val={val}
      change={(e) => {
        setErr("");
        setVal(e.target.value);
        if (e.target.value === "") {
          dispatch(saleActions.setKaspiNumber({ number: "", valid: false }));
        }
      }}
      blurHandler={kaspiNumberCheck}
      valid={number && valid}
      err={err}
    />
  );
};
export default OrderPromo;
