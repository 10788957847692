import React from 'react';
import { useSelector } from 'react-redux';
import CatalogSlider from '../CatalogSlider';
import { DelFavoriteButton } from './DelFavoriteButton';
import './personal-favorite.css'
const PersonalFavorite = ()=>{
    const {favorite} = useSelector(state=>state.user)    
    return <CatalogSlider type="favorites" data={favorite} header={id=><DelFavoriteButton prodId={id}/>}/>
}

export default PersonalFavorite