import React,{Fragment, useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'

import {ResponsiveLayout} from '../ResponsiveLayout'

import {useBitrix} from '../BitrixProvider'
import {systemActions} from '../../state/actions'
import { BannerSliderD } from './BannerSliderD';
import { BannerSliderM } from './BannerSliderM';

import './banner-slider.css'
const BannerSlider = ()=>{
    const {slider} = useSelector(state=>state.system)    
    const bitrix = useBitrix()
    const dispath = useDispatch()
       

    useEffect(()=>{ 
        let mounted = true;
        const getData = ()=>{
            bitrix.content.getSlider()
                .then(res=>{
                    if(Array.isArray(res) && res.length>0 && mounted){                         
                        dispath(systemActions.setSlider(res))
                    }
                })
                .catch(e=>{})
        }        
        if(!slider) getData();
        return ()=>{            
            mounted = false;
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])  
    
    if(slider) return <ResponsiveLayout renderDesktop={()=><BannerSliderD slider={slider}/>} renderMobile={()=><BannerSliderM slider={slider}/>}/>        
    return <Fragment/>
}
export default BannerSlider