import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../state/actions";
import { useBitrix } from "../BitrixProvider";
import { useTranslation } from "react-i18next";

import "./personal-favorite.css";

export const DelFavoriteButton = ({ prodId }) => {
  const { t } = useTranslation();
  const bitrix = useBitrix();
  const dispath = useDispatch();
  const { favorite } = useSelector((state) => state.user);

  const [load, setLoad] = useState(false);

  const delProd = () => {
    const arr = favorite.filter((item) => parseInt(item) !== parseInt(prodId));
    setLoad(true);
    bitrix.users
      .updateUser({ UF_FAVORITES: arr })
      .then((data) => {
        setLoad(false);
        if (data["status"] && data["status"] === "success") {
          dispath(userActions.setFavorite(arr));
        } else {
          alert(t("SERVICE_NOT_FOUND"));
        }
      })
      .catch((e) => {
        alert(t("SERVICE_NOT_FOUND"));
      });
  };
  if (load) {
    return (
      <Spinner
        animation="border"
        size="sm"
        className="del-favorite-button-spin"
      />
    );
  }
  return (
    <span className="del-favorite-button" onClick={delProd}>
      &times;
    </span>
  );
};
