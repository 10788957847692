import React, { useState } from "react";
import { FaUserCircle, FaInfoCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import PersonalInfoForm from "./PersonalInfoForm";

export const PersonalInfoM = ({ user, setUser }) => {
  const [info, showInfo] = useState(false);

  return (
    <div className="d-flex flex-column bg-white">
      <div className="personal-info-title  d-flex align-items-center position-relative">
        <div className="d-flex align-items-center mr-5">
          <FaUserCircle size="60" color="#8cc83c" className="mr-2" />
          <div className="d-flex flex-column">
            <strong>{user.NAME}</strong>
            <div className="d-flex align-items-center">
              <span className="mr-2">+{user.LOGIN}</span>
              <div className="d-flex flex-column">
                <FaInfoCircle color="#8cc83c" onClick={() => showInfo(!info)} />
                {info ? <Info /> : null}
              </div>
            </div>

            <span>{user.EMAIL}</span>
          </div>
        </div>
      </div>
      <PersonalInfoForm user={user} setUser={setUser} />
    </div>
  );
};
const Info = () => {
  const { t } = useTranslation();
  return (
    <div className="info-wrap">
      <div className="info-title">
        <div className="arrow"></div>
        <h3>{t("ATTENCHION")}</h3>
      </div>
      <div className="info-body">{t("LOGIN_WARNING")}</div>
    </div>
  );
};
