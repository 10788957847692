import React, { useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { ResponsiveLayout } from "../ResponsiveLayout";
import { SaleMiniBasketM } from "./SaleMiniBasketM";
import { SaleMiniBasketD } from "./SaleMiniBasketD";
import { saleActions } from "../../state/actions";
import { useBitrix } from "../BitrixProvider";

import './sale-mini-basket.css'
const SaleMiniBasket = () => {
  const { basket, total,orderId } = useSelector((state) => state.sale);
  const dispath = useDispatch();
  const bitrix = useBitrix();  
  const updateBasket = ()=>{
    bitrix.sale.basket(orderId).then((res) => {
      if (Array.isArray(res)) {
        dispath(saleActions.updateBasket(res));
      }
    });
  }
  const getEditOrder = async ()=>{    
    const res = await bitrix.sale.getEditOrder()   
    if(res.ID){
      dispath(saleActions.setOrderIdForEdit(parseInt(res.ID)))
      dispath(saleActions.updateBasket(res.BASKET))      
    }else{
      updateBasket()
    }
  }
  
  useEffect(() => {      
    getEditOrder() 
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);   
  return (
    <ResponsiveLayout
      renderMobile={()=><SaleMiniBasketM count={basket.length}/>}
      renderDesktop={() => (
        <SaleMiniBasketD
          sum={total}
          count={basket.length}          
        />
      )}
    />
  );
};
export default SaleMiniBasket;
