import React, { Fragment} from 'react';
import {FilterItems} from './FilterItems'
export const CatalogFilterD = ({filters,action,filter})=>{
    if(Array.isArray(filters) && filters.length>0){
        return (
            <Fragment>
                {
                    filters.map(item=><FilterItems filter={item} active={filter} key={item.CODE} action={action}/>)
                }
            </Fragment>
        )
    }    
    return <Fragment/>
}