import React, { Fragment, useState } from "react";
import { FaAngleRight, FaMapMarkedAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { OrderDeliveryD } from "./OrderDeliveryD";

export const OrderDeliveryM = ({ params }) => {
  const { t } = useTranslation();

  const [show, toggle] = useState(false);

  let classWrap = "d-flex position-relative order-panel-button";

  if (params.valid) {
    classWrap += " valid";
  }
  if (!params.valid && (params.name || params.phone)) {
    classWrap += " invalid";
  }

  return (
    <Fragment>
      <div className={classWrap}>
        <div className="w-25 d-flex justify-content-center align-items-center order-panel-button-icon-wrap position-relative">
          <FaMapMarkedAlt className="order-panel-button-icon" />
        </div>
        <div
          className="w-75 d-flex align-items-center order-panel-button-action pt-2 pb-2 pl-4 position-relative"
          onClick={() => toggle(true)}
        >
          {!params.address ? (
            <small>
              {t("DELIVERY_ADDRESS")}/{t("DELIVERY_PICK_LABEL")}
            </small>
          ) : null}
          {params.address ? (
            <div className="d-flex flex-column w-100">
              <strong>
                {params.type === 1
                  ? t("DELIVERY_PICK_LABEL")
                  : t("DELIVERY_LABEL")}
              </strong>
              <span className="address-line">
                {params.address.replace("Костанай, ", "")}
              </span>
            </div>
          ) : null}

          <FaAngleRight className="order-panel-button-right-icon" />
        </div>
      </div>
      {show ? (
        <div className="modal-panel-wrap d-flex justify-content-center align-items-start">
          <div className="modal-panel">
            <OrderDeliveryD params={params} />
            <button
              className="btn btn-sm btn-block btn-success"
              onClick={() => toggle(false)}
            >
              {t("SAVE")}
            </button>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
