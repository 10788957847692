import React from "react";
import { Image, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import noImg from "./error1.png";

const CrashPage = ({ reload }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center align-items-center no-page">
      <div className="d-flex flex-column align-items-center">
        <Image src={noImg} />
        <h1 className="mt-3">{t("CRASHPAGE_TITLE")}</h1>
        <span className="text-center w-75 mb-3">
          {t("CRASHPAGE_DESCRIPTION")}
        </span>
        {reload ? (
          <Button onClick={reload} variant="success">
            {t("CRASHPAGE_TRY_AGAIN")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
export default CrashPage;
