export default class Catalog {
  #shop = 11;
  #price = 6;
  //Запрос дерева каталога
  getSectionsList() {
    return {
      method: "GET",
      url: "prods/sections",
      data: { shop: this.#shop },
    };
  }
  //Запрос списка товаров
  getProds(params) {
    if (params["searchList"]) {
      params["search"] = params["searchList"];
      params["searchList"] = "";
    }
    if (
      params["typeReq"] === "page" ||
      params["typeReq"] === "sort" ||
      params["typeReq"] === "filter"
    ) {
      params["view"] = "small";
    }
    params["pagesNav"] = "";
    params["typeReq"] = "";
    params["shop"] = this.#shop;
    params["price"] = this.#price;
    Object.keys(params).forEach((key) => {
      if (!params[key]) delete params[key];
    });
    return { method: "GET", url: "prods", data: params };
  }
  //Поиск по названию
  searchProdsByTitle(query) {
    return {
      method: "GET",
      url: "prods/search",
      data: {
        shop: this.#shop,
        price: this.#price,
        query,
        limit: 12,
      },
    };
  }
  //Карточка товара
  getProdDetail(code) {
    return {
      method: "GET",
      url: "prods",
      data: {
        shop: this.#shop,
        price: this.#price,
        code,
      },
    };
  }
  //Рекламные товары
  getBlurbProds(params) {
    return {
      method: "GET",
      url: "prods/blurb",
      data: {
        shop: this.#shop,
        price: this.#price,
        ...params,
      },
    };
  }
}
