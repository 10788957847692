import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ResponsiveLayout } from "../ResponsiveLayout";
import { CatalogCategoriesD } from "./CatalogCategoriesD";
import { CatalogCategoriesM } from "./CatalogCategoriesM";

import "./catalog-categories.css";
const CatalogCategories = () => {
  const { categories } = useSelector((state) => state.catalog.categories);
  const { pathname } = useLocation();
  const baseUrl = pathname.includes("discounts") ? "discounts" : "catalog";
  let path = [];

  if (pathname.includes("discounts")) {
    path = pathname.replace("/discounts", "").slice(1, -1).split("/");
  } else {
    path = pathname.replace("/catalog", "").slice(1, -1).split("/");
  }

  if (
    path[0] === "search" ||
    path[0] === "newspaper" ||
    path[0] === "banners" ||
    path[0] === "baners" ||
    path[0] === "brands" ||
    path[0] === "personal"
  ) {
    return <Fragment />;
  }

  // eslint-disable-next-line array-callback-return
  const section = path.reduce((prev, curr) => {
    if (!curr) {
      return prev;
    }
    if (prev[curr]) {
      return prev[curr];
    }
    if (prev["CHILD"][curr]["CHILD"] === false) {
      return prev;
    }
    return prev["CHILD"][curr];
  }, categories);

  if (section["CODE"]) {
    return (
      <ResponsiveLayout
        renderDesktop={() => (
          <CatalogCategoriesD
            list={section["CHILD"]}
            active={path[path.length - 1]}
            base={baseUrl}
          />
        )}
        renderMobile={() => (
          <CatalogCategoriesM
            list={section}
            active={path[path.length - 1]}
            base={baseUrl}
          />
        )}
      />
    );
  }

  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <CatalogCategoriesD
          list={section}
          active={path[path.length - 1]}
          base={baseUrl}
        />
      )}
      renderMobile={() => (
        <CatalogCategoriesM
          list={section}
          active={path[path.length - 1]}
          base={baseUrl}
        />
      )}
    />
  );
};
export default CatalogCategories;
