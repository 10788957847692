import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

import { useBitrix } from "../BitrixProvider";

import "./category-banner.css";

export const CategoryBanner = () => {
  const bitrix = useBitrix();
  const [banner, setBanner] = useState(null);
  const getBanner = async () => {
    try {
      const response = await bitrix.content.getCategoryBanner();
      if (response?.PICTURE) {
        setBanner(response);
      } else {
        setBanner(null);
      }
    } catch (e) {}
  };
  useEffect(() => {
    getBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (banner) {
    return (
      <div className="container">
        <div className="row no-gutters blurb-category-wrap">
          <div className="col-12">
            <Link to={banner.LINK}>
              <img src={banner.PICTURE} alt={banner.ID} className="img-fluid" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return <Fragment />;
};
