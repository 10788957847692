import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import miniCart from "./mini-cart.svg";

export const SaleButtonD = ({
  value,
  add,
  remove,
  change,
  label,
  saveChange,
  subladel = "",
}) => {
  const { t } = useTranslation();

  const units = {
    шт: t("UNITS_PIECES"),
    "л.": t("UNITS_LITERS"),
    г: t("UNITS_GRAMMS"),
    кг: t("UNITS_KILOGRAMMS"),
  };

  const inputWidth = String(value).length - 1 + "em";

  if (value === 0) {
    return (
      <button
        className="btn btn-success sale-desc-button d-flex align-items-center justify-content-center"
        onClick={add}
      >
        <Image src={miniCart} height={16} />
      </button>
    );
  }
  return (
    <div className="sale-buttons-wrap sale-buttons-desc-wrap d-flex justify-content-between">
      <button className="sale-buttons sale-buttons-minus" onClick={remove}>
        -
      </button>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="position-relative d-flex align-items-center justify-content-center sale-buttons-input-wrap">
          <input
            type="text"
            className="sale-buttons-input"
            value={value}
            onChange={change}
            onBlur={saveChange}
            style={{ width: inputWidth }}
          />
          <small className="sale-buttons-label">{units[label]}</small>
        </div>
        {subladel ? (
          <small
            className="sale-buttons-label text-white"
            style={{ lineHeight: "1.1em", fontSize: ".6em" }}
          >
            ~ {subladel + " " + t("UNITS_PIECES")}
          </small>
        ) : null}
      </div>

      <button className="sale-buttons sale-buttons-plus" onClick={add}>
        +
      </button>
    </div>
  );
};
