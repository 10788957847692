import React, { useState, Fragment } from "react";
import { FaFilter, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { FilterItems } from "./FilterItems";

export const CatalogFilterM = ({ filters, action, filter }) => {
  const { t } = useTranslation();

  const [show, toggle] = useState(false);

  if (Array.isArray(filters) && filters.length > 0) {
    return (
      <Fragment>
        <div
          className="d-flex align-items-center justify-content-center"
          onClick={() => toggle(true)}
        >
          <FaFilter />
          <strong>{t("FILTER")}</strong>
        </div>
        <div
          className={
            show
              ? "catalog-filter-mobil-list show"
              : "catalog-filter-mobil-list"
          }
        >
          <div
            className="catalog-filter-mobil-list-close"
            onClick={() => toggle(false)}
          ></div>
          <div
            className={
              show
                ? "catalog-filter-mobil-list-wrap show"
                : "catalog-filter-mobil-list-wrap"
            }
          >
            <div className="catalog-filter-mobil-list-title d-flex align-items-center justify-content-center">
              <span>{t("FILTER")}</span>
              <FaTimes
                color="rgb(255, 239, 22)"
                className="catalog-filter-mobil-list-title-icon"
                onClick={() => toggle(false)}
              />
            </div>
            <div className="catalog-filter-mobil-list-body">
              {filters.map((item) => (
                <FilterItems
                  filter={item}
                  action={action}
                  active={filter}
                  key={item.CODE}
                />
              ))}
            </div>
            <div className="catalog-filter-mobil-list-footer">
              <button
                className="btn btn-block btn-success btn-sm"
                onClick={() => toggle(false)}
              >
                {t("DONE")}
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  return <Fragment />;
};
