import React, { useState, useEffect } from "react";
import { useBitrix } from "../../BitrixProvider";
import { useTranslation } from "react-i18next";

import { ResponsiveLayout } from "../../ResponsiveLayout";
import { PersonalInfoFormD } from "./PersonalInfoFormD";
import { PersonalInfoFormM } from "./PersonalInfoFormM";

import "./personal-info-form.css";

const PersonalInfoForm = ({ user, setUser }) => {
  const { t } = useTranslation();
  const bitrix = useBitrix();

  const [fields, setFields] = useState({
    name: {
      val: "",
      err: "",
    },
    surname: {
      val: "",
      err: "",
    },
    sex: {
      val: "",
      err: "",
    },
    birth: {
      val: "",
      err: "",
    },
    phone: {
      val: "",
      err: "",
    },
    email: {
      val: "",
      err: "",
    },
    oldPass: {
      val: "",
      err: "",
    },
    newPass: {
      val: "",
      err: "",
    },
    rPass: {
      val: "",
      err: "",
    },
    card: {
      val: "",
      err: "",
    },
  });
  useEffect(() => {
    setFields({
      name: {
        val: user.NAME ? user.NAME : "",
        err: "",
      },
      surname: {
        val: user.LAST_NAME ? user.LAST_NAME : "",
        err: "",
      },
      sex: {
        val: user.PERSONAL_GENDER ? user.PERSONAL_GENDER : "",
        err: "",
      },
      birth: {
        val: user.PERSONAL_BIRTHDAY
          ? new Date(user.PERSONAL_BIRTHDAY_DATE)
          : "",
        err: "",
      },
      phone: {
        val: user.PERSONAL_MOBILE
          ? "+" + user.PERSONAL_MOBILE
          : "+" + user.LOGIN,
        err: "",
      },
      email: {
        val: user.EMAIL ? user.EMAIL : "",
        err: "",
      },
    });
  }, [user]);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(null);
  const changeField = (key, value) => {
    setFields({
      ...fields,
      [key]: {
        err: "",
        val: value,
      },
    });
  };
  const setErr = (key, value) => {
    setFields({
      ...fields,
      [key]: {
        ...fields[key],
        err: value,
      },
    });
  };
  const validField = (key) => {
    switch (key) {
      case "name":
        const errorSimName = fields.name.val.replace(
          /[а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\s-]*/g,
          ""
        );
        if (fields.name.val && errorSimName.length > 0) {
          setErr("name", t("INVALID_VALUE"));
        }
        break;
      case "surname":
        const errorSimSurName = fields.surname.val.replace(
          /[а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\s-]*/g,
          ""
        );
        if (fields.surname.val && errorSimSurName.length > 0) {
          setErr("surname", t("INVALID_VALUE"));
        }
        break;
      case "card":
        if (
          fields.card.val &&
          !/^999[0-9]{5}$|^999[0-9]{10}$/.test(fields.card.val)
        ) {
          setErr("card", t("INVALID_VALUE"));
        }
        break;
      case "sex":
        break;
      case "birth":
        break;
      case "phone":
        const validPhone = fields.phone.val
          .replace(/[^0-9]*/g, "")
          .replace(/^8/, "7");
        if (fields.phone.val && validPhone.length !== 11) {
          setErr("phone", t("INVALID_VALUE"));
        }
        break;
      case "email":
        if (
          fields.email.val &&
          !/^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/.test(fields.email.val)
        ) {
          setErr("email", t("INVALID_VALUE"));
        }
        break;
      default:
    }
  };
  const saveFields = () => {
    const params = {};
    if (fields.name.val && !fields.name.err) {
      params["NAME"] = fields.name.val;
    }
    if (fields.surname.val && !fields.surname.err) {
      params["LAST_NAME"] = fields.surname.val;
    }
    if (fields.sex.val) {
      params["PERSONAL_GENDER"] = fields.sex.val;
    }
    if (fields.birth.val && !fields.birth.err) {
      params["PERSONAL_BIRTHDAY_DATE"] = fields.birth.val
        .toLocaleDateString()
        .split(".")
        .reverse()
        .join("-");
      params["PERSONAL_BIRTHDAY"] =
        fields.birth.val.toLocaleDateString("ru-RU");
    }
    if (fields.phone.val && !fields.phone.err) {
      params["PERSONAL_MOBILE"] = fields.phone.val
        .replace(/[^0-9]*/g, "")
        .replace(/^8/, "7");
    }
    if (fields.email.val && !fields.email.err) {
      params["EMAIL"] = fields.email.val;
    }
    if (Object.keys(params).length > 0) {
      setLoad(true);
      bitrix.users
        .updateUser(params)
        .then((data) => {
          if (data.status && data.status === "success") {
            setUser(data.user);
            setStatus(
              <span className="text-success">&#10004; {t("DATA_IS_SAVE")}</span>
            );
          } else {
            setStatus(
              <span className="text-danger">&#10006; {t("DATA_NO_SAVE")}</span>
            );
            setUser(user);
          }
        })
        .catch((e) => {})
        .finally(() => setLoad(false));
    }
  };
  return (
    <ResponsiveLayout
      renderDesktop={() => (
        <PersonalInfoFormD
          params={{ fields, changeField, validField, load, saveFields, status }}
        />
      )}
      renderMobile={() => (
        <PersonalInfoFormM
          params={{ fields, changeField, validField, load, saveFields, status }}
        />
      )}
    />
  );
};
export default PersonalInfoForm;
