import React, { useState, useEffect } from "react";

import { ResponsiveLayout } from "../ResponsiveLayout";
import { CatalogDetailD } from "./CatalogDatailD";
import { CatalogDetailM } from "./CatalogDetailM";

import { useBitrix } from "../BitrixProvider";
import AppSrinner from "../AppSpinner";
import StaticPage from "../StaticPage";

import "./catalog-detail.css";

const CatalogDetail = ({ id }) => {
  const bitrix = useBitrix();

  const [prod, setProd] = useState({});
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    bitrix.catalog
      .prodById(id)
      .then((res) => {
        if (
          res["ARRAY"] &&
          Array.isArray(res["ARRAY"]) &&
          res["ARRAY"].length === 1
        ) {
          setProd(res["ARRAY"][0]);
        }
      })
      .finally(() => setLoad(false));
  }, [bitrix.catalog, id]);

  if (load) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <AppSrinner />
      </div>
    );
  }

  if (Object.keys(prod).length === 0) {
    return <StaticPage />;
  }

  return (
    <ResponsiveLayout
      renderDesktop={() => <CatalogDetailD prod={prod} />}
      renderMobile={() => <CatalogDetailM prod={prod} />}
    />
  );
};
export default CatalogDetail;
