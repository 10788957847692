import React from "react";
import { Form, FormControl } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const OrderPromoD = ({ val, change, blurHandler, valid, err = "" }) => {
  const { t } = useTranslation();

  return (
    <Form.Group controlId="order-promo" className="order-promo-wrap">
      <Form.Label>{t("PROMOCODE")}</Form.Label>
      <Form.Control
        value={val}
        onChange={change}
        onBlur={blurHandler}
        isValid={valid}
        isInvalid={err ? true : false}
      />
      <FormControl.Feedback type="invalid">{err}</FormControl.Feedback>
    </Form.Group>
  );
};
