import React, { useState, Fragment } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Registartion from "../Registration";
import { useWindowDimensions } from "../WindowDimensionsProvider";

import "./personal-auth.css";
import login from "./login.svg";
const PersonalAuth = () => {
  const { t } = useTranslation();
  const { isMobil } = useWindowDimensions();

  const [modal, showModal] = useState(null);

  const showLogin = () => showModal(<Registartion />);
  if (isMobil) {
    return (
      <Fragment>
        <div
          className="d-flex align-items-center link-block"
          onClick={showLogin}
        >
          <Image src={login} width={50} className="p-2" />
          <span className="text-white">{t("ENTER_REG")}</span>
        </div>
        {modal ? (
          <div className="personal-auth-modal-wrap">
            <div className="personal-auth-modal">
              {modal}
              <span className="link-block" onClick={() => showModal(null)}>
                &times;
              </span>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <div
        className="d-flex flex-column align-items-center link-block"
        onClick={showLogin}
      >
        <Image src={login} width={50} className="pt-2 pb-2 pr-3 pl-0" />
        <span className="text-white text-shipment">{t("ENTER_REG")}</span>
      </div>
      {modal ? (
        <div className="personal-auth-modal-wrap">
          <div className="personal-auth-modal">
            {modal}
            <span className="link-block" onClick={() => showModal(null)}>
              &times;
            </span>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
export default PersonalAuth;
