import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BannerSlider from "../components/BannerSlider";
import Advantages from "../components/Advantages";
// import { useWindowDimensions } from "../components/WindowDimensionsProvider";
// import CatalogTile from "../components/CatalogTile";
import CatalogSlider from "../components/CatalogSlider";
import { FavoriteButton } from "../components/PersonalFavorite/FavoriteButton";
import { BrandsList } from "../components/brands-list/BrandsList";
import QrCodeBanner from "../components/QrCodeBanner";

export const MainPage = () => {
  //   const { isMobil } = useWindowDimensions();
  return (
    <main>
      <Container className="pt-45 pb-3">
        <Row>
          <Col>
            <BannerSlider />
          </Col>
        </Row>
        <Row>
          <Col>
            <QrCodeBanner />
          </Col>
        </Row>
        <Row>
          <Col>
            <Advantages />
          </Col>
        </Row>
        <Row>
          <Col>
            <BrandsList />
          </Col>
        </Row>
        {/* {isMobil ? (
          <Row>
            <Col>
              <CatalogTile />
            </Col>
          </Row>
        ) : null} */}
        <Row>
          <Col>
            <CatalogSlider
              type="discounts"
              header={(id) => <FavoriteButton prodId={id} />}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};
