import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./LanguageSwitcher.module.css";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  return (
    <div className={styles.switcher}>
      <button
        onClick={() => i18n.changeLanguage("kk")}
        className={
          i18n.language === "kk" ? styles.active_button : styles.button
        }
      >
        Қаз
      </button>
      <button
        onClick={() => i18n.changeLanguage("ru")}
        className={
          i18n.language === "ru" ? styles.active_button : styles.button
        }
      >
        Рус
      </button>
    </div>
  );
};

export default LanguageSwitcher;
