import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useBitrix } from "../BitrixProvider";
import { CatalogSliderView } from "../CatalogSlider";

import "./blurb-catalog-more-category.css";

export const BlurbCatalogMoreCategory = ({ category, size = 4 }) => {
  const bitrix = useBitrix();
  const { t } = useTranslation();

  const [products, setProds] = useState([]);

  const getData = () => {
    bitrix.catalog
      .getBlurbMoreCategory(category, size)
      .then((res) => {
        if (res.ARRAY && Array.isArray(res.ARRAY)) {
          setProds(res.ARRAY);
        }
      })
      .catch((e) => {});
  };
  useEffect(() => {
    if (category) {
      getData();
    } else {
      setProds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);
  if (products.length > 0) {
    return (
      <CatalogSliderView prods={products} view="desc" name={t("SEE_ALSO")} />
    );
  }
  return <Fragment />;
};
