import React, { Fragment } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { ResponsiveLayout } from "../ResponsiveLayout";
import { TiClipboard } from "react-icons/ti";
import { useTranslation } from "react-i18next";

import Logo from "../Logo/";
import MainMenu from "../MainMenu/";

import "./header.css";
import { Link } from "react-router-dom";

import CatalogSearch from "../CatalogSearch";
// import Chat from "../Chat";
// import SaleMiniBasket from "../SaleMiniBasket";
import PersonalLink from "../PersonalLink";

import LanguageSwitcher from "../LanguageSwitcher";

import feed from "./feed.svg";

const Header = () => {
  return (
    <ResponsiveLayout
      renderDesktop={HeaderDesktop}
      renderMobile={HeaderMobil}
    />
  );
};
const HeaderMobil = () => {
  return (
    <header className="sticky-top">
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-around align-items-center bg-app-green pt-1 pb-1">
            <MainMenu />
            {/* <Chat /> */}
            <Logo />
            <CatalogSearch />
            {/* <SaleMiniBasket /> */}
          </Col>
        </Row>
      </Container>
    </header>
  );
};
const HeaderDesktop = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <header>
        <div className="bg-app-darkgreen">
          <Container>
            <Row className="py-2">
              <Col lg={{ span: 3, offset: 9 }} className="text-end">
                <LanguageSwitcher />
              </Col>
            </Row>
            <Row className="pt-1 pb-3">
              <Col
                lg={3}
                className="d-flex align-items-center justify-content-center"
              >
                <Logo />
              </Col>
              <Col
                lg={3}
                sm={6}
                className="d-flex flex-column justify-content-start"
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Image src={feed} width={50} className="p-2" />
                  <div className="d-flex flex-column align-items-center">
                    <span className="text-white text-support">
                      {t("SUPPORT")}
                    </span>
                    <span className="text-white text-support">
                      {t("SUPPORT_PHONE")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col
                lg={3}
                sm={6}
                className="d-flex flex-column justify-content-start"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <Link
                    to="/shipping_and_payment/"
                    className="d-flex flex-column align-items-center text-decoration-none"
                  >
                    <TiClipboard color="white" size="50" />
                    <span className="text-white text-shipment">
                      {t("SHIPMENT")}
                    </span>
                  </Link>
                </div>
              </Col>
              <Col
                lg={3}
                sm={6}
                className="d-flex flex-column justify-content-start"
              >
                <PersonalLink />
              </Col>
            </Row>
          </Container>
        </div>
      </header>
      <div className="bg-app-green stick-menu">
        <Container>
          <Row>
            <Col>
              <MainMenu />
              {/* <Chat /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default Header;
